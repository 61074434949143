export const APP_PATH = "/app";
export const AUTH_PATH = `${APP_PATH}/auth`;
export const FORGOTTEN_PASSWORD_PATH = "/forgotten-password";
export const RESET_PASSWORD_PATH = "/reset-password";
export const LOGIN_PATH = "/login";
export const SIGN_IN_PATH = "/sign-in";
export const USER_PATH = `${APP_PATH}/user`;
export const EDIT_PROFILE_PATH = "/edit-profile";
export const DASHBOARD_PATH = "/dashboard";
export const ORDER_PLATE_PATH = "/order-plate";
export const NEW_PROFILE_PATH = "/new-profile";
export const PUBLIC_PROFILE_PATH = "/public-profile";
export const PROFILE_ID_PARAM = "/:profileId";
export const USER_ID_PARAM = "/:userId";
export const TOKEN_PARAM = "/:token";
export const ORDERS_PATH = "/orders";
export const SETTINGS_PATH = "/settings";
export const PROFILE_PREVIEW_PATH = "/profile-preview";
export const TERMS_AND_CONDITIONS_PATH = "/terms-and-conditions";
export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const CONTACT_PATH = "/contact";
export const SINGLE_ORDER_PATH = "/order";
export const ORDER_NUMBER_PARAM = "/:orderNumber";
export const SUCCESSFUL_PAYMENT_PATH = "/successful-payment";
export const FAILED_PAYMENT_PATH = "/failed-payment";
export const STATIC_PAGE_PATH = "/static"
