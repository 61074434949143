import styled from "styled-components";
import {useTranslation} from "react-i18next";

import {useColorThemeContext} from "global-contexts/ColorThemeContext";

const PrivacyPolicyContent = () => {
  const {isDarkTheme} = useColorThemeContext();

  const {t} = useTranslation();

  return (
    <Container isDarkTheme={isDarkTheme}>
      <Title>{t("privacyPolicy")}</Title>
      <Description>
        <p><strong>Polityka prywatności opisuje zasady przetwarzania przez nas
          informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli
          tzw. cookies.</strong></p>
        <p><strong>1. Informacje ogólne</strong></p>
        <ol type="1">
          <li><p>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
            adresem url: <strong>memero.pl</strong></p></li>
          <li><p>Operatorem serwisu oraz Administratorem danych osobowych jest:
            MEMERO SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ ul. Gawrońska 2/17, KRS;
            0000979409, NIP: 7312077128, kapitał zakładowy: 5.000,00 zł (pięć
            tysięcy złotych), Sąd Rejestrowy: Sąd Rejonowy dla Łodzi-Śródmieścia w
            Łodzi XX Wydział Gospodarczy Krajowego Rejestru Sądowego.</p></li>
          <li><p>Adres kontaktowy poczty elektronicznej operatora:
            kontakt@memero.pl</p></li>
          <li><p>Operator jest Administratorem Twoich danych osobowych w
            odniesieniu do danych podanych dobrowolnie w Serwisie.</p></li>
          <li><p>Serwis wykorzystuje dane osobowe w następujących celach:</p>
            <ul>
              <li><blockquote>
                <p>Prezentacja profil użytkownika innym użytkownikom</p>
              </blockquote></li>
              <li><blockquote>
                <p>Przygotowanie, pakowanie, wysyłka towarów</p>
              </blockquote></li>
              <li><blockquote>
                <p>Realizacja zamówionych usług</p>
              </blockquote></li>
            </ul></li>
          <li><p>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach
            i ich zachowaniu w następujący sposób:</p>
            <ol type="1">
              <li><p>Poprzez dobrowolnie wprowadzone w formularzach dane, które
                zostają wprowadzone do systemów Operatora.</p></li>
              <li><p>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
                „ciasteczka”).</p></li>
            </ol></li>
        </ol>
        <p><strong>2. Wybrane metody ochrony danych stosowane przez
          Operatora</strong></p>
        <ol type="1">
          <li><p>Miejsca logowania i wprowadzania danych osobowych są chronione w
            warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane
            logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze
            użytkownika i mogą być odczytane jedynie na docelowym serwerze.</p></li>
          <li><p>Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja
            hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej
            działania, co stanowi obecnie współczesny standard w zakresie
            przechowywania haseł użytkowników.</p></li>
          <li><p>Operator okresowo zmienia swoje hasła administracyjne.</p></li>
          <li><p>W celu ochrony danych Operator regularnie wykonuje kopie
            bezpieczeństwa.</p></li>
          <li><p>Istotnym elementem ochrony danych jest regularna aktualizacja
            wszelkiego oprogramowania, wykorzystywanego przez Operatora do
            przetwarzania danych osobowych, co w szczególności oznacza regularne
            aktualizacje komponentów programistycznych.</p></li>
        </ol>
        <p><strong>3. Hosting</strong></p>
        <ol type="1">
          <li><p>Serwis jest hostowany (technicznie utrzymywany) na serwerach
            operatora: webh.pl</p></li>
        </ol>
        <p><strong>4. Twoje prawa i dodatkowe informacje o sposobie
          wykorzystania danych</strong></p>
        <ol type="1">
          <li><p>W niektórych sytuacjach Administrator ma prawo przekazywać Twoje
            dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
            zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
            Administratorze. Dotyczy to takich grup odbiorców:</p>
            <ul>
              <li><blockquote>
                <p>kurierzy</p>
              </blockquote></li>
              <li><blockquote>
                <p>operatorzy pocztowi</p>
              </blockquote></li>
              <li><blockquote>
                <p>upoważnieni pracownicy i współpracownicy, którzy korzystają z danych
                  w celu realizacji celu działania strony</p>
              </blockquote></li>
            </ul></li>
          <li><p>Twoje dane osobowe przetwarzane przez Administratora nie dłużej,
            niż jest to konieczne do wykonania związanych z nimi czynności
            określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W
            odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej
            niż przez 3 lata.</p></li>
          <li><p>Przysługuje Ci prawo żądania od Administratora:</p>
            <ul>
              <li><blockquote>
                <p>dostępu do danych osobowych Ciebie dotyczących,</p>
              </blockquote></li>
              <li><blockquote>
                <p>ich sprostowania,</p>
              </blockquote></li>
              <li><blockquote>
                <p>usunięcia,</p>
              </blockquote></li>
              <li><blockquote>
                <p>ograniczenia przetwarzania,</p>
              </blockquote></li>
              <li><blockquote>
                <p>oraz przenoszenia danych.</p>
              </blockquote></li>
            </ul></li>
          <li><p>Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
            przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych
            osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych
            przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie
            będzie mogło być wykonane w przypadku istnienia ważnych prawnie
            uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
            interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub
            obrony roszczeń.</p></li>
          <li><p>Na działania Administratora przysługuje skarga do Prezesa Urzędu
            Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</p></li>
          <li><p>Podanie danych osobowych jest dobrowolne, lecz niezbędne do
            obsługi Serwisu.</p></li>
          <li><p>W stosunku do Ciebie mogą być podejmowane czynności polegające na
            zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
            świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez
            Administratora marketingu bezpośredniego.</p></li>
          <li><p>Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu
            przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich
            poza teren Unii Europejskiej.</p></li>
        </ol>
        <p><strong>5. Informacje w formularzach</strong></p>
        <ol type="1">
          <li><p>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w
            tym dane osobowe, o ile zostaną one podane.</p></li>
          <li><p>Serwis może zapisać informacje o parametrach połączenia
            (oznaczenie czasu, adres IP).</p></li>
          <li><p>Serwis, w niektórych wypadkach, może zapisać informację
            ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika
            wypełniającego formularz. W takim wypadku adres e-mail użytkownika
            pojawia się wewnątrz adresu url strony zawierającej formularz.</p></li>
          <li><p>Dane podane w formularzu są przetwarzane w celu wynikającym z
            funkcji konkretnego formularza, np. w celu dokonania procesu obsługi
            zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp.
            Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do
            czego on służy.</p></li>
        </ol>
        <p><strong>6. Logi Administratora</strong></p>
        <ol type="1">
          <li><p>Informacje zachowaniu użytkowników w serwisie mogą podlegać
            logowaniu. Dane te są wykorzystywane w celu administrowania
            serwisem.</p></li>
        </ol>
        <p><strong>7. Istotne techniki marketingowe</strong></p>
        <ol type="1">
          <li><p>Operator stosuje analizę statystyczną ruchu na stronie, poprzez
            Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje
            do operatora tej usługi danych osobowych, a jedynie zanonimizowane
            informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
            końcowym użytkownika. W zakresie informacji o preferencjach użytkownika
            gromadzonych przez sieć reklamową Google użytkownik może przeglądać i
            edytować informacje wynikające z plików cookies przy pomocy narzędzia:
            https://www.google.com/ads/preferences/</p></li>
          <li><p>Operator stosuje techniki remarketingowe, pozwalające na
            dopasowanie przekazów reklamowych do zachowania użytkownika na stronie,
            co może dawać złudzenie, że dane osobowe użytkownika są wykorzystywane
            do jego śledzenia, jednak w praktyce nie dochodzi do przekazania żadnych
            danych osobowych od Operatora do operatorom reklam. Technologicznym
            warunkiem takich działań jest włączona obsługa plików cookie.</p></li>
          <li><p>Operator stosuje korzysta z piksela Facebooka. Ta technologia
            powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że
            dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym wypadku
            na danych, wobec których sam jest administratorem, Operator nie
            przekazuje od siebie żadnych dodatkowych danych osobowych serwisowi
            Facebook. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
            końcowym użytkownika.</p></li>
          <li><p>Operator stosuje rozwiązanie badające zachowanie użytkowników
            poprzez tworzenie map ciepła oraz nagrywanie zachowania na stronie. Te
            informacje są anonimizowane zanim zostaną przesłane do operatora usługi
            tak, że nie wie on jakiej osoby fizycznej one dotyczą. W szczególności
            nagrywaniu nie podlegają wpisywane hasła oraz inne dane
            osobowe.</p></li>
          <li><p>Operator stosuje rozwiązanie automatyzujące działanie Serwisu w
            odniesieniu do użytkowników, np. mogące przesłać maila do użytkownika po
            odwiedzeniu konkretnej podstrony, o ile wyraził on zgodę na otrzymywanie
            korespondencji handlowej od Operatora.</p></li>
          <li><p>Operator może stosować profilowanie w rozumieniu przepisów o
            ochronie danych osobowych</p></li>
        </ol>
        <p><strong>8. Informacja o plikach cookies</strong></p>
        <ol type="1">
          <li><p>Serwis korzysta z plików cookies.</p></li>
          <li><p>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
            internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony
            internetowej, z której pochodzą, czas przechowywania ich na urządzeniu
            końcowym oraz unikalny numer.</p></li>
          <li><p>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
            Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator
            Serwisu.</p></li>
          <li><p>Pliki cookies wykorzystywane są w następujących celach:</p>
            <ol type="1">
              <li><blockquote>
                <p>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której
                  użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać
                  loginu i hasła;</p>
              </blockquote></li>
              <li><blockquote>
                <p>realizacji celów określonych powyżej w części "Istotne techniki
                  marketingowe";</p>
              </blockquote></li>
            </ol></li>
          <li><p>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
            cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies).
            Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w
            urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony
            internetowej lub wyłączenia oprogramowania (przeglądarki internetowej).
            „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika
            przez czas określony w parametrach plików cookies lub do czasu ich
            usunięcia przez Użytkownika.</p></li>
          <li><p>Oprogramowanie do przeglądania stron internetowych (przeglądarka
            internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies
            w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać
            zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia
            usunięcie plików cookies. Możliwe jest także automatyczne blokowanie
            plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub
            dokumentacja przeglądarki internetowej.</p></li>
          <li><p>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
            funkcjonalności dostępne na stronach internetowych Serwisu.</p></li>
          <li><p>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
            Serwisu wykorzystywane mogą być również przez współpracujące z
            operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google
            (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w
            USA), Twitter (Twitter Inc. z siedzibą w USA).</p></li>
        </ol>
        <p><strong>9. Zarządzanie plikami cookies – jak w praktyce wyrażać i
          cofać zgodę?</strong></p>
        <ol type="1">
          <li><p>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
            ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
            cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
            utrzymania preferencji użytkownika może utrudnić, a w skrajnych
            przypadkach może uniemożliwić korzystanie ze stron www</p></li>
          <li><p>W celu zarządzania ustawienia cookies wybierz z listy poniżej
            przeglądarkę internetową, której używasz i postępuj zgodnie z
            instrukcjami:</p>
            <ul>
              <li><blockquote>
                <p><a
                  href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">Edge</a></p>
              </blockquote></li>
              <li><blockquote>
                <p><a
                  href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet
                  Explorer</a></p>
              </blockquote></li>
              <li><blockquote>
                <p><a
                  href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a></p>
              </blockquote></li>
              <li><blockquote>
                <p><a href="http://support.apple.com/kb/PH5042">Safari</a></p>
              </blockquote></li>
              <li><blockquote>
                <p><a
                  href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a></p>
              </blockquote></li>
              <li><blockquote>
                <p><a
                  href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></p>
              </blockquote></li>
            </ul></li>
        </ol>
        <p>Urządzenia mobilne:</p>
        <ul>
          <li><blockquote>
            <p><a
              href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Android</a></p>
          </blockquote></li>
          <li><blockquote>
            <p><a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">Safari
              (iOS)</a></p>
          </blockquote></li>
          <li><blockquote>
            <p><a
              href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">Windows
              Phone</a></p>
          </blockquote></li>
        </ul>
      </Description>;
    </Container>
  );
};

export default PrivacyPolicyContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({theme}) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({theme}) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 24px;
  overflow-y: auto;
`;
