import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {lightTheme} from "global-styles/colorThemes";

import {CONTACT_PATH, PRIVACY_POLICY_PATH, TERMS_AND_CONDITIONS_PATH,} from "utils/routes";
import {useProfileDataContext} from "global-contexts/ProfileDataContext";
import {useQuestionModalContext} from "global-contexts/QuestionModalContext";

const Footer = ({basePath}: { basePath: string }) => {
  const {openQuestionModal} = useQuestionModalContext();
  const {allFormsIsDirtyStatuses, resetForm} = useProfileDataContext();

  const navigate = useNavigate();

  const {t} = useTranslation();

  const goToPage = (path: string) => {
    const areAnyUnsavedChangesInProfileEditing = Object.values(
      allFormsIsDirtyStatuses
    ).some((value) => value);

    const redirect = (isResetProfileForm?: boolean) => {
      navigate(`${path}`, {replace: true});

      isResetProfileForm && resetForm();
    };

    if (!areAnyUnsavedChangesInProfileEditing) {
      redirect();
    } else {
      openQuestionModal({
        text: t("addProfile.wantGoOutUnsavedChangesWillBeLost"),
        rightButtonAction: () => redirect(true),
      });
    }
  };

  return (
    <Container>
      <Spacer/>
      <FooterButton onClick={() => goToPage("/")}>
        {t("mainPage")}
      </FooterButton>
      <FooterButton onClick={() => goToPage(`${basePath}${TERMS_AND_CONDITIONS_PATH}`)}>
        {t("termsAndConditions")}
      </FooterButton>
      <FooterButton onClick={() => goToPage(`${basePath}${PRIVACY_POLICY_PATH}`)}>
        {t("privacyPolicy")}
      </FooterButton>
      <FooterButton onClick={() => goToPage(`${basePath}${CONTACT_PATH}`)}>
        {t("contact")}
      </FooterButton>
      <Spacer/>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  left: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 5vh;
  padding-right: 30px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;


  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  grid-row-start: 2;
  grid-row-end: 2;
  @media (max-width: 720px) {
    grid-template-columns: repeat(1, minmax(min-content, max-content));
    grid-row-start: 2;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
  }
`;

const Spacer = styled.div`
  grid-column: span 3;
  @media (max-width: 720px) {
  grid-column: span 0;
    display: none;
  }
`;

const FooterButton = styled.button`
  all: unset;
  color: ${lightTheme.colors.whiteOrDark};
  transition: 0.5s;

  &:hover {
    opacity: 0.6;
  }
`;
