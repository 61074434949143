import { useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";
import ResetPasswordForm from "components/auth/forms/ResetPasswordForm";

import { AUTH_PATH, LOGIN_PATH } from "utils/routes";
import { renderErrorToast, renderSuccessToast } from "utils/toasts";
import {
  ResetPasswordFormValues,
  useResetPasswordFormController,
} from "components/auth/forms/ResetPasswordFormController";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const resetPasswordFormMethods = useResetPasswordFormController();

  const onSubmit = (values: ResetPasswordFormValues) => {
    setIsLoading(true);

    httpService
      .post(`api/auth/v1/password/reset/confirm/`, {
        newPassword1: values.newPassword,
        newPassword2: values.newPasswordConfirmation,
        uid: params.userId,
        token: params.token,
      })
      .then(() => {
        setIsLoading(false);
        renderSuccessToast(t("auth.passwordHasBeenChanged"));
        navigate(`${AUTH_PATH}${LOGIN_PATH}`, { replace: true });
      })
      .catch(() => {
        setIsLoading(false);
        renderErrorToast(t("errorOccurred"));
      });
  };

  const goToSignInPage = () => {
    navigate(`${AUTH_PATH}${LOGIN_PATH}`, { replace: true });
  };

  return (
    <AuthContentWrapper>
      <AuthTitle>{t("auth.setNewPassword")}</AuthTitle>
      <FormProvider {...resetPasswordFormMethods}>
        <Form onSubmit={resetPasswordFormMethods.handleSubmit(onSubmit)}>
          <ResetPasswordForm isLoading={isLoading} />
        </Form>
      </FormProvider>
      <StyledMainButton onClick={goToSignInPage} expandWidth>
        {t("auth.goToSignInPage")}
      </StyledMainButton>
    </AuthContentWrapper>
  );
};

export default ResetPassword;

const AuthTitle = styled.div`
  margin: 32px 0 15px;
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  line-height: 1.2;
`;

const AuthContentWrapper = styled.div`
  margin: 0 24px;
  color: ${({ theme }) => theme.colors.darkOrLight};
`;

const StyledMainButton = styled(MainButton)`
  margin-top: 60px;
`;
