import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import OutlineButton from "components/inputs/OutlineButton";
import SettingsRegularTableRow from "components/settings/SettingsRegularTableRow";
import SettingsPasswordTableRow from "components/settings/SettingsPasswordTableRow";

import { logOut, selectAuthToken } from "store/auth/AuthSlice";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { USER_DATA_NAMES, USER_REGULAR_DATA_API_NAMES } from "utils/consts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useUserDataContext } from "global-contexts/UserDataContext";

const SettingsContent = () => {
  const [isAnySettingsRowBeingEdited, setIsAnySettingsRowBeingEdited] =
    useState<boolean>(false);

  const { isDarkTheme } = useColorThemeContext();

  const { userData, fetchUserData, clearUserData } = useUserDataContext();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthToken);

  const handleLogOut = () => {
    // window.open(
    //   "http://localhost:4000/auth/logout",
    //   "_self",
    //   "noopener,noreferrer"
    // );

    if (authToken) {
      clearUserData();
      dispatch(logOut());
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userDataFields = useMemo(() => {
    if (userData) {
      return {
        email: {
          dataName: USER_DATA_NAMES.EMAIL,
          dataValue: userData.email,
        },
        firstName: {
          dataName: USER_DATA_NAMES.FIRST_NAME,
          dataValue: userData.firstName,
        },
        lastName: {
          dataName: USER_DATA_NAMES.LAST_NAME,
          dataValue: userData.lastName,
        },
        password: {
          dataName: USER_DATA_NAMES.PASSWORD,
          dataValue: undefined,
        },
      };
    }
  }, [userData]);

  return (
    <Container isDarkTheme={isDarkTheme}>
      <Title>{t("settings.title")}</Title>
      <TableWrapper>
        {userDataFields && (
          <TableContent isDarkTheme={isDarkTheme}>
            <SettingsRegularTableRow
              rowData={userDataFields.email}
              isNotEditable
              isAnySettingsRowBeingEdited={isAnySettingsRowBeingEdited}
              setIsAnySettingsRowBeingEdited={setIsAnySettingsRowBeingEdited}
            />
            {/*<SettingsRegularTableRow*/}
            {/*  rowData={userDataFields.firstName}*/}
            {/*  apiReuqestKeyName={USER_REGULAR_DATA_API_NAMES.FIRST_NAME}*/}
            {/*  isAnySettingsRowBeingEdited={isAnySettingsRowBeingEdited}*/}
            {/*  setIsAnySettingsRowBeingEdited={setIsAnySettingsRowBeingEdited}*/}
            {/*/>*/}
            {/*<SettingsRegularTableRow*/}
            {/*  rowData={userDataFields.lastName}*/}
            {/*  apiReuqestKeyName={USER_REGULAR_DATA_API_NAMES.LAST_NAME}*/}
            {/*  isAnySettingsRowBeingEdited={isAnySettingsRowBeingEdited}*/}
            {/*  setIsAnySettingsRowBeingEdited={setIsAnySettingsRowBeingEdited}*/}
            {/*/>*/}
            <SettingsPasswordTableRow
              rowData={userDataFields.password}
              isAnySettingsRowBeingEdited={isAnySettingsRowBeingEdited}
              setIsAnySettingsRowBeingEdited={setIsAnySettingsRowBeingEdited}
            />
          </TableContent>
        )}
      </TableWrapper>
      <div>
        <OutlineButton lateralPadding={70} onClick={handleLogOut} expandWidth={true}>
          {t("logOut")}
        </OutlineButton>
      </div>
    </Container>
  );
};

export default SettingsContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
  @media (max-width: 720px) {
    display: grid;
  }
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 44px;
`;

const TableWrapper = styled.div`
  max-height: 69vh;
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media (max-width: 720px) {
    display: grid;
  }
`;

const TableContent = styled.div<{ isDarkTheme: boolean }>`
  width: 100%;
  flex-grow: 1;
  position: relative;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
`;
