import styled from "styled-components";
import { useFieldArray, useWatch, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Description,
  subformContentStyle,
  SubformWrapper,
} from "global-styles/commonOrderStyles";
import { lightTheme } from "global-styles/colorThemes";

import CheckboxRHF from "components/inputs/CheckboxRHF";
import QuantityPickerRHF from "components/inputs/QuantityPickerRHF";

import { OrderFormData } from "types/Order";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as QrCodeIcon } from "assets/qr-code-icon.svg";

const ProfilesFieldArrayForm = () => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();

  const { fields } = useFieldArray({
    control: orderPlateFormMethods.control,
    name: "profiles",
  });

  const profilesWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "profiles",
  });

  return (
    <SubformWrapper>
      <Description>{t("orderPlate.profilesChoiceDescription")}</Description>
      <ProfilesList isDarkTheme={isDarkTheme}>
        {fields.map((field, index) => (
          <ProfileSelection key={field.id}>
            {orderPlateFormMethods.getValues("profiles")[index].photoUrl !==
              "" && (
              <ProfilePhoto
                src={
                  orderPlateFormMethods.getValues("profiles")[index].photoUrl
                }
              />
            )}
            {orderPlateFormMethods.getValues("profiles")[index].photoUrl ===
              "" && (
              <NoPhoto>
                <NoPhotoText>{t("noPhoto")}</NoPhotoText>
              </NoPhoto>
            )}
            <Inputs>
              <CheckboxRHF
                formFieldName={
                  `profiles.${index}.isProfileChosen` as "profiles.0.isProfileChosen"
                }
                control={orderPlateFormMethods.control}
                label={
                  orderPlateFormMethods.getValues("profiles")[index].fullName
                }
                biggerFontSize
                noMarginTop
              />
              {profilesWatcher[index].isProfileChosen && (
                <QuantityPickersWrapper>
                  {/*<PlateQuantityPicker>*/}
                  {/*  <IconWrapper backgroundcolor={"whiteOrDark"}>*/}
                  {/*    <StyledQrCodeIcon iconcolor={"darkOrLight"} />*/}
                  {/*  </IconWrapper>*/}
                  {/*  <QuantityPickerRHF*/}
                  {/*    control={orderPlateFormMethods.control}*/}
                  {/*    formFieldName={*/}
                  {/*      `profiles.${index}.colors.WHITE` as "profiles.0.colors.WHITE"*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</PlateQuantityPicker>*/}
                  <PlateQuantityPicker>
                    <IconWrapper backgroundcolor={"whiteOpacityOrDarkOpacity"}>
                      <StyledQrCodeIcon iconcolor={"darkOrLight"} />
                    </IconWrapper>
                    <QuantityPickerRHF
                      control={orderPlateFormMethods.control}
                      formFieldName={
                        `profiles.${index}.colors.TRANSPARENT` as "profiles.0.colors.TRANSPARENT"
                      }
                    />
                  </PlateQuantityPicker>
                </QuantityPickersWrapper>
              )}
            </Inputs>
          </ProfileSelection>
        ))}
      </ProfilesList>
    </SubformWrapper>
  );
};

export default ProfilesFieldArrayForm;

const ProfilesList = styled.div<{ isDarkTheme: boolean }>`
  ${subformContentStyle}

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const ProfileSelection = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 720px) {
    display: grid;
  }
  margin-bottom: 20px;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightOrDark};
    padding-bottom: 20px;
  }
`;

const ProfilePhoto = styled.img`
  display: block;
  width: 105px;
  height: 105px;
  object-fit: cover;
  border-radius: 10px;
`;

const NoPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.darkOrLight};
`;

const NoPhotoText = styled.div`
  font-size: 12px;
`;

const Inputs = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

const QuantityPickersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const PlateQuantityPicker = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;

const IconWrapper = styled.div<{ backgroundcolor: "darkGray" | "whiteOrDark" | "whiteOpacityOrDarkOpacity" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29px;
  width: 29px;
  background-color: ${(p) => lightTheme.colors[p.backgroundcolor]};
  border-radius: 4px;
`;

const StyledQrCodeIcon = styled(QrCodeIcon)<{
  iconcolor: "whiteOrDark" | "darkOrLight";
}>`
  width: 17px;
  height: 17px;
  color: ${(p) => lightTheme.colors[p.iconcolor]};
`;
