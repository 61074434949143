import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { smallButtonStyle } from "global-styles/sharedStyles";

import {
  Header,
  Section,
  SectionContent,
  SummaryDescription,
} from "global-styles/commonOrderStyles";

import httpService from "utils/httpService";
import InputRHF from "components/inputs/InputRHF";

import { OrderCheckoutSummary } from "types/Order";
import {
  AddDiscountFormValues,
  useAddDiscountFormController,
} from "components/order-plate/order-summary/forms/AddDiscountFormController";
import { renderErrorToast } from "utils/toasts";

interface Props {
  orderCheckoutSummary: OrderCheckoutSummary | undefined;
  setOrderCheckoutSummary: Dispatch<
    SetStateAction<OrderCheckoutSummary | undefined>
  >;
}

const Discount = ({ orderCheckoutSummary, setOrderCheckoutSummary }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useAddDiscountFormController();

  const discountCodeWatcher = useWatch({
    control,
    name: "discountCode",
  });

  const { t } = useTranslation();

  const calculateDiscount = (values: AddDiscountFormValues) => {
    httpService
      .post(`api/ecommerce/order/v1/apply-coupon/`, {
        code: values.discountCode,
      })
      .then((response) => {
        setOrderCheckoutSummary(response.data.details);
        reset();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          renderErrorToast(t("orderPlate.wrongDiscountCode"));
          reset({ discountCode: "" }, { keepErrors: true });
        } else {
          renderErrorToast(t("errorOccurred"));
        }
      });
  };

  return (
    <Section>
      <Header>
        <SummaryDescription>{t("orderPlate.discountCode")}</SummaryDescription>
      </Header>
      <SectionContent>
        <AddDiscountCodeWrapper
          alignItemsFlexStart={!!orderCheckoutSummary?.discount}
        >
          {!orderCheckoutSummary?.discount && (
            <>
              <InputRHF
                formFieldName="discountCode"
                control={control}
                placeholder={t("orderPlate.enterDiscountCode")}
                error={errors.discountCode?.message}
                noMarginTop
              />
              <AddButton
                type="submit"
                onClick={handleSubmit(calculateDiscount)}
                disabled={!discountCodeWatcher}
              >
                {t("orderPlate.calculateDiscount")}
              </AddButton>
            </>
          )}
          {orderCheckoutSummary?.discount && (
            <div>
              {t("orderPlate.usedCode")}
              <DiscountName>{orderCheckoutSummary.discount.name}</DiscountName>
            </div>
          )}
        </AddDiscountCodeWrapper>
      </SectionContent>
    </Section>
  );
};

export default Discount;

const AddDiscountCodeWrapper = styled.div<{
  alignItemsFlexStart?: boolean | null;
}>`
  display: flex;
  align-items: ${(p) => (p.alignItemsFlexStart ? "flex-start" : "center")};
  margin-top: 12px;
  height: 38px;
`;

const AddButton = styled.button`
  ${smallButtonStyle}
  margin-left: 10px;
  margin-top: 11px;

  @media (max-width: 1189px) {
    width: 39px;
    text-align: center;
  }
`;

const DiscountName = styled.span`
  font-style: italic;
  margin-left: 5px;
`;
