import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export interface AddDiscountFormValues {
  discountCode: string;
}

export const useAddDiscountFormController = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      discountCode: Yup.string()
        .trim()
        .required(t("orderPlate.discountCodeValidation")),
    });
  }, [t]);

  return useForm<AddDiscountFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
