import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddBasicDataForm from "components/profile/forms/AddBasicDataForm";
import AddDescription from "components/profile/AddDescription";
import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";
import OutlineButton from "components/inputs/OutlineButton";
import ProfileLivePreview from "components/profile/ProfileLivePreview";
import ProfileSummary from "components/profile/ProfileSummary";
import Stepper from "components/profile/Stepper";

import {
  BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS,
  SAVE_PROFILE_OPTIONS,
} from "utils/consts";
import {
  DASHBOARD_PATH,
  EDIT_PROFILE_PATH,
  NEW_PROFILE_PATH,
  ORDER_PLATE_PATH,
  USER_PATH,
} from "utils/routes";
import {
  renderErrorToast,
  renderInformationToast,
  renderSuccessToast,
} from "utils/toasts";
import { ProfileSaveOption } from "types/Others";
import { StepsRange } from "types/Profile";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";
import { useQuestionModalContext } from "global-contexts/QuestionModalContext";

import { ReactComponent as FloppyDiskIcon } from "assets/floppy-disk-icon.svg";
import {AppMode} from "../../types/AppMode";

const ProfileContent = ({appMode}: { appMode: AppMode}) => {
  const [firstDescriptionDataFetchDone, setFirstDescriptionDataFetchDone] =
    useState<boolean>(false);
  const [wasProfileSavedOnServer, setWasProfileSavedOnServer] =
    useState<boolean>(false);
  const [
    basicDataFormIsDirtyStatusToBeCleared,
    setBasicDataFormIsDirtyStatusToBeCleared,
  ] = useState<boolean>(false);
  const [
    descriptionFormIsDirtyStatusToBeCleared,
    setDescriptionFormIsDirtyStatusToBeCleared,
  ] = useState<boolean>(false);

  const {
    profileGeneralData,
    setProfileGeneralData,
    personDescription,
    setPersonDescription,
    activeStepIndex,
    setActiveStepIndex,
    allFormsIsDirtyStatuses,
    setAllFormsIsDirtyStatuses,
    resetForm,
  } = useProfileDataContext();

  const { isDarkTheme } = useColorThemeContext();

  const { openQuestionModal } = useQuestionModalContext();

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const firstStepContent = useMemo(() => {
    if (profileGeneralData) {
      return profileGeneralData.fields.map((field) => field.fieldName);
    }
  }, [profileGeneralData]);

  const secondStepContent = useMemo(() => {
    if (personDescription) {
      return personDescription.map((section) => section.header);
    }
  }, [personDescription]);

  const goToPreviousStep = () => {
    if (activeStepIndex - 1 >= 1) {
      setActiveStepIndex((activeStepIndex - 1) as StepsRange);
    }
  };

  const goToNextStep = () => {
    if (activeStepIndex + 1 <= 3) {
      setActiveStepIndex((activeStepIndex + 1) as StepsRange);
    }
  };

  useEffect(
    function fetchProfileBasicData() {
      if (params.profileId) {
        httpService
          .get(`api/passedaway/person/v1/${params.profileId}/`)
          .then((response) => {
            setProfileGeneralData(response.data);
            if (!location.pathname.includes(NEW_PROFILE_PATH)) {
              setWasProfileSavedOnServer(true);
            }
          })
          .catch(() => {
            renderErrorToast(t("errorOccurred"));
          });
      } else {
        httpService
          .post("api/passedaway/person/v1/")
          .then((response) => {
            navigate(
              `${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}/${response.data.id}`,
              {
                replace: true,
              }
            );
          })
          .catch(() => {
            renderErrorToast(t("errorOccurred"));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.profileId, setWasProfileSavedOnServer, t]
  );

  useEffect(
    function fetchProfileDescriptionData() {
      if (
        !firstDescriptionDataFetchDone &&
        profileGeneralData &&
        (profileGeneralData.id || params.profileId)
      ) {
        httpService
          .get(
            `api/passedaway/person/v1/${
              params.profileId ? params.profileId : profileGeneralData.id
            }/sections/`
          )
          .then((response) => {
            setFirstDescriptionDataFetchDone(true);
            setPersonDescription(response.data);
          })
          .catch(() => {
            renderErrorToast(t("errorOccurred"));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.profileId, profileGeneralData, t]
  );

  const deleteProfile = () => {
    httpService
      .delete(`api/passedaway/person/v1/${profileGeneralData?.id}/`)
      .then(() => {
        resetFormAndNavigateToDashboard();
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  };

  const resetFormAndNavigateToDashboard = () => {
    navigate(`${USER_PATH}${DASHBOARD_PATH}`, { replace: true });
    setWasProfileSavedOnServer(false);
    resetForm();
  };

  const resetFormAndNavigateToOrderPlate = () => {
    setWasProfileSavedOnServer(false);
    resetForm();
    navigate(`${USER_PATH}${ORDER_PLATE_PATH}`, { replace: true });
  };

  const nameLength = profileGeneralData?.fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.NAME
  )?.content.length;

  const saveProfile = (saveOption?: ProfileSaveOption) => {
    if (nameLength && nameLength > 0) {
      httpService
        .patch(`api/passedaway/person/v1/${profileGeneralData?.id}/`, {
          fields: profileGeneralData?.fields,
        })
        .then(() => {
          httpService
            .put(
              `/api/passedaway/person/v1/${profileGeneralData?.id}/sections/bulk-update/`,
              personDescription
            )
            .then(() => {
              setWasProfileSavedOnServer(true);
              setBasicDataFormIsDirtyStatusToBeCleared(true);
              setDescriptionFormIsDirtyStatusToBeCleared(true);
              setAllFormsIsDirtyStatuses({
                basicData: false,
                sections: false,
                subsections: false,
              });

              if (!saveOption) {
                renderSuccessToast(t("addProfile.profileHasBeenSaved"));
              } else if (
                saveOption === SAVE_PROFILE_OPTIONS.ON_NAVIGATE_TO_ORDER
              ) {
                resetFormAndNavigateToOrderPlate();
              } else if (saveOption === SAVE_PROFILE_OPTIONS.ON_QUIT_FORM) {
                renderSuccessToast(t("addProfile.profileHasBeenSaved"));
                resetFormAndNavigateToDashboard();
              }
            })
            .catch(() => {
              renderErrorToast(t("errorOccurred"));
            });
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    } else {
      renderInformationToast(t("addProfile.personNameValidation"));
    }
  };

  const exitProfileEditingWithoutSaving = () => {
    if (!wasProfileSavedOnServer) {
      deleteProfile();
    } else {
      resetFormAndNavigateToDashboard();
    }
  };

  const exitProfileWithSaving = () => {
    saveProfile(SAVE_PROFILE_OPTIONS.ON_QUIT_FORM);
  };

  const onProfileFinishedEditing = () => {
      switch (appMode) {
        case "onprem":
          return saveProfile(SAVE_PROFILE_OPTIONS.ON_QUIT_FORM);
        case "standalone":
          return saveProfile(SAVE_PROFILE_OPTIONS.ON_NAVIGATE_TO_ORDER);
      }
  }

  const profileFinishedText = () => {
      switch (appMode) {
        case "onprem":
          return t("orderPlate.saveProfile");
        case "standalone":
          return t("orderPlate.saveProfileAndOrderPlate")
      }
  }
  const areAnyUnsavedChanges = Object.values(allFormsIsDirtyStatuses).some(
    (value) => value
  );

  const handleExitClick = () => {
    if (!wasProfileSavedOnServer) {
      openQuestionModal({
        text: t("addProfile.wantGoOutProfileWillBeDeleted"),
        middleButtonText: t("addProfile.quitWithoutSaving"),
        middleButtonAction: exitProfileEditingWithoutSaving,
        rightButtonText: t("addProfile.saveAndQuit"),
        rightButtonAction: exitProfileWithSaving,
      });
    } else if (areAnyUnsavedChanges) {
      openQuestionModal({
        text: t("addProfile.wantGoOutUnsavedChangesWillBeLost"),
        middleButtonText: t("addProfile.quitWithoutSaving"),
        middleButtonAction: exitProfileEditingWithoutSaving,
        rightButtonText: t("addProfile.saveAndQuit"),
        rightButtonAction: exitProfileWithSaving,
      });
    } else {
      openQuestionModal({
        text: t("addProfile.wantGoOut"),
        rightButtonAction: exitProfileEditingWithoutSaving,
      });
    }
  };

  const renderHeader = () => (
    <Header>
      <Headline>{t("addProfile.title")}</Headline>
      <HeaderButtons>
        <OutlineButton isGray onClick={handleExitClick} noMarginTop>
          {t("addProfile.exit")}
        </OutlineButton>
        <OutlineButton
          isGray
          onClick={saveProfile}
          noMarginTop
          disabled={!areAnyUnsavedChanges}
        >
          <StyledFloppyDiskIcon />
          {t("addProfile.saveDraft")}
        </OutlineButton>
      </HeaderButtons>
    </Header>
  );

  const renderMainContent = () => {
    switch (activeStepIndex) {
      case 1:
        return (
          <AddBasicDataForm
            basicDataFormIsDirtyStatusToBeCleared={
              basicDataFormIsDirtyStatusToBeCleared
            }
            setBasicDataFormIsDirtyStatusToBeCleared={
              setBasicDataFormIsDirtyStatusToBeCleared
            }
          />
        );
      case 2:
        return (
          <AddDescription
            descriptionFormIsDirtyStatusToBeCleared={
              descriptionFormIsDirtyStatusToBeCleared
            }
            setDescriptionFormIsDirtyStatusToBeCleared={
              setDescriptionFormIsDirtyStatusToBeCleared
            }
          />
        );
      case 3:
        return <ProfileSummary />;
    }
  };

  return (
    <>
      {profileGeneralData && (
        <>
          <Container isDarkTheme={isDarkTheme}>
            {renderHeader()}
            {firstStepContent && secondStepContent && (
                <Stepper
                  activeStepIndex={activeStepIndex}
                  firstStepContent={firstStepContent}
                  secondStepContent={secondStepContent}
                />

            )}
            {renderMainContent()}
            <ButtonsContainer>
              {activeStepIndex !== 1 && (
                <PaddedButton>
                  <OutlineButton lateralPadding={20} onClick={goToPreviousStep}>
                    {t("goBack")}
                  </OutlineButton>
                </PaddedButton>
              )}
              <StyledMainButton
                onClick={
                  activeStepIndex === 3
                    ? onProfileFinishedEditing
                    : goToNextStep
                }
              >
                {activeStepIndex === 3
                  ? profileFinishedText()
                  : t("continue")}
              </StyledMainButton>
            </ButtonsContainer>
          </Container>
          <ProfileLivePreview />
        </>
      )}
    </>
  );
};

export default ProfileContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 65%;
  background: ${({theme}) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({theme}) => theme.colors.darkOrLight};
  padding: 15px 20px 20px 32px;

  display: grid;
  @media (min-width: 1220px) {
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1.2fr 12fr 1fr;
    grid-template-areas:
    "header header"
    "steps form"
    "steps buttons";
    column-gap: 15px;
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 16fr 1fr;
    grid-template-areas:
      "header"
      "form"
      "buttons";
    padding: 0px;
    max-height: 150vh;
  }

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  padding-right: 25px;
  @media (max-width: 720px) {
    display: grid;
  }
`;

const PaddedButton = styled.div`
  @media (max-width: 720px) {
    display: grid;
    margin-left: 15px;

  }

`
const Headline = styled.div`
  width: 318px;
  margin-top: 13px;
  @media (max-width: 720px) {
    display: grid;
  }
`;

const HeaderButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 10px;
`;

const StyledFloppyDiskIcon = styled(FloppyDiskIcon)`
  margin-bottom: 5px;
  margin-right: 7px;
`;

const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 25px;
  padding-bottom: 3px;
  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const StyledMainButton = styled(MainButton)`
  margin-left: 15px;
  @media (max-width: 720px) {
    display: grid;
  }
`;
