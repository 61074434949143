import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useAppSelector } from "store/storeHooks";
import * as Sentry from "@sentry/react";

import "bootstrap/dist/css/bootstrap.min.css";

import ContextProviders from "global-contexts/ContextProviders";
import AddProfilePage from "views/AddProfilePage";
import AuthPage from "views/AuthPage";
import ContactPage from "views/ContactPage";
import DashboardPage from "views/DashboardPage";
import FailedPaymentPage from "views/FailedPaymentPage";
import ForgottenPassword from "components/auth/ForgottenPassword";
import Login from "components/auth/Login";
import MainTemplate from "components/MainTemplate";
import OrderPlatePage from "views/OrderPlatePage";
import OrdersPage from "views/OrdersPage";
import PrivacyPolicyPage from "views/PrivacyPolicyPage";
import PublicProfilePage from "views/PublicProfilePage";
import ResetPassword from "components/auth/ResetPassword";
import SettingsPage from "views/SettingsPage";
import SignIn from "components/auth/SignIn";
import SingleOrderSummaryPage from "views/SingleOrderSummaryPage";
import SuccessfullPaymentPage from "views/SuccessfullPaymentPage";
import TermsAndConditionsPage from "views/TermsAndConditionsPage";

import {
  AUTH_PATH,
  CONTACT_PATH,
  DASHBOARD_PATH,
  EDIT_PROFILE_PATH,
  FAILED_PAYMENT_PATH,
  FORGOTTEN_PASSWORD_PATH,
  LOGIN_PATH,
  NEW_PROFILE_PATH,
  ORDER_NUMBER_PARAM,
  ORDER_PLATE_PATH,
  ORDERS_PATH,
  PRIVACY_POLICY_PATH,
  PROFILE_ID_PARAM,
  PUBLIC_PROFILE_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_PATH,
  SIGN_IN_PATH,
  SINGLE_ORDER_PATH, STATIC_PAGE_PATH,
  SUCCESSFUL_PAYMENT_PATH,
  TERMS_AND_CONDITIONS_PATH,
  TOKEN_PARAM,
  USER_ID_PARAM,
  USER_PATH,
} from "utils/routes";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { darkTheme, lightTheme } from "global-styles/colorThemes";
import { selectAuthToken } from "store/auth/AuthSlice";

import "utils/translations/i18n";
import React from "react";
import StaticPage from "./views/StaticPage";
import LandingPage from "./views/LandingPage";
import {AppMode} from "./types/AppMode";

const LoggedRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Router = (authToken: string | null, appMode: AppMode) => {
  return(
    <BrowserRouter>
      <LoggedRoutes>
        <Route
          path={`${USER_PATH}${PUBLIC_PROFILE_PATH}${PROFILE_ID_PARAM}`}
          element={<PublicProfilePage />}
        />
        <Route
          path={USER_PATH}
          element={
            authToken ? (
              <MainTemplate appMode={appMode} />
            ) : (
              <Navigate to={`${AUTH_PATH}${SIGN_IN_PATH}`} />
            )
          }
        >
          <Route
            path={`${USER_PATH}${DASHBOARD_PATH}`}
            element={<DashboardPage />}
          />
          <Route
            path={`${USER_PATH}${ORDER_PLATE_PATH}`}
            element={<OrderPlatePage />}
          />
          <Route
            path={`${USER_PATH}${ORDERS_PATH}`}
            element={<OrdersPage />}
          />
          <Route
            path={`${USER_PATH}${SINGLE_ORDER_PATH}${ORDER_NUMBER_PARAM}`}
            element={<SingleOrderSummaryPage />}
          />
          <Route
            path={`${USER_PATH}${SUCCESSFUL_PAYMENT_PATH}${ORDER_NUMBER_PARAM}`}
            element={<SuccessfullPaymentPage />}
          />
          <Route
            path={`${USER_PATH}${FAILED_PAYMENT_PATH}`}
            element={<FailedPaymentPage />}
          />
          <Route
            path={`${USER_PATH}${SETTINGS_PATH}`}
            element={<SettingsPage />}
          />
          <Route
            path={`${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}`}
            element={<AddProfilePage appMode={appMode} />}
          />
          <Route
            path={`${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}${PROFILE_ID_PARAM}`}
            element={<AddProfilePage appMode={appMode} />}
          />
          <Route
            path={`${USER_PATH}${EDIT_PROFILE_PATH}${PROFILE_ID_PARAM}`}
            element={<AddProfilePage appMode={appMode} />}
          />
          <Route
            path={`${USER_PATH}${TERMS_AND_CONDITIONS_PATH}`}
            element={<TermsAndConditionsPage />}
          />
          <Route
            path={`${USER_PATH}${PRIVACY_POLICY_PATH}`}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={`${USER_PATH}${CONTACT_PATH}`}
            element={<ContactPage />}
          />
          <Route
            path={""}
            element={
              <Navigate
                to={`${USER_PATH}${DASHBOARD_PATH}`}
                replace={true}
              />
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to={`${USER_PATH}${DASHBOARD_PATH}`}
                replace={true}
              />
            }
          />
        </Route>
        <Route
          path={AUTH_PATH}
          element={
            !authToken ? (
              <AuthPage />
            ) : (
              <Navigate to={USER_PATH} replace={true} />
            )
          }
        >
          <Route
            path={`${AUTH_PATH}${SIGN_IN_PATH}`}
            element={<SignIn />}
          />
          <Route path={`${AUTH_PATH}${LOGIN_PATH}`} element={<Login />} />
          <Route
            path={`${AUTH_PATH}${FORGOTTEN_PASSWORD_PATH}`}
            element={<ForgottenPassword />}
          />
          <Route
            path={`${AUTH_PATH}${RESET_PASSWORD_PATH}${USER_ID_PARAM}${TOKEN_PARAM}`}
            element={<ResetPassword />}
          />
          <Route
            path={""}
            element={<Navigate to={USER_PATH} replace={true} />}
          />
          <Route
            path="*"
            element={<Navigate to={USER_PATH} replace={true} />}
          />
        </Route>

        {/* Landing page tutaj */}
        <Route
          path={""}
          element={<LandingPage />}
        >
          <Route
            path={""}
            element={<LandingPage />}
          />
        </Route>
        {/* koniec landing page */}

        <Route
          path="*"
          element={<Navigate to={USER_PATH} replace={true} />}
        />
        <Route
          path={STATIC_PAGE_PATH}
          element={<StaticPage />}
        >
          <Route
            path={`${STATIC_PAGE_PATH}${TERMS_AND_CONDITIONS_PATH}`}
            element={<TermsAndConditionsPage />}
          />
          <Route
            path={`${STATIC_PAGE_PATH}${PRIVACY_POLICY_PATH}`}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={`${STATIC_PAGE_PATH}${CONTACT_PATH}`}
            element={<ContactPage />}
          />
        </Route>
      </LoggedRoutes>
    </BrowserRouter>
  )
}
const App = ({appMode}: { appMode: AppMode}) => {
  const authToken = useAppSelector(selectAuthToken);
  const { isDarkTheme } = useColorThemeContext();

  return (
    <React.StrictMode>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <ContextProviders>
          {Router(authToken, appMode)}
        </ContextProviders>
      </ThemeProvider>
    </React.StrictMode>
  );
};


export default App;
