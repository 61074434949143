import { Dispatch, SetStateAction, useRef } from "react";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

import "../styles/ParcelLockersModalStyles.css";

declare global {
  interface Window {
    chosenParcelLocker: any;
  }
}

Modal.setAppElement(document.getElementById("root")!);

interface Props {
  isParcelLockersModalOpen: boolean;
  setIsParcelLockersModalOpen: Dispatch<SetStateAction<boolean>>;
  setChosenParcelLocker: Dispatch<SetStateAction<string | undefined>>;
}

const ParcelLockersModal = ({
  isParcelLockersModalOpen,
  setIsParcelLockersModalOpen,
  setChosenParcelLocker,
}: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    setIsParcelLockersModalOpen(false);
  };

  const handleTooltipClick = (e: React.MouseEvent<HTMLElement>) => {
    if ((e.target as Element).className === "select-link") {
      setChosenParcelLocker(
        `${window.chosenParcelLocker.name} - ${window.chosenParcelLocker.address.line1}, ${window.chosenParcelLocker.address.line2} (${window.chosenParcelLocker.location_description})`
      );
      closeModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isParcelLockersModalOpen}
        onRequestClose={closeModal}
        overlayClassName={{
          base: "overlay-base-parcel-lockers",
          afterOpen: "overlay-after-parcel-lockers",
          beforeClose: "overlay-before-parcel-lockers",
        }}
        className={{
          base: "content-base-parcel-lockers",
          afterOpen: "content-after-parcel-lockers",
          beforeClose: "content-before-parcel-lockers",
        }}
        closeTimeoutMS={500}
      >
        {isParcelLockersModalOpen && (
          <>
            <Helmet>
              <script
                async
                src="https://sandbox-geowidget.easypack24.net/js/sdk-for-javascript.js"
              ></script>
              <link
                rel="stylesheet"
                type="text/css"
                href="https://sandbox-geowidget.easypack24.net/css/easypack.css"
              ></link>
              <script type="text/javascript">
                {`window.easyPackAsyncInit = function () {
                easyPack.init({
                  points: 
                    {
                      types: ['parcel_locker']
                    },
                });

                var map = easyPack.mapWidget('easypack-map', function(point) {
                  window.chosenParcelLocker = point;
                });
              };`}
              </script>
            </Helmet>
            <div style={{ borderRadius: "10px" }}>
              <div
                id="easypack-map"
                onClick={handleTooltipClick}
                ref={mapRef}
              ></div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ParcelLockersModal;
