import { createContext, ReactNode, useContext, useState } from "react";

import QuestionModal from "components/shared-modals/QuestionModal";

export interface QuestionModalContent {
  text: string;
  leftButtonText?: string;
  leftButtonAction?: () => void;
  middleButtonText?: string;
  middleButtonAction?: () => void;
  rightButtonText?: string;
  rightButtonAction: () => void;
}

interface QuestionModalProvider {
  children: ReactNode;
}

export const QuestionModalContext = createContext<{
  isQuestionModalOpen: boolean;
  openQuestionModal: (questionModalContent?: QuestionModalContent) => void;
  closeQuestionModal: () => void;
}>({
  isQuestionModalOpen: false,
  openQuestionModal: () => {},
  closeQuestionModal: () => {},
});

export const QuestionModalContextProvider = ({
  children,
}: QuestionModalProvider) => {
  const [isQuestionModalOpen, setIsQuestionModalOpen] =
    useState<boolean>(false);
  const [questionModalContent, setQuestionModalContent] =
    useState<QuestionModalContent>();

  return (
    <QuestionModalContext.Provider
      value={{
        isQuestionModalOpen,
        openQuestionModal: (questionModalContent?: QuestionModalContent) => {
          setIsQuestionModalOpen(true);
          setQuestionModalContent(questionModalContent);
        },
        closeQuestionModal: () => {
          setIsQuestionModalOpen(false);
          setQuestionModalContent(undefined);
        },
      }}
    >
      <QuestionModal questionModalContent={questionModalContent} />
      {children}
    </QuestionModalContext.Provider>
  );
};

export const useQuestionModalContext = () => {
  const context = useContext(QuestionModalContext);

  if (context === undefined) {
    throw new Error(
      "useQuestionModalContext must be used within the QuestionModalContextProvider"
    );
  }

  return context;
};
