import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { Item } from "react-photoswipe-gallery";
import { useTranslation } from "react-i18next";

import "photoswipe/dist/photoswipe.css";

import { Photo } from "types/Profile";
import { renderErrorToast } from "utils/toasts";

interface ImageDimenstions {
  width: string;
  height: string;
}

interface Props {
  photoObject: Photo;
  isSwiper?: boolean;
}

const PhotoSwipe = ({ photoObject, isSwiper }: Props) => {
  const [imageDimensions, setImageDimensions] = useState<ImageDimenstions>();

  const { t } = useTranslation();

  const loadImage = useCallback(
    (
      setImageDimensions: Dispatch<
        SetStateAction<ImageDimenstions | undefined>
      >,
      imageUrl: string
    ) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        setImageDimensions({
          width: String(img.width),
          height: String(img.height),
        });
      };
      img.onerror = () => {
        renderErrorToast(t("errorOccurred"));
      };
    },
    [t]
  );

  useEffect(() => {
    loadImage(setImageDimensions, photoObject.photo);
  }, [photoObject, loadImage]);

  return (
    <Item
      original={photoObject.photo}
      width={imageDimensions?.width}
      height={imageDimensions?.height}
    >
      {({ ref, open }) => (
        <PhotosWrapper>
          {!isSwiper && (
            <ProfileMainPhoto
              // @ts-expect-error
              ref={ref}
              onClick={open}
              src={photoObject.photo}
              alt={photoObject.originalFileName}
            />
          )}
          {isSwiper && (
            <div>
              <SectionImage
                // @ts-expect-error
                ref={ref}
                onClick={open}
                src={photoObject.photo}
                alt={photoObject.originalFileName}
              />
            </div>
          )}
        </PhotosWrapper>
      )}
    </Item>
  );
};

export default PhotoSwipe;

const PhotosWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 10px;
`;

const ProfileMainPhoto = styled.img`
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
`;

const SectionImage = styled.img`
  cursor: pointer;
`;
