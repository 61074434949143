import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SectionContent,
  SectionMainLine,
  SummaryDescription,
} from "global-styles/commonOrderStyles";

import { PAYMENT_METHODS_API_NAMES_KEYS } from "utils/consts";
import { PlacedOrderFullData } from "types/Order";

interface Props {
  orderFullData: PlacedOrderFullData | undefined;
}

const PlacedOrderPayment = ({ orderFullData }: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Header>
        <SummaryDescription>{t("orderPlate.paymentMethod")}</SummaryDescription>
      </Header>
      <SectionContent>
        {orderFullData?.details.payment.name ===
          PAYMENT_METHODS_API_NAMES_KEYS.PRZELEWY_24 && (
          <SectionMainLine>{t("orderPlate.przelewy24")}</SectionMainLine>
        )}
        {orderFullData?.details.payment.name ===
          PAYMENT_METHODS_API_NAMES_KEYS.PAYMENT_ON_DELIVERY && (
          <SectionMainLine>{t("orderPlate.paymentOnDelivery")}</SectionMainLine>
        )}
      </SectionContent>
    </Section>
  );
};

export default PlacedOrderPayment;
