import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";
import {OrderFormData} from "types/Order";
import {renderErrorToast} from "utils/toasts";
import {useEffect, useState} from "react";

const SubmitOrderButton = () => {
  const {t} = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();
  const [canFocus, setCanFocus] = useState(true)

  const finilizeOrder = (redirectTo: string) => {
    window.open(`${redirectTo}`, "_self", "noopener,noreferrer");
  };

  const order = (values: OrderFormData) => {
    httpService
      .post(`api/ecommerce/order/v1/checkout/`, values)
      .then((response) => {
        finilizeOrder(response.data.redirectTo);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  };

  useEffect(() => {
    if (orderPlateFormMethods.formState.errors ) {
      const errors = Array.from(document.getElementsByClassName("error-container"));
      errors.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);


      if (errors.length > 0 && canFocus) {
        let errorElement = errors[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        setCanFocus(false) // so the form doesn't suddenly jump to the next input that has error.
      }
    }
  }, [orderPlateFormMethods.formState, canFocus]);

  const handleClick = () => {
    setCanFocus(true);
    orderPlateFormMethods.handleSubmit(order)();
  }
  return (
    <StyledMainButton
      expandWidth
      onClick={handleClick}
    >
      {t("orderPlate.iAmOrdering")}
    </StyledMainButton>
  );
};

export default SubmitOrderButton;

const StyledMainButton = styled(MainButton)`
  margin-top: 30px;
`;
