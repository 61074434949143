import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ADMINS_DATA } from "utils/consts";

import { ReactComponent as LetterIcon } from "assets/letter-icon.svg";

const ContactContent = () => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  return (
    <Container isDarkTheme={isDarkTheme}>
      <Title>{t("contact")}</Title>
      <Description>
        <div>{ADMINS_DATA.COMPANY_NAME}</div>
        <div>{ADMINS_DATA.NIP}</div>
        <div> {ADMINS_DATA.REGON}</div>
        <div> {ADMINS_DATA.KRS}</div>
        <LinkTo href={`mailto:${ADMINS_DATA.EMAIL_ADDRESS}`}>
          <LinkToContent>
            <StyledLetterIcon />
            {ADMINS_DATA.EMAIL_ADDRESS}
          </LinkToContent>
        </LinkTo>
      </Description>
    </Container>
  );
};

export default ContactContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 24px;
`;

const LinkTo = styled.a`
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;

  color: ${({ theme }) => theme.colors.darkOrLight};
  transition: 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.darkOrLight};
    opacity: 0.6;
  }
`;

const LinkToContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLetterIcon = styled(LetterIcon)`
  width: 18px;
  height: 14px;
  margin-top: 1px;
  margin-right: 10px;
`;
