import { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Gallery } from "react-photoswipe-gallery";
import { useTranslation } from "react-i18next";

import "./styles/PreviewModalStyles.css";
import "photoswipe/dist/photoswipe.css";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
  smallButtonStyle,
} from "global-styles/sharedStyles";

import BasicDataField from "components/profile-preview-commons/BasicDataField";
import FirstAndLastNameField from "components/profile-preview-commons/FirstAndLastNameField";
import SectionContent from "components/profile-preview-commons/SectionContent";
import SwiperSlideWithPhotoSwipe from "components/profile/SwiperSlideWithPhotoSwipe";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

Modal.setAppElement(document.getElementById("root")!);

interface Props {
  isPreviewModalOpen: boolean;
  setIsPreviewModalOpen: Dispatch<SetStateAction<boolean>>;
}

const PreviewModal = ({ isPreviewModalOpen, setIsPreviewModalOpen }: Props) => {
  const { profileGeneralData, personDescription } = useProfileDataContext();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const closeModal = () => {
    setIsPreviewModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isPreviewModalOpen}
        onRequestClose={closeModal}
        overlayClassName={{
          base: "overlay-base-preview",
          afterOpen: "overlay-after-preview",
          beforeClose: "overlay-before-preview",
        }}
        className={{
          base: "content-base-preview",
          afterOpen: "content-after-preview",
          beforeClose: "content-before-preview",
        }}
        closeTimeoutMS={500}
      >
        <Background>
          <ContentWrapper>
            <PageWrapper>
              <HidePreviewButton onClick={closeModal}>
                {t("addProfile.closePreview")}
              </HidePreviewButton>
              <Container isDarkTheme={isDarkTheme}>
                {profileGeneralData && personDescription && (
                  <PreviewWrapper>
                    {profileGeneralData.photo && (
                      <ProfilePhotoWrapper>
                        <Gallery>
                          <SwiperSlideWithPhotoSwipe
                            photoObject={profileGeneralData.photo}
                          />
                        </Gallery>
                      </ProfilePhotoWrapper>
                    )}
                    <FirstAndLastNameField
                      profileGeneralData={profileGeneralData}
                    />
                    <BasicDataField profileGeneralData={profileGeneralData} />
                    {personDescription.map((section, index) => (
                      <Section key={index}>
                        <SectionContent section={section} />
                      </Section>
                    ))}
                  </PreviewWrapper>
                )}
              </Container>
            </PageWrapper>
          </ContentWrapper>
        </Background>
      </Modal>
    </>
  );
};

export default PreviewModal;

const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 95vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.lightOrDark};
`;

const PageWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HidePreviewButton = styled.button`
  ${smallButtonStyle}
  margin-top: 0;
  border: 1px solid ${({ theme }) => theme.colors.whiteOrDark};
  width: 120px;
  text-align: center;
`;

const Container = styled.div<{ isDarkTheme: boolean }>`
  max-width: 500px;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  flex-direction: column;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const PreviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 4px;
  padding: 30px 0 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
`;

const ProfilePhotoWrapper = styled.div`
  max-width: 300px;
  margin: 0 30px;
`;

const Section = styled.section`
  width: 100%;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }
`;
