import Modal from "react-modal";
import styled from "styled-components";

import "./styles/QrCodeModal.css";
import { lightTheme } from "global-styles/colorThemes";

import { PLATES_COLORS_API_NAMES } from "utils/consts";

import { ReactComponent as XThickIcon } from "assets/x-thick-icon.svg";

Modal.setAppElement(document.getElementById("root")!);

interface Props {
  isQrCodeModalOpen: boolean;
  closeModal: () => void;
  profileId: string | undefined;
  plateColor: string | undefined;
}

const QrCodeModal = ({
  isQrCodeModalOpen,
  closeModal,
  profileId,
  plateColor,
}: Props) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <Modal
        isOpen={isQrCodeModalOpen}
        onRequestClose={closeModal}
        overlayClassName={{
          base: "overlay-base-qr-code",
          afterOpen: "overlay-after-qr-code",
          beforeClose: "overlay-before-qr-code",
        }}
        className={{
          base: "content-base-qr-code",
          afterOpen: "content-after-qr-code",
          beforeClose: "content-before-qr-code",
        }}
        closeTimeoutMS={500}
      >
        <CloseButton onClick={closeModal}>
          <StyledXIcon />
        </CloseButton>
        <QrCodeWrapper>
          <QrCodeImg
            src={`${baseUrl}api/passedaway/person/v1/${profileId}/qr-code/?color=${plateColor}`}
            alt="qrcode"
            addBlackBorder={plateColor === PLATES_COLORS_API_NAMES.WHITE}
          />
        </QrCodeWrapper>
      </Modal>
    </>
  );
};

export default QrCodeModal;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 20px;
  top: 20px;
`;

const StyledXIcon = styled(XThickIcon)`
  width: 25px;
  height: 25px;
  color: ${lightTheme.colors.darkOrLight};

  &:hover {
    opacity: 0.6;
  }
`;

const QrCodeWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QrCodeImg = styled.img<{ addBlackBorder: boolean }>`
  width: 300px;
  height: 300px;
  border-radius: 8px;
  ${(p) =>
    p.addBlackBorder && `border: 1px solid ${lightTheme.colors.darkOrLight}}`}
`;
