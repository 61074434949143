import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS } from "utils/consts";
import { EDIT_PROFILE_PATH, USER_PATH } from "utils/routes";
import { ProfileGeneralData } from "types/Profile";

import { ReactComponent as EditIcon } from "assets/edit-icon.svg";

const DashboardTableRow = ({
  fields,
  id,
  createdAt,
  updatedAt,
}: ProfileGeneralData) => {
  const firstName = fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.NAME
  )?.content;

  const lastName = fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.SURNAME
  )?.content;

  return (
    <Row>
      <RowItem>
        <span>{`${firstName} ${lastName}`}</span>
      </RowItem>
      <RowItem>{createdAt}</RowItem>
      <RowItem>{updatedAt}</RowItem>
      <RowItem>
        <NavLink to={`${USER_PATH}${EDIT_PROFILE_PATH}/${id}`}>
          <EditButton>
            <StyledEditIcon />
          </EditButton>
        </NavLink>
      </RowItem>
    </Row>
  );
};

export default DashboardTableRow;

const Row = styled.div`
  height: 50px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.extraLightGray};
  }
`;

const RowItem = styled.div`
  font-size: 19px;
  line-height: 150%;
  text-align: center;

  &:nth-child(1) {
    flex-basis: 49%;
    text-align: left;

    & span {
      margin-left: 10px;
    }
  }
  &:nth-child(2) {
    flex-basis: 27%;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
  &:nth-child(3) {
    flex-basis: 27%;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
  &:nth-child(4) {
    flex-basis: 27%;
  }
`;

const EditButton = styled.button`
  all: unset;
  margin-left: 3px;
  margin-bottom: 6px;
  transition: 0.5s;
  color: ${({ theme }) => theme.colors.darkOrLight};

  &:hover {
    opacity: 0.6;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  width: 21px;
  height: 21px;
`;
