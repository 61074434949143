import { useEffect, useState } from "react";
import styled from "styled-components";
import { Gallery } from "react-photoswipe-gallery";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "global-styles/SwiperStyles.css";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";
import { lightTheme } from "global-styles/colorThemes";

import httpService from "utils/httpService";
import BasicDataField from "components/profile-preview-commons/BasicDataField";
import FirstAndLastNameField from "components/profile-preview-commons/FirstAndLastNameField";
import PublicProfileBottomSection from "components/public-profile/PublicProfileBottomSection";
import SectionContent from "components/profile-preview-commons/SectionContent";
import SwiperSlideWithPhotoSwipe from "components/profile/SwiperSlideWithPhotoSwipe";
import ThemeSwitch from "components/inputs/ThemeSwitch";

import { AUTH_PATH, LOGIN_PATH } from "utils/routes";
import { PublicProfileData } from "types/Profile";
import { renderErrorToast } from "utils/toasts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

const PublicProfileContent = () => {
  const [publicProfileData, setPublicProfileData] =
    useState<PublicProfileData>();

  const navigate = useNavigate();
  const params = useParams();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  useEffect(
    function fetchProfileBasicData() {
      httpService
        .get(`api/passedaway/person/v1/${params.profileId}/public/`)
        .then((response) => {
          setPublicProfileData(response.data);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
          navigate(`${AUTH_PATH}${LOGIN_PATH}`, { replace: true });
        });
    },
    [navigate, params.profileId, t]
  );

  return (
    <Background>
      <Container isDarkTheme={isDarkTheme}>
        <HeaderWrapper>
          <StyledLink to={`${AUTH_PATH}${LOGIN_PATH}`}>
            <AppTitle>{t("memero")}</AppTitle>
          </StyledLink>
          <ThemeSwitchWrapper>
            <ThemeSwitch isPublicProfile />
          </ThemeSwitchWrapper>
        </HeaderWrapper>
        <PreviewWrapper>
          {publicProfileData && (
            <>
              {publicProfileData.photo && (
                <ProfilePhotoWrapper>
                  <Gallery>
                    <SwiperSlideWithPhotoSwipe
                      photoObject={publicProfileData.photo}
                    />
                  </Gallery>
                </ProfilePhotoWrapper>
              )}
              <FirstAndLastNameField profileGeneralData={publicProfileData} />
              <BasicDataField profileGeneralData={publicProfileData} />
              {publicProfileData.sections.map((section, index) => (
                <Section key={index}>
                  <SectionContent section={section} />
                </Section>
              ))}
            </>
          )}
        </PreviewWrapper>
        <PublicProfileBottomSection />
      </Container>
    </Background>
  );
};

export default PublicProfileContent;

const Background = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  background-color: ${(p) =>
    p.isDarkTheme
      ? lightTheme.colors.darkOrLight
      : p.theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  flex-direction: column;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ThemeSwitchWrapper = styled.div`
  margin-top: 15px;
  margin-right: 25px;
`;

const AppTitle = styled.div<{ modifiedMargin?: boolean }>`
  font-family: "Jost";
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
  margin: ${(p) => (p.modifiedMargin ? "10px 0 10px" : "20px 30px 0")};
`;

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: 0.5s;

  &:hover {
    color: inherit;
    opacity: 0.6;
  }
`;

const PreviewWrapper = styled.div`
  border-radius: 4px;
  padding: 30px 0 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
`;

const ProfilePhotoWrapper = styled.div`
  max-width: 300px;
`;

const Section = styled.section`
  width: 100%;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }
`;
