import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
  smallButtonStyle,
} from "global-styles/sharedStyles";

import AddSectionDataForm from "components/profile/forms/AddSectionDataForm";
import AddSectionForm from "components/profile/forms/AddSectionForm";
import httpService from "utils/httpService";
import ProfilePhotoUpload from "components/profile/ProfilePhotoUpload";
import ReorderModal from "components/profile/reorder-drag-and-drop/ReorderModal";

import {
  AddSectionFormValues,
  useAddSectionFormController,
} from "components/profile/forms/AddSectionFormController";
import { PersonDescriptionSection } from "types/Profile";
import { renderErrorToast, renderInformationToast } from "utils/toasts";
import { scrollToBottomOfDivElement } from "utils/utilityFunctions";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

interface Props {
  descriptionFormIsDirtyStatusToBeCleared: boolean;
  setDescriptionFormIsDirtyStatusToBeCleared: Dispatch<SetStateAction<boolean>>;
}

const AddDescription = ({
  descriptionFormIsDirtyStatusToBeCleared,
  setDescriptionFormIsDirtyStatusToBeCleared,
}: Props) => {
  const [isReorderModalOpen, setIsReorderModalOpen] = useState<boolean>(false);
  const [subsectionFormToBeUpdated, setSubsectionFormToBeUpdated] =
    useState<boolean>(false);

  const {
    profileGeneralData,
    personDescription,
    setPersonDescription,
    allFormsIsDirtyStatuses,
    setAllFormsIsDirtyStatuses,
  } = useProfileDataContext();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const addFieldFormMethods = useAddSectionFormController();

  const inputsWrapperRef = useRef<HTMLDivElement>(null);

  const addNewField = (values: AddSectionFormValues) => {
    const doesSectionNameAlreadyExist = personDescription
      .map((section) => section.header)
      .includes(values.newFieldName);

    if (!doesSectionNameAlreadyExist) {
      addFieldFormMethods.reset();

      httpService
        .post(`api/passedaway/person/v1/${profileGeneralData?.id}/sections/`, {
          header: values.newFieldName,
          subsections: [
            {
              subtitle: "",
              startDate: "",
              endDate: "",
              rawContent: "",
            },
          ],
        })
        .then((response) => {
          if (personDescription) {
            setPersonDescription([
              ...personDescription,
              {
                header: response.data.header,
                subsections: response.data.subsections,
                photos: [],
                id: response.data.id,
              },
            ]);
          } else {
            setPersonDescription([
              {
                header: response.data.header,
                subsections: response.data.subsections,
                photos: [],
                id: response.data.id,
              },
            ]);
          }

          setAllFormsIsDirtyStatuses({
            ...allFormsIsDirtyStatuses,
            sections: true,
          });

          setTimeout(() => {
            scrollToBottomOfDivElement(inputsWrapperRef);
          }, 100);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    } else {
      renderInformationToast(t("addProfile.cannotAddSectionThatExists"));
    }
  };

  const descriptionDataToReorder = personDescription?.map((field, index) => {
    return { cardId: index, text: field.header, objectContent: field };
  });

  const saveReorderedArray = (reorderedArray: PersonDescriptionSection[]) => {
    setPersonDescription(reorderedArray);
    setSubsectionFormToBeUpdated(true);
    setIsReorderModalOpen(false);
    setAllFormsIsDirtyStatuses({
      ...allFormsIsDirtyStatuses,
      sections: true,
    });
  };

  useEffect(
    function clearIsDirtyFormStatus() {
      if (descriptionFormIsDirtyStatusToBeCleared) {
        setSubsectionFormToBeUpdated(true);
        setDescriptionFormIsDirtyStatusToBeCleared(true);

        setTimeout(() => {
          setDescriptionFormIsDirtyStatusToBeCleared(false);
        }, 400);
      }
    },
    [
      descriptionFormIsDirtyStatusToBeCleared,
      setDescriptionFormIsDirtyStatusToBeCleared,
    ]
  );

  return (
    <FormWrapper>
      <FormLabel>{`${t("addProfile.addDescriptionAndPhotos")}:`}</FormLabel>
      <InputsWrapper ref={inputsWrapperRef} isDarkTheme={isDarkTheme}>
        <Subheader>{t("addProfile.profilePhoto")}</Subheader>
        <ProfilePhotoUpload />
        <Subheader>{t("addProfile.sections")}</Subheader>
        {personDescription &&
          personDescription.map((section, index) => (
            <AddSectionDataForm
              key={index}
              sectionIndex={index}
              sectionName={section.header}
              defaultFormValues={{ subsections: section.subsections }}
              subsectionFormToBeUpdated={subsectionFormToBeUpdated}
              setSubsectionFormToBeUpdated={setSubsectionFormToBeUpdated}
            />
          ))}
      </InputsWrapper>
      <ManageFields isDarkTheme={isDarkTheme}>
        <FormProvider {...addFieldFormMethods}>
          <StyledForm
            onSubmit={addFieldFormMethods.handleSubmit(addNewField)}
            noValidate
          >
            <AddSectionForm />
          </StyledForm>
        </FormProvider>
        <ReorderButton
          onClick={() => setIsReorderModalOpen(true)}
          disabled={personDescription.length < 2}
        >
          {t("addProfile.changeSectionsOrder")}
        </ReorderButton>
      </ManageFields>
      <ReorderModal
        isReorderModalOpen={isReorderModalOpen}
        setIsReorderModalOpen={setIsReorderModalOpen}
        dataToReorder={descriptionDataToReorder}
        saveReorderedArray={saveReorderedArray}
        headerText={t("addProfile.sectionsReorderText")}
      />
    </FormWrapper>
  );
};

export default AddDescription;

const FormWrapper = styled.div`
  grid-area: form;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.div`
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 150%;
  font-weight: 600;
`;

const InputsWrapper = styled.div<{ isDarkTheme: boolean }>`
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 4px 4px 0 0;
  padding: 15px;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
`;

const Subheader = styled.div`
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 10px;
`;

const ManageFields = styled.div<{ isDarkTheme: boolean }>`
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  min-height: 90px;
  border-radius: ${(p) => (p.isDarkTheme ? "0" : "0 0 4px 4px")};
  padding-left: 20px;
  padding-bottom: 4px;
  padding-right: 9px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  display: flex;

  ${(p) =>
    p.isDarkTheme && `border-bottom: 1px solid ${p.theme.colors.darkOrLight}`}
  @media (max-width: 720px) {
    display: grid;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
`;

const ReorderButton = styled.button`
  ${smallButtonStyle}
  margin-top: 41px;
  margin-left: 20px;
  height: 24px;
  margin-bottom: 0;

  @media (max-width: 1245px) {
    height: 44px;
    width: 120px;
    margin-top: 24px;
  }
`;
