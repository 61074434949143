import { Dispatch, SetStateAction, useCallback } from "react";
import update from "immutability-helper";

import { Card } from "./Card";

const style = {
  width: 400,
};

export interface Item {
  cardId: number;
  text: string;
  objectContent: any;
}

interface Props {
  cards: Item[];
  setCards: Dispatch<SetStateAction<Item[]>>;
}

const Container = ({ cards, setCards }: Props) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards((prevCards): Item[] =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Item],
          ],
        })
      );
    },
    [setCards]
  );

  const renderCard = useCallback(
    (card: { cardId: number; text: string }, index: number) => {
      return (
        <Card
          key={card.cardId}
          index={index}
          id={card.cardId}
          text={card.text}
          moveCard={moveCard}
        />
      );
    },
    [moveCard]
  );

  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};

export default Container;
