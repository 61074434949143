import { ChangeEvent, FormEvent, useRef, useState } from "react";
import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from "react-i18next";

import { colors } from "utils/outsideThemeColors";

import httpService from "utils/httpService";

import { renderErrorToast, renderSuccessToast } from "utils/toasts";
import { smallOutlineButtonStyle } from "global-styles/sharedStyles";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";
import { useQuestionModalContext } from "global-contexts/QuestionModalContext";

import { ReactComponent as AlertIcon } from "assets/alert-icon.svg";
import { ReactComponent as XIcon } from "assets/x-icon.svg";

interface Props {
  sectionIndex: number;
}

const SectionPhotosUpload = ({ sectionIndex }: Props) => {
  const [isFileSelected, setIsFileSelected] = useState<string | Blob>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { profileGeneralData, personDescription, setPersonDescription } =
    useProfileDataContext();

  const { openQuestionModal } = useQuestionModalContext();

  const selectFileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const isSectionPhotosLimitReached =
    personDescription[sectionIndex].photos.length >= 10;

  const cancelUpload = () => {
    selectFileInputRef.current!.value = "";
    setIsFileSelected(undefined);
  };

  const addPhotoToSection = (
    uploadedPhotoLink: string,
    uploadedPhotoId: string,
    uploadedPhotoName: string
  ) => {
    if (personDescription) {
      const updatedPersonDescription = personDescription.map(
        (section, index) => {
          if (sectionIndex === index) {
            return {
              header: section.header,
              subsections: section.subsections,
              photos: [
                ...section.photos,
                {
                  photo: uploadedPhotoLink,
                  id: uploadedPhotoId,
                  originalFileName: uploadedPhotoName,
                },
              ],
              id: section.id,
            };
          } else return { ...section };
        }
      );

      setPersonDescription(updatedPersonDescription);
    }
  };

  const handleFileUpload = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = new FormData();

    if (isFileSelected) {
      formData.append("photo", isFileSelected);
    }

    httpService
      .post(
        `api/passedaway/person/v1/${profileGeneralData?.id}/sections/${personDescription[sectionIndex].id}/photos/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        setIsFileSelected(undefined);
        addPhotoToSection(
          response.data.photo,
          response.data.id,
          response.data.originalFileName
        );
        selectFileInputRef.current!.value = "";
        renderSuccessToast(t("addProfile.photoHasBeenSavedInProfile"));
        setIsLoading(false);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
        setIsFileSelected(undefined);
      });
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files!.length !== 0) {
      setIsFileSelected(e.target.files[0]);
    } else if (e.target.files!.length === 0) {
      selectFileInputRef.current!.value = "";
    }
  };

  const removePhotoFromContext = (photoId: string) => {
    if (personDescription) {
      const updatedPersonDescription = personDescription.map(
        (section, index) => {
          if (sectionIndex === index) {
            return {
              header: section.header,
              subsections: section.subsections,
              photos: section.photos.filter(
                (photoObject) => photoObject.id !== photoId
              ),
              id: section.id,
            };
          } else return { ...section };
        }
      );

      setPersonDescription(updatedPersonDescription);
    }
  };

  const removePhoto = (photoId: string) => {
    httpService
      .delete(
        `api/passedaway/person/v1/${profileGeneralData?.id}/sections/${personDescription[sectionIndex].id}/photos/${photoId}/`
      )
      .then(() => {
        removePhotoFromContext(photoId);
        renderSuccessToast(t("addProfile.photoHasBeenRemovedFromProfile"));
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  };

  return (
    <>
      <UploadForm onSubmit={(e) => handleFileUpload(e)}>
        <SelectFile
          type="file"
          onChange={(e) => handleFileSelect(e)}
          ref={selectFileInputRef}
          disabled={isSectionPhotosLimitReached}
        />
        {isFileSelected && (
          <SelectedFileActions>
            {!isLoading && (
              <>
                <CancelUploadButton onClick={cancelUpload} type="button">
                  {t("cancel")}
                </CancelUploadButton>
                <UploadFileInput
                  type="submit"
                  value={t("addProfile.savePhoto")}
                />
              </>
            )}
            {isLoading && (
              <LoaderWrapper>
                <SyncLoader color={colors.dark} size={7} />
              </LoaderWrapper>
            )}
          </SelectedFileActions>
        )}
      </UploadForm>
      {isSectionPhotosLimitReached && (
        <Alert>
          <AlertIcon /> <span>{t("addProfile.maxTenPhotos")}</span>
        </Alert>
      )}
      <UploadedPhotosList>
        {personDescription &&
          personDescription[sectionIndex].photos.map((photoObject) => (
            <UploadedPhotoWrapper key={photoObject.id}>
              <DeleteFileButton
                onClick={() =>
                  openQuestionModal({
                    text: t("addProfile.wantToDeletePhotoQuestion"),
                    rightButtonAction: () => removePhoto(photoObject.id),
                  })
                }
              >
                <XIcon />
              </DeleteFileButton>
              <UploadedFileDescription>
                {photoObject.originalFileName}
              </UploadedFileDescription>
            </UploadedPhotoWrapper>
          ))}
      </UploadedPhotosList>
    </>
  );
};

export default SectionPhotosUpload;

const UploadForm = styled.form`
  margin-top: 10px;
`;

const SelectFile = styled.input`
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 8px;
  position: relative;

  &::file-selector-button {
    ${smallOutlineButtonStyle}
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.midGrayOrGray};
  }

  &:hover:disabled::file-selector-button {
    opacity: 1;
    cursor: default;
  }
`;

const UploadFileInput = styled.input`
  ${smallOutlineButtonStyle}
  background-color: ${({ theme }) => theme.colors.darkOrLight};
  color: ${({ theme }) => theme.colors.whiteOrDark};
  border: 1px solid ${({ theme }) => theme.colors.darkOrLight};
`;

const LoaderWrapper = styled.div`
  margin-left: 10px;
`;

const SelectedFileActions = styled.div`
  display: inline-block;
  margin-bottom: 4px;
`;

const CancelUploadButton = styled.button`
  ${smallOutlineButtonStyle}
`;

const Alert = styled.div`
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 7px;
  color: ${(p) => p.theme.colors.validationErrorRed};

  & span {
    vertical-align: -2px;
    margin-left: 5px;
  }
`;

const UploadedPhotosList = styled.div`
  margin-bottom: 10px;
`;

const UploadedPhotoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UploadedFileDescription = styled.div`
  font-size: 12px;
`;

const DeleteFileButton = styled.button`
  all: unset;
  margin-right: 5px;

  &:hover {
    opacity: 0.6;
  }
`;
