import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Description,
  subformContentStyle,
  SubformWrapper,
} from "global-styles/commonOrderStyles";

import InputRHF from "components/inputs/InputRHF";

import { OrderFormData } from "types/Order";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

const ShipmentDataForm = () => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();

  return (
    <SubformWrapper>
      <Description>{t("orderPlate.enterYourDataAndAddress")}</Description>
      <ShipmentData isDarkTheme={isDarkTheme}>
        <Email>
          <InputRHF
            formFieldName="shippingAddress.email"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.email")}
            placeholder={t("orderPlate.enterEmail")}
            error={orderPlateFormMethods.formState.errors.shippingAddress?.email ?.message}
            noMarginTop
            type={"email"}
          />
        </Email>
        <PhoneNumber>
          <InputRHF
            formFieldName="shippingAddress.phoneNumber"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.phoneNumber")}
            placeholder={t("orderPlate.enterPhoneNumber")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.phoneNumber
                ?.message
            }
            noMarginTop
            maxInputLength={11}
          />
        </PhoneNumber>
        <FirstName>
          <InputRHF
            formFieldName="shippingAddress.firstName"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.firstName")}
            placeholder={t("orderPlate.enterFirstName")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.street
                ?.message
            }
            noMarginTop
          />
        </FirstName>
        <LastName>
          <InputRHF
            formFieldName="shippingAddress.lastName"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.lastName")}
            placeholder={t("orderPlate.enterLastName")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.street
                ?.message
            }
            noMarginTop
          />
        </LastName>
        <Street>
          <InputRHF
            formFieldName="shippingAddress.street"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.streetAndHouseNumber")}
            placeholder={t("orderPlate.enterStreetAndHouseNumber")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.street
                ?.message
            }
            noMarginTop
          />
        </Street>
        <FlatNumber>
          <InputRHF
            formFieldName="shippingAddress.flatNumber"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.flatNumber")}
            placeholder={t("orderPlate.enterFlatNumber")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.flatNumber
                ?.message
            }
            noMarginTop
          />
        </FlatNumber>
        <City>
          <InputRHF
            formFieldName="shippingAddress.city"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.city")}
            placeholder={t("orderPlate.enterCity")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.city
                ?.message
            }
            noMarginTop
          />
        </City>
        <ZipCode>
          <InputRHF
            formFieldName="shippingAddress.zipCode"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.zipCode")}
            placeholder={t("orderPlate.enterZipCode")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.zipCode
                ?.message
            }
            noMarginTop
            maxInputLength={6}
          />
        </ZipCode>
        <Country>
          <InputRHF
            formFieldName="shippingAddress.country"
            control={orderPlateFormMethods.control}
            label={t("orderPlate.country")}
            placeholder={t("orderPlate.enterCountry")}
            error={
              orderPlateFormMethods.formState.errors.shippingAddress?.country
                ?.message
            }
            noMarginTop
          />
        </Country>
      </ShipmentData>
    </SubformWrapper>
  );
};

export default ShipmentDataForm;

const ShipmentData = styled.div<{ isDarkTheme: boolean }>`
  ${subformContentStyle}

  display: grid;
  grid-template-columns: 1fr 0.3fr 0.3fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "email email phoneNumber phoneNumber"
    "firstName firstName lastName lastName"
    "street street street flatNumber"
    "city city city zipCode"
    "country country country empty";
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "email"
    "phoneNumber"
    "firstName"
    "lastName"
    "street"
    "flatNumber"
    "city"
    "zipCode"
    "country";
  }
  column-gap: 20px;
  row-gap: 30px;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const FirstName = styled.div`
  grid-area: firstName;
`;

const LastName = styled.div`
  grid-area: lastName;
`;

const Street = styled.div`
  grid-area: street;
`;

const FlatNumber = styled.div`
  grid-area: flatNumber;
`;

const City = styled.div`
  grid-area: city;
`;

const ZipCode = styled.div`
  grid-area: zipCode;
`;

const Country = styled.div`
  grid-area: country;
`;

const Email = styled.div`
  grid-area: email;
`;

const PhoneNumber = styled.div`
  grid-area: phoneNumber;
`;
