import * as Sentry from "@sentry/react";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

export const logError = (message: string, err: any) => {
  if (sentryDsn) {
    Sentry.withScope((scope) => {
      scope.setExtra("message", message);
      Sentry.captureException(err);
    });
  } else {
    console.error(err);
  }
}
