import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../store/storeHooks";
import {useGoogleLogin} from "@react-oauth/google";
import {loginOauth} from "../../store/auth/AuthSlice";
import {logError} from "../../utils/errorLogger";
import {ButtonText, StyledSocialMediaAuthButton} from "./SocialComponents";
import React from "react";
import styled from "styled-components";
import {ReactComponent as GoogleIcon} from "assets/google-icon.svg";

export const LoginGoogle= () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;

  const googleLogIn = useGoogleLogin({
    onSuccess: tokenResponse => {
      dispatch(
        loginOauth({provider: "google", access_token: tokenResponse.access_token})
      )
    },
    onError: error => logError("Google Login Failed!", error),
    onNonOAuthError: error => logError("Google non OAuth Login Error!", error),
  });

  return (
    <div>
      {GOOGLE_APP_ID && (
        <StyledSocialMediaAuthButton
          variant="outline-dark"
          onClick={googleLogIn}
        >
          <StyledGoogleIcon/>
          <ButtonText>{t("auth.loginViaGoogle")}</ButtonText>
        </StyledSocialMediaAuthButton>
      )}
    </div>
  )
}

const StyledGoogleIcon = styled(GoogleIcon)`
  width: 26px;
  height: 26px;
  margin-left: 1px;
  margin-right: 1px;
`;
