import { createContext, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import httpService from "utils/httpService";

import { renderErrorToast } from "utils/toasts";
import { User } from "types/Others";

interface UserDataProvider {
  children: ReactNode;
}

export const UserDataContext = createContext<{
  userData?: User;
  fetchUserData: () => void;
  clearUserData: () => void;
}>({
  userData: undefined,
  fetchUserData: () => {},
  clearUserData: () => {},
});

export const UserDataContextProvider = ({ children }: UserDataProvider) => {
  const [userData, setUserData] = useState<User>();

  const { t } = useTranslation();

  const fetchUserData = () => {
    httpService
      .get("api/auth/v1/user/")
      .then((response) => {
        setUserData(response.data);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  };

  const clearUserData = () => {
    setUserData(undefined);
  };

  return (
    <UserDataContext.Provider
      value={{
        userData,
        fetchUserData,
        clearUserData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error(
      "useUserDataContext must be used within the UserDataContextProvider"
    );
  }

  return context;
};
