import {useState} from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import {FormProvider} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import httpService from "utils/httpService";
import SignInForm, {SignInFormFields} from "components/auth/forms/SignInForm";

import {AUTH_PATH, LOGIN_PATH} from "utils/routes";
import {logIn} from "store/auth/AuthSlice";
import {renderErrorToast, renderSuccessToast} from "utils/toasts";
import {SignInFormValues, useSignInFormController,} from "components/auth/forms/SignInFormController";
import {useAppDispatch} from "store/storeHooks";
import {LoginFacebook} from "./LoginFacebook";
import {LoginGoogle} from "./LoginGoogle";

const SignIn = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [backendErrors, setBackendErrors] = useState<Record<SignInFormFields, string[]>>({} as Record<SignInFormFields, string[]>);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const signInFormMethods = useSignInFormController();

  const onSubmit = (values: SignInFormValues) => {
    setIsLoading(true);

    httpService
      .post(`api/auth/v1/register/`, {
        email: values.userEmail,
        password1: values.password,
        password2: values.passwordConfirmation,
      })
      .then(() => {
        renderSuccessToast(t("auth.accountCreatedWillLogIn"));
        dispatch(
          logIn({
            email: values.userEmail,
            password: values.password,
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        handleBackendError(err.response.data);
        renderErrorToast(t("errorOccurred"));
      });
  };

  const handleBackendError = (error: any) => {
    if ("email" in error) {
      setBackendErrors({...backendErrors, userEmail: error.email});
    }
  }
  const onLogIn = () => {
    navigate(`${AUTH_PATH}${LOGIN_PATH}`, {replace: true});
  };


  return (
    <AuthContentWrapper>
      <AuthTitle>{t("auth.createAccount")}</AuthTitle>
      <AuthSubtitle>{t("auth.createAccountDescription")}</AuthSubtitle>
      <FormProvider {...signInFormMethods}>
        <Form onSubmit={signInFormMethods.handleSubmit(onSubmit)} noValidate>
          <SignInForm isLoading={isLoading} backendErrors={backendErrors}/>
        </Form>
      </FormProvider>
      <HorizontalLineWrapper>
        <span>{t("or")}</span>
      </HorizontalLineWrapper>
      <SocialMediaAuthButtonsWrapper>
        <LoginFacebook></LoginFacebook>
        <LoginGoogle></LoginGoogle>
      </SocialMediaAuthButtonsWrapper>
      <RegistrationWrapper>
        <HaveAccount>{t("auth.haveAccountQuestion")}</HaveAccount>
        <GoToLogIn onClick={onLogIn}>{t("auth.logIn")}</GoToLogIn>
      </RegistrationWrapper>
    </AuthContentWrapper>
  );
};

export default SignIn;

const AuthContentWrapper = styled.div`
  margin: 0 24px;
  color: ${({theme}) => theme.colors.darkOrLight};
`;

const AuthTitle = styled.div`
  margin: 32px 0 28px;
  font-family: "Jost", serif;
  font-weight: 500;
  font-size: 34px;
  color: ${({theme}) => theme.colors.darkOrLight};
  line-height: 1.2;
`;

const AuthSubtitle = styled.div`
  margin-bottom: 28px;
  font-family: "Jost", serif;
  font-weight: 400;
  font-size: 20px;
  color: ${({theme}) => theme.colors.darkOrLight};
  line-height: 1.1;
`;

const HorizontalLineWrapper = styled.div`
  display: block;
  margin-top: 25px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  & > span {
    position: relative;
    display: inline-block;
  }

  & > span:before,
  & > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: ${({theme}) => theme.colors.grayOrLightGray};
  }

  & > span:before {
    right: 100%;
    margin-right: 15px;
  }

  & > span:after {
    left: 100%;
    margin-left: 15px;
  }
`;

const SocialMediaAuthButtonsWrapper = styled.div`
  margin: 25px 0;
`;


const RegistrationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const HaveAccount = styled.div`
  font-size: 16px;
`;

const GoToLogIn = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({theme}) => theme.colors.actionBlue};
  transition: 0.5s;

  &:hover {
    color: black;
  }
`;
