import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { OrderFormData } from "types/Order";
import { PAYMENT_METHODS_API_NAMES_KEYS } from "utils/consts";
import { subformContentStyle } from "global-styles/commonOrderStyles";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as MoneyIcon } from "assets/money-icon.svg";
import { ReactComponent as Przelewy24Icon } from "assets/przelewy24-icon.svg";

const PaymentRadioButtonsRHF = () => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();

  return (
    <>
      <Controller
        control={orderPlateFormMethods.control}
        name="payment.method"
        render={({ field: { onChange, value } }) => (
          <PaymentMethods isDarkTheme={isDarkTheme}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onChange(PAYMENT_METHODS_API_NAMES_KEYS.PRZELEWY_24);
              }}
              blueBorder={value === PAYMENT_METHODS_API_NAMES_KEYS.PRZELEWY_24}
            >
              <PaymentMethod>
                <Przelewy24Icon />
                <span>{t("orderPlate.przelewy24")}</span>
              </PaymentMethod>
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onChange(PAYMENT_METHODS_API_NAMES_KEYS.PAYMENT_ON_DELIVERY);
              }}
              blueBorder={
                value === PAYMENT_METHODS_API_NAMES_KEYS.PAYMENT_ON_DELIVERY
              }
            >
              <PaymentMethod>
                <MoneyIcon />
                <span>{t("orderPlate.paymentOnDelivery")}</span>
              </PaymentMethod>
            </Button>
          </PaymentMethods>
        )}
      />
    </>
  );
};

export default PaymentRadioButtonsRHF;

const PaymentMethods = styled.div<{ isDarkTheme: boolean }>`
  ${subformContentStyle}

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Button = styled.button<{ blueBorder: boolean }>`
  all: unset;
  height: 60px;
  min-width: 250px;
  background-color: ${(p) => p.theme.colors.whiteOrDark};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(p) =>
      p.blueBorder
        ? p.theme.colors.inputFocusBlue
        : p.theme.colors.inputWrapperGray};
  border-radius: 4px;
`;

const PaymentMethod = styled.div`
  & span {
    margin-left: 20px;
  }
`;
