import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { colors } from "utils/outsideThemeColors";

import InputRHF from "components/inputs/InputRHF";
import InputPasswordRHF from "components/inputs/InputPasswordRHF";
import MainButton from "components/inputs/MainButton";

import { AUTH_PATH, FORGOTTEN_PASSWORD_PATH } from "utils/routes";
import { LoginFormValues } from "components/auth/forms/LoginFormController";
import { selectAuthIsLoading } from "store/auth/AuthSlice";
import { useAppSelector } from "store/storeHooks";

const LoginForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const authIsLoading = useAppSelector(selectAuthIsLoading);

  const {
    control,
    formState: { errors },
  } = useFormContext<LoginFormValues>();

  const passwordForgotten = () => {
    navigate(`${AUTH_PATH}${FORGOTTEN_PASSWORD_PATH}`, {
      replace: true,
    });
  };

  return (
    <>
      <InputRHF
        type="email"
        formFieldName="userEmail"
        control={control}
        label={t("auth.emailAddress")}
        placeholder={t("auth.enterEmailAddress")}
        error={errors.userEmail?.message}
        inputTabIndex={1}
      />
      <InputPasswordRHF
        formFieldName="password"
        control={control}
        label={t("auth.password")}
        placeholder={t("auth.enterPassword")}
        error={errors.password?.message}
        extraButton={{
          content: t("auth.dontRememberPassword"),
          onClickFunction: passwordForgotten,
          color: colors.actionBlue,
        }}
        inputTabIndex={2}
      />
      {!authIsLoading && (
        <StyledMainButton type="submit" expandWidth>
          {t("auth.logIn")}
        </StyledMainButton>
      )}
      {authIsLoading && (
        <LoaderWrapper>
          <SyncLoader color={colors.dark} size={10} />
        </LoaderWrapper>
      )}
    </>
  );
};

export default LoginForm;

const StyledMainButton = styled(MainButton)`
  margin-top: 30px !important;
`;

const LoaderWrapper = styled.div`
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
