export const LOCAL_STORAGE_AUTH_TOKEN = "auth_token";

export const BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS = {
  NAME: "imię",
  SURNAME: "nazwisko",
  DATE_OF_BIRTH: "data urodzenia",
  PLACE_OF_BIRTH: "miejsce urodzenia",
  DATE_OF_DEATH: "data śmierci",
  PLACE_OF_DEATH: "miejsce śmierci",
  MAIDEN_NAME: "z domu",
};

export const PROFILE_BASIC_DATA_FIELD_CONTENT_TYPES = {
  STRING: "STRING",
  DATE: "DATE",
} as const;

export const SETTINGS_TABLE_ROW_DATA_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  PASSWORD: "password",
} as const;

export const USER_DATA_NAMES = {
  EMAIL: "Adres email",
  FIRST_NAME: "Imię",
  LAST_NAME: "Nazwisko",
  PASSWORD: "Hasło",
} as const;

export const USER_REGULAR_DATA_API_NAMES = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
} as const;

export const DELIVERY_METHODS_API_NAMES = {
  IN_POST: "IN_POST",
  POST: "POST",
} as const;

export const PAYMENT_METHODS_API_NAMES_KEYS = {
  PRZELEWY_24: "PRZELEWY_24",
  PAYMENT_ON_DELIVERY: "PAYMENT_ON_DELIVERY",
} as const;

export const PLATES_COLORS_API_NAMES = {
  GRAY: "GRAY",
  WHITE: "WHITE",
  TRANSPARENT: "TRANSPARENT"
} as const;

export const SAVE_PROFILE_OPTIONS = {
  ON_QUIT_FORM: "ON_QUIT_FORM",
  ON_NAVIGATE_TO_ORDER: "ON_NAVIGATE_TO_ORDER",
} as const;

export const ADMINS_DATA = {
  COMPANY_NAME: "Memero Sp. z o.o.",
  NIP: "NIP: 7312077128",
  REGON: "REGON: 522411704",
  KRS: "KRS: 0000979409",
  EMAIL_ADDRESS: "kontakt@memero.pl",
};
