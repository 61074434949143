import { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";

import "./styles/UploadProfilePhotoModal.css";

import ProfilePhotoCropper from "components/profile/upload-profile-photo/ProfilePhotoCropper";

Modal.setAppElement(document.getElementById("root")!);

interface Props {
  isUploadProfilePhotoModalOpen: boolean;
  setIsUploadProfilePhotoModalOpen: Dispatch<SetStateAction<boolean>>;
}

const UploadProfilePhotoModal = ({
  isUploadProfilePhotoModalOpen,
  setIsUploadProfilePhotoModalOpen,
}: Props) => {
  const closeModal = () => {
    setIsUploadProfilePhotoModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isUploadProfilePhotoModalOpen}
        overlayClassName={{
          base: "overlay-base-photo-upload",
          afterOpen: "overlay-after-photo-upload",
          beforeClose: "overlay-before-photo-upload",
        }}
        className={{
          base: "content-base-photo-upload",
          afterOpen: "content-after-photo-upload",
          beforeClose: "content-before-photo-upload",
        }}
        closeTimeoutMS={500}
      >
        <ProfilePhotoCropper closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default UploadProfilePhotoModal;
