import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SummaryDescription,
  SectionMainLine,
  SummaryPrice,
} from "global-styles/commonOrderStyles";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import QrCodeModal from "components/shared-modals/QrCodeModal";

import { PlacedOrderFullData } from "types/Order";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useWindowDimensions } from "hooks/useWindowDimensions";

import { ReactComponent as QrCodeIcon } from "assets/qr-code-icon.svg";

interface Props {
  orderFullData: PlacedOrderFullData | undefined;
}

const PlacedOrderItems = ({ orderFullData }: Props) => {
  const [displayedQrCodeProfileId, setDisplayedQrCodeProfileId] =
    useState<string>();
  const [displayedQrCodeColor, setDisplayedQrCodeColor] = useState<string>();
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState<boolean>(false);

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const { height } = useWindowDimensions();

  const openQrCodeModal = (personId: string, plateColor: string) => {
    setDisplayedQrCodeProfileId(personId);
    setDisplayedQrCodeColor(plateColor);
    setIsQrCodeModalOpen(true);
  };

  const closeQrCodeModal = () => {
    setIsQrCodeModalOpen(false);
    setDisplayedQrCodeProfileId(undefined);
    setDisplayedQrCodeColor(undefined);
  };
  const colorTranslationTable: Record<string ,string> = {
    GRAY: t("orderPlate.grayColor"),
    WHITE: t("orderPlate.whiteColor"),
    TRANSPARENT: t("orderPlate.transparentColor")
  }

  return (
    <Section>
      <Header>
        <SummaryDescription>{`${t("orderPlate.order")} ${t("orders.number")} ${
          orderFullData?.details.orderNumber
        }`}</SummaryDescription>
        <SummaryPrice>{`${orderFullData?.details.itemsTotal.price} ${orderFullData?.details.itemsTotal.currency}`}</SummaryPrice>
      </Header>
      <OrderItemsWrapper maxHeight={0.25 * height} isDarkTheme={isDarkTheme}>
        {orderFullData?.details.items.map((item, index) => (
          <OrderItem key={index}>
            <SectionMainLine>
              {`${index + 1}. ${item.personName}`}
            </SectionMainLine>
            <PlatesQuantityWrapper>
              {item.plates.map((plate, index) => (
                <SinglePlateColorSummary key={index}>
                  <LeftColumnWrapper>
                    <div>{`${plate.count} x ${
                      colorTranslationTable[plate.color]
                    }`}</div>
                    <div>
                      <ShowQrCodeButton
                        onClick={() =>
                          openQrCodeModal(item.personId, plate.color)
                        }
                      >
                        <StyledQrCodeIcon />
                      </ShowQrCodeButton>
                    </div>
                  </LeftColumnWrapper>
                  <div>{`${plate.count} x ${plate.price} ${plate.currency}`}</div>
                </SinglePlateColorSummary>
              ))}
            </PlatesQuantityWrapper>
          </OrderItem>
        ))}
      </OrderItemsWrapper>
      <QrCodeModal
        isQrCodeModalOpen={isQrCodeModalOpen}
        closeModal={closeQrCodeModal}
        profileId={displayedQrCodeProfileId}
        plateColor={displayedQrCodeColor}
      />
    </Section>
  );
};

export default PlacedOrderItems;

interface OrderItemsWrapperProps {
  maxHeight: number;
  isDarkTheme: boolean;
}

const OrderItemsWrapper = styled.div<OrderItemsWrapperProps>`
  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  max-height: ${(p) => p.maxHeight}px;
  padding-right: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const OrderItem = styled.div`
  font-size: 16px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const PlatesQuantityWrapper = styled.div`
  padding-left: 20px;
`;

const SinglePlateColorSummary = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0 4px;
`;

const LeftColumnWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const ShowQrCodeButton = styled.button`
  all: unset;

  &:hover {
    opacity: 0.6;
  }
`;

const StyledQrCodeIcon = styled(QrCodeIcon)`
  width: 13px;
  height: 13px;
`;
