import styled from "styled-components";
import {FieldArrayWithId, useFormContext} from "react-hook-form";

import DatePickerRHF from "components/inputs/DatePickerRHF";
import InputRHF from "components/inputs/InputRHF";

import {AddBasicDataFormValues} from "components/profile/forms/AddBasicDataFormController";
import {PROFILE_BASIC_DATA_FIELD_CONTENT_TYPES,} from "utils/consts";
import {renderLabel, renderTextInputPlaceholder,} from "components/profile/utils/useBasicDataUtils";
import {useProfileDataContext} from "global-contexts/ProfileDataContext";
import {useQuestionModalContext} from "global-contexts/QuestionModalContext";
import {useTranslation} from "react-i18next";

import {ReactComponent as XIcon} from "assets/x-icon.svg";

interface Props {
  fields: FieldArrayWithId<AddBasicDataFormValues, "personFields", "id">[];
  handleRemoveField: (fieldIndex: number) => void;
}

const AddBasicDataFormFields = ({ fields, handleRemoveField }: Props) => {
  const { profileGeneralData } = useProfileDataContext();

  const { openQuestionModal } = useQuestionModalContext();

  const { t } = useTranslation();

  const { control } = useFormContext<AddBasicDataFormValues>();

  return (
    <>
      {profileGeneralData &&
        fields.map((field, index) => (
          <div key={field.id}>
            {profileGeneralData.fields[index].fieldType ===
              PROFILE_BASIC_DATA_FIELD_CONTENT_TYPES.STRING && (
              <InputRHF
                formFieldName={
                  `personFields.${index}.content` as "personFields.0.content"
                }
                control={control}
                label={renderLabel(profileGeneralData, index)}
                placeholder={renderTextInputPlaceholder(
                  profileGeneralData,
                  index
                )}
                extraButton={
                  profileGeneralData.fields[index].deletable
                    ? {
                        content: (
                          <>
                            {t("addProfile.deleteField")}
                            <StyledXIcon />
                          </>
                        ),
                        onClickFunction: () =>
                          openQuestionModal({
                            text: t("addProfile.wantToDeleteFieldQuestion"),
                            rightButtonAction: () => handleRemoveField(index),
                          }),
                      }
                    : undefined
                }
                noMarginTop={index === 0}
                inputTabIndex={index + 1}
              />
            )}
            {profileGeneralData.fields[index].fieldType ===
              PROFILE_BASIC_DATA_FIELD_CONTENT_TYPES.DATE && (
              <DatePickerRHF
                control={control}
                formFieldName={
                  `personFields.${index}.content` as "personFields.0.content"
                }
                label={renderLabel(profileGeneralData, index)}
                defaultValue={profileGeneralData.fields[index].content}
                extraButton={
                  profileGeneralData.fields[index].deletable ?{
                  content: (
                    <>
                      {t("addProfile.deleteField")}
                      <StyledXIcon />
                    </>
                  ),
                  onClickFunction: () => handleRemoveField(index),
                }: undefined}
                inputTabIndex={index + 1}
              />
            )}
          </div>
        ))}
    </>
  );
};

export default AddBasicDataFormFields;

const StyledXIcon = styled(XIcon)`
  vertical-align: -6px;
  margin-left: 2px;
`;
