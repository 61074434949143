import { RefObject } from "react";
import moment from "moment";

export const formatDateWithDay = (selectedDate: Date) => {
  return moment(selectedDate).format("DD/MM/yyyy");
};
export const formatDateWithoutDay = (selectedDate: Date) => {
  return moment(selectedDate).format("MM/yyyy");
};

export const scrollToBottomOfDivElement = (
  element: RefObject<HTMLDivElement>
) => {
  if (element.current) {
    element.current.scroll({
      top: element.current.scrollHeight,
      behavior: "smooth",
    });
  }
};

export const scrollToTopOfDivElement = (element: RefObject<HTMLDivElement>) => {
  if (element.current) {
    element.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  }
};

export const scrollElementIntoView = (element: RefObject<HTMLDivElement>) => {
  if (element.current) {
    element.current.scrollIntoView({ behavior: "smooth" });
  }
};
