import FacebookLogin from "@greatsumini/react-facebook-login";
import {loginOauth} from "../../store/auth/AuthSlice";
import {logError} from "../../utils/errorLogger";
import styled from "styled-components";
import {ButtonText, StyledSocialMediaAuthButton} from "./SocialComponents";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../store/storeHooks";
import {ReactComponent as FacebookIcon} from "assets/facebook-icon.svg";

export const LoginFacebook = () => {
  const FACEBOOK_APP_ID: string | undefined = process.env.REACT_APP_FACEBOOK_APP_ID;
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div>
      {FACEBOOK_APP_ID && (
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          scope={"public_profile,email"}
          onSuccess={(response) => {
            dispatch(loginOauth({provider: "facebook", access_token: response.accessToken}));
          }}
          onFail={(error) => {
            logError("Facebook Login Failed!", error);
          }}
          render={({onClick}) => (
            <StyledSocialMediaAuthButton
              variant="outline-dark"
              onClick={onClick}
            >
              <StyledFacebookIcon/>
              <ButtonText>{t("auth.loginViaFb")}</ButtonText>
            </StyledSocialMediaAuthButton>
          )}
        />
      )}
    </div>
  )
}


const StyledFacebookIcon = styled(FacebookIcon)`
  width: 28px;
  height: 28px;
`;
