import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  LinesWrapper,
  Lines,
  Diamond,
} from "global-styles/horizontalLineWithDiamond";

import { ADMINS_DATA } from "utils/consts";
import { AUTH_PATH, LOGIN_PATH } from "utils/routes";

import { ReactComponent as LetterIcon } from "assets/letter-icon.svg";
import { ReactComponent as PhoneIcon } from "assets/phone-icon.svg";

const PublicProfileBottomSection = () => {
  const { t } = useTranslation();

  return (
    <BottomSection>
      <LinesWrapper>
        <Lines>
          <Diamond />
        </Lines>
      </LinesWrapper>
      <StyledLink to={`${AUTH_PATH}${LOGIN_PATH}`}>
        <AppTitle modifiedMargin>{t("memero")}</AppTitle>
      </StyledLink>
      <AppInfoLine>{t("publicProfile.description")}</AppInfoLine>
      <AppInfoLine>
        <span>{t("publicProfile.learnMoreAt")}</span>
        <UnderlinedLink to={`${AUTH_PATH}${LOGIN_PATH}`}>
          {t("publicProfile.memeroPl")}
        </UnderlinedLink>
      </AppInfoLine>
      <Contact>
        <ContactLine>{t("publicProfile.contact")}</ContactLine>
        <ContactLine>
          <LinkTo href={`mailto:${ADMINS_DATA.EMAIL_ADDRESS}`}>
            <LinkToContent>
              <StyledLetterIcon /> {ADMINS_DATA.EMAIL_ADDRESS}
            </LinkToContent>
          </LinkTo>
        </ContactLine>
        {/* <ContactLine>
          <LinkTo href="tel:500600400">
            <LinkToContent>
              <StyledPhoneIcon />
              500 600 400
            </LinkToContent>
          </LinkTo>
        </ContactLine> */}
      </Contact>
      <ContactLine>
        &copy; {t("publicProfile.allRightsReserved2022")}
      </ContactLine>
    </BottomSection>
  );
};

export default PublicProfileBottomSection;

const BottomSection = styled.div`
  padding: 0 30px;
  margin: 0 0 30px;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: 0.5s;

  &:hover {
    color: inherit;
    opacity: 0.6;
  }
`;

const AppTitle = styled.div<{ modifiedMargin?: boolean }>`
  font-family: "Jost";
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
  margin: ${(p) => (p.modifiedMargin ? "10px 0 10px" : "20px 30px 0")};
`;

const AppInfoLine = styled.div`
  margin-top: 10px;
  font-size: 17px;
  word-break: break-word;

  & span {
    margin-right: 6px;
  }
`;

const UnderlinedLink = styled(Link)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkOrLight};
  transition: 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.darkOrLight};
    opacity: 0.6;
  }
`;

const Contact = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
`;

const ContactLine = styled.div`
  font-size: 15px;

  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;

const LinkTo = styled.a`
  text-decoration: none;
  display: inline-block;

  color: ${({ theme }) => theme.colors.darkOrLight};
  transition: 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.darkOrLight};
    opacity: 0.6;
  }
`;

const LinkToContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLetterIcon = styled(LetterIcon)`
  width: 18px;
  height: 14px;
  margin-top: 1px;
  margin-right: 5px;
`;
