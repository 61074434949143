import styled from "styled-components";

import DateAndPlaceOfBirthField from "components/profile-preview-commons/DateAndPlaceOfBirthField";
import DateAndPlaceOfDeathField from "components/profile-preview-commons/DateAndPlaceOfDeathField";

import { BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS } from "utils/consts";
import { ProfileGeneralData } from "types/Profile";

interface Props {
  profileGeneralData: ProfileGeneralData;
}

const BasicDataField = ({ profileGeneralData }: Props) => {
  return (
    <BasicData>
      {profileGeneralData &&
        profileGeneralData.fields
          .filter(
            (field) =>
              field.fieldName.toLowerCase() ===
              BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.MAIDEN_NAME
          )
          .map((field, index) => (
            <div key={index}>
              {field.content && (
                <>
                  <FieldName>{field.fieldName}</FieldName>
                  <Data>{field.content}</Data>
                </>
              )}
            </div>
          ))}
      <DateAndPlaceOfBirthField fields={profileGeneralData.fields} />
      <DateAndPlaceOfDeathField fields={profileGeneralData.fields} />
      {profileGeneralData &&
        profileGeneralData.fields
          .filter(
            (field) =>
              !Object.values(BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS).includes(
                field.fieldName.toLowerCase()
              )
          )
          .map((field, index) => (
            <div key={index}>
              {field.content && (
                <>
                  <FieldName>{field.fieldName}</FieldName>
                  <Data>{field.content}</Data>
                </>
              )}
            </div>
          ))}
    </BasicData>
  );
};

export default BasicDataField;

const BasicData = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 30px;
`;

const FieldName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.grayOrLightGray};
`;

const Data = styled.div`
  font-size: 19px;
  line-height: 150%;
`;
