import { createContext, ReactNode, useContext, useState } from "react";

import {
  PersonDescriptionSection,
  ProfileGeneralData,
  StepsRange,
} from "types/Profile";

interface AllFormsIsDirtyStatuses {
  basicData: boolean;
  sections: boolean;
  subsections: boolean;
}

interface Props {
  children: ReactNode;
}

export const ProfileDataContext = createContext<{
  profileGeneralData?: ProfileGeneralData;
  setProfileGeneralData: (profileGeneralData?: ProfileGeneralData) => void;
  personDescription: PersonDescriptionSection[];
  setPersonDescription: (personDescription: PersonDescriptionSection[]) => void;
  activeStepIndex: StepsRange;
  setActiveStepIndex: (activeStepIndex: StepsRange) => void;
  allFormsIsDirtyStatuses: AllFormsIsDirtyStatuses;
  setAllFormsIsDirtyStatuses: (
    allFormsIsDirtyStatuses: AllFormsIsDirtyStatuses
  ) => void;
  resetForm: () => void;
  focusedSectionIndex?: number;
  setFocusedSectionIndex: (focusedSectionIndex: number) => void;
}>({
  profileGeneralData: undefined,
  setProfileGeneralData: () => {},
  personDescription: [],
  setPersonDescription: () => {},
  activeStepIndex: 1,
  setActiveStepIndex: () => {},
  allFormsIsDirtyStatuses: {
    basicData: false,
    sections: false,
    subsections: false,
  },
  setAllFormsIsDirtyStatuses: () => {},
  resetForm: () => {},
  focusedSectionIndex: undefined,
  setFocusedSectionIndex: () => {},
});

export const ProfileDataContextProvider = ({ children }: Props) => {
  const [profileGeneralData, setProfileGeneralData] =
    useState<ProfileGeneralData>();
  const [allFormsIsDirtyStatuses, setAllFormsIsDirtyStatuses] =
    useState<AllFormsIsDirtyStatuses>({
      basicData: false,
      sections: false,
      subsections: false,
    });
  const [personDescription, setPersonDescription] = useState<
    PersonDescriptionSection[]
  >([]);
  const [activeStepIndex, setActiveStepIndex] = useState<StepsRange>(1);
  const [focusedSectionIndex, setFocusedSectionIndex] = useState<number>();

  const resetForm = () => {
    setActiveStepIndex(1);
    setProfileGeneralData(undefined);
    setAllFormsIsDirtyStatuses({
      basicData: false,
      sections: false,
      subsections: false,
    });
    setPersonDescription([]);
    setFocusedSectionIndex(undefined);
  };

  return (
    <ProfileDataContext.Provider
      value={{
        profileGeneralData,
        setProfileGeneralData: (profileGeneralData?: ProfileGeneralData) => {
          setProfileGeneralData(profileGeneralData);
        },
        personDescription,
        setPersonDescription: (
          personDescription: PersonDescriptionSection[]
        ) => {
          setPersonDescription(personDescription);
        },
        activeStepIndex,
        setActiveStepIndex: (activeStepIndex: StepsRange) => {
          setActiveStepIndex(activeStepIndex);
        },
        allFormsIsDirtyStatuses,
        setAllFormsIsDirtyStatuses: (
          allFormsIsDirtyStatuses: AllFormsIsDirtyStatuses
        ) => {
          setAllFormsIsDirtyStatuses(allFormsIsDirtyStatuses);
        },
        resetForm,
        focusedSectionIndex,
        setFocusedSectionIndex: (focusedSectionIndex: number) => {
          setFocusedSectionIndex(focusedSectionIndex);
        },
      }}
    >
      {children}
    </ProfileDataContext.Provider>
  );
};

export const useProfileDataContext = () => {
  const context = useContext(ProfileDataContext);

  if (context === undefined) {
    throw new Error(
      "useProfileDataContext must be used within the ProfileDataContextProvider"
    );
  }

  return context;
};
