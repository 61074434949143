import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { colors } from "utils/outsideThemeColors";

import CheckboxRHF from "components/inputs/CheckboxRHF";
import InputRHF from "components/inputs/InputRHF";
import InputPasswordRHF from "components/inputs/InputPasswordRHF";
import MainButton from "components/inputs/MainButton";

import { SignInFormValues } from "components/auth/forms/SignInFormController";
import {useState} from "react";

export type SignInFormFields = "userEmail" | "password" | "passwordConfirmation" | "rules";

interface Props {
  isLoading: boolean;
  backendErrors: Record<SignInFormFields, string[]>;
}

const SignInForm = ({ isLoading, backendErrors }: Props) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<SignInFormValues>();

  const getErrorsForField = (fieldName: SignInFormFields, frontendError?: string): string | undefined => {
    const backendError = backendErrors[fieldName];
    if(frontendError) { return frontendError; }
    else if(backendError ) { return backendError.join(", "); }
    else { return undefined; }
  }
  return (
    <>
      <InputRHF
        type="email"
        formFieldName="userEmail"
        control={control}
        label={t("auth.emailAddress")}
        placeholder={t("auth.enterEmailAddress")}
        error={getErrorsForField("userEmail", errors.userEmail?.message)}
        inputTabIndex={1}
        noMarginTop
      />
      <InputPasswordRHF
        formFieldName="password"
        control={control}
        label={t("auth.password")}
        sublabel={t("auth.passwordRequirements")}
        placeholder={t("auth.enterPassword")}
        error={getErrorsForField("password", errors.password?.message)}
        inputTabIndex={2}
      />
      <InputPasswordRHF
        formFieldName="passwordConfirmation"
        control={control}
        label={t("auth.repeatPassword")}
        placeholder={t("auth.repeatPassword")}
        error={errors.passwordConfirmation?.message}
        inputTabIndex={3}
      />
      <StyledCheckboxRHF
        formFieldName="rules"
        control={control}
        label={t("auth.rulesCheckboxLabel")}
        error={errors.rules?.message}
        inputTabIndex={4}
      />
      {!isLoading && (
        <StyledMainButton type="submit" expandWidth>
          {t("auth.create")}
        </StyledMainButton>
      )}
      {isLoading && (
        <LoaderWrapper>
          <SyncLoader color={colors.dark} size={10} />
        </LoaderWrapper>
      )}
    </>
  );
};

export default SignInForm;

const StyledMainButton = styled(MainButton)`
  margin-top: 21px;
`;

const LoaderWrapper = styled.div`
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCheckboxRHF = styled(CheckboxRHF)`
  margin-top: 15px;
`;
