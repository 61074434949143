export interface DefaultEditorObjectType {
  blocks: {
    key: string;
    text: string;
    type: string;
    depth: number;
    inlineStyleRanges: never[];
    entityRanges: never[];
    data: {};
  }[];
  entityMap: {};
}

export const defaultEditorParagraphObject = {
  key: "",
  text: "",
  type: "unstyled",
  depth: 0,
  inlineStyleRanges: [],
  entityRanges: [],
  data: {},
};
