import axios from "axios";
import {store} from "store/store";
import {renderErrorToast} from "utils/toasts";
import {t} from "i18next";

import {authSlice} from "store/auth/AuthSlice";
import {USER_PATH} from "utils/routes";
import {LOCAL_STORAGE_AUTH_TOKEN} from "utils/consts";

const httpService = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

httpService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
      localStorage.removeItem("persist:root");
      renderErrorToast(t("sessionHasEnded"));
      authSlice.actions.reset();
      window.location.pathname = `${USER_PATH}`;
    }
    throw error;
  }
);

httpService.interceptors.request.use((config) => {
  if (!config) {
    config = {};
  }

  if (!config.headers) {
    config.headers = {};
  }

  if (!config.headers.Authorization) {
    config.headers.Authorization = store.getState().auth.token ?? "";
  }

  return config;
});

export default httpService;
