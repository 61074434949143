import styled from "styled-components";
import {Outlet, useNavigate} from "react-router-dom";
import Footer from "../components/footer/Footer";
import {AUTH_PATH, LOGIN_PATH, STATIC_PAGE_PATH} from "../utils/routes";
import { ReactComponent as XIcon } from "assets/x-icon.svg";
import {smallButtonStyle, smallOutlineButtonStyle} from "../global-styles/sharedStyles";

const StaticPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Background>
        <ContentWrapper>
          <CloseButton
            onClick={() =>
              navigate(`${AUTH_PATH}${LOGIN_PATH}`, { replace: true })
            }
          >
            <XIcon />
          </CloseButton>
          <Outlet/>
        </ContentWrapper>
        <Footer basePath={STATIC_PAGE_PATH}/>
      </Background>
    </>
  )
}

export default StaticPage;

const Background = styled.div`
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 720px) {
    display: grid;
  }
`;




const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  height: 90vh;
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({theme}) => theme.colors.lightOrDark};
  @media (max-width: 720px) {
    display: grid;
  }
`;

const CloseButton = styled.button`
  ${smallButtonStyle}

  padding: 3px 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 85vw;
`;
