import { useId } from "react";
import styled from "styled-components";
import { Control, Controller } from "react-hook-form";

import { lightTheme } from "global-styles/colorThemes";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as MinusIcon } from "assets/minus-icon.svg";
import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";

interface Props {
  control: Control<any>;
  formFieldName: string;
  error?: string;
  inputTabIndex?: number;
}

const QuantityPickerRHF = ({ control, formFieldName, error }: Props) => {
  const { isDarkTheme } = useColorThemeContext();

  const id = useId();

  return (
    <Controller
      control={control}
      name={formFieldName}
      render={({ field: { onChange, value } }) => (
        <QuantityPicker isDarkTheme={isDarkTheme}>
          <button
            type="button"
            onClick={() => {
              if (Number(value) > 0) onChange(Number(value) - 1);
            }}
          >
            <MinusIcon />
          </button>
          <input id={id} type="number" value={Number(value)} readOnly />
          <button
            type="button"
            onClick={() => {
              if (Number(value) < 99) onChange(Number(value) + 1);
            }}
          >
            <PlusIcon />
          </button>
          {error && <Error>*{error}</Error>}
        </QuantityPicker>
      )}
    />
  );
};

export default QuantityPickerRHF;

const QuantityPicker = styled.div<{ isDarkTheme: boolean }>`
  position: relative;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.inputWrapperGray};
  flex-shrink: 0;
  width: 75px;
  border-radius: 4px;

  input {
    height: 29px;
    width: 25px;
    font-size: 14px;
    font-weight: 400;
    font-family: inherit;
    text-align: center;
    background-color: white;
    color: ${lightTheme.colors.darkOrLight};
    border: none;
    outline: none;
    border-radius: 0;
  }

  // Chrome, Safari, Edge, Opera
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  button {
    position: relative;
    height: 29px;
    width: 25px;
    text-align: center;
    font-size: 12px;
    font-family: "Roboto Condensed", sans-serif;
    background-color: white;
    color: ${lightTheme.colors.grayOrLightGray};
    transition: 0.3s;

    border: none;

    &:hover {
      opacity: 1;
      background-color: ${(p) =>
        p.isDarkTheme
          ? p.theme.colors.darkOrLight
          : p.theme.colors.whiteOpacityOrDarkOpacity};
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -18px;
  left: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.validationErrorRed};
`;
