import { useEffect, useState } from "react";
import styled from "styled-components";
import { FormProvider, useWatch } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Title } from "global-styles/commonOrderStyles";

import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";
import OrderPlateForm from "components/order-plate/order-data-forms/OrderPlateForm";
import OrderSummary from "components/order-plate/order-summary/OrderSummary";

import { EDIT_PROFILE_PATH, NEW_PROFILE_PATH, USER_PATH } from "utils/routes";
import { OrderCheckoutSummary, OrderPricing } from "types/Order";
import { renderErrorToast } from "utils/toasts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useOrderPlateFormController } from "components/order-plate/order-data-forms/OrderPlateFormController";

import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";

const OrderPlateContent = () => {
  const [anyProfilesExist, setAnyProfilesExist] = useState<boolean | undefined>(
    undefined
  );
  const [initialOrderDataFetchDone, setInitialOrderDataFetchDone] =
    useState<boolean>(false);
  const [orderCheckoutSummary, setOrderCheckoutSummary] =
    useState<OrderCheckoutSummary>();
  const [orderPricing, setOrderPricing] = useState<OrderPricing>();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const orderPlateFormMethods = useOrderPlateFormController();

  const shipmentRadioButtonsWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "shipment.method",
  });
  const profilesWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "profiles",
  });
  const inPostAddressWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "shipment.inPostAddress",
  });
  const paymentWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "payment.method",
  });

  useEffect(
    function checkIfAnyProfilesExist() {
      httpService
        .get(`api/passedaway/person/v1/`)
        .then((response) => {
          setAnyProfilesExist(response.data.length > 0);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    },
    [t]
  );

  useEffect(() => {
    if (anyProfilesExist) {
      httpService
        .get(`api/ecommerce/order/v1/current/`)
        .then((response) => {
          setInitialOrderDataFetchDone(true);
          const formData = (({ details, ...o }) => o)(response.data);
          orderPlateFormMethods.reset({ ...formData });
          setOrderCheckoutSummary(response.data.details);
          setOrderPricing(response.data.costs);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    }
  }, [orderPlateFormMethods, anyProfilesExist, t]);

  useEffect(
    function updateOrderSummary() {
      if (initialOrderDataFetchDone) {
        const requestBody = orderPlateFormMethods.getValues();

        httpService
          .put(`api/ecommerce/order/v1/current/`, requestBody)
          .then((response) => {
            setOrderCheckoutSummary(response.data.details);
          })
          .catch(() => {
            renderErrorToast(t("errorOccurred"));
          });
      }
    },
    [
      profilesWatcher,
      paymentWatcher,
      inPostAddressWatcher,
      shipmentRadioButtonsWatcher,
      orderPlateFormMethods,
      initialOrderDataFetchDone,
      t,
    ]
  );

  return (
    <>
      {anyProfilesExist && (
        <OrderPlateContainer>
          <FormProvider {...orderPlateFormMethods}>
            <OrderPlateForm orderPricing={orderPricing} />
            <OrderSummary
              orderCheckoutSummary={orderCheckoutSummary}
              setOrderCheckoutSummary={setOrderCheckoutSummary}
            />
          </FormProvider>
        </OrderPlateContainer>
      )}
      {!anyProfilesExist && (
        <OrderNotPossibleContainer isDarkTheme={isDarkTheme}>
          <Title>{t("orderPlate.submitOrder")}</Title>
          {anyProfilesExist !== undefined && (
            <NoProfilesWrapper>
              <NoProfilesYet>
                <Description>{t("orderPlate.cannotPlaceOrder")}</Description>
                <NavLink
                  to={`${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}`}
                >
                  <MainButton type="submit" expandWidth>
                    <StyledPlusIcon />
                    {t("dashboard.createProfile")}
                  </MainButton>
                </NavLink>
              </NoProfilesYet>
            </NoProfilesWrapper>
          )}
        </OrderNotPossibleContainer>
      )}
    </>
  );
};

export default OrderPlateContent;

const OrderPlateContainer = styled.div`
  flex-basis: 85.5%;
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  @media (max-width: 720px) {
    display: grid;
  }
  gap: 20px;
`;

const OrderNotPossibleContainer = styled.div<{ isDarkTheme: boolean }>`
  background: ${({ theme }) => theme.colors.lightOrDark};
  padding: 32px;
  border-radius: 12px;
  flex-basis: 85.5%;
  color: ${({ theme }) => theme.colors.darkOrLight};

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const NoProfilesWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 720px) {
    display: grid;
  }
`;

const NoProfilesYet = styled.div`
  height: 80px;
  width: 350px;
  margin: 70px 0 0;
  text-align: center;
  @media (max-width: 720px) {
    width: 95%;
  }
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 0;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin-bottom: 3px;
`;
