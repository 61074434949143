import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SummaryDescription,
  SectionMainLine,
  SummaryPrice,
} from "global-styles/commonOrderStyles";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import { OrderCheckoutSummary } from "types/Order";
import { PLATES_COLORS_API_NAMES } from "utils/consts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useWindowDimensions } from "hooks/useWindowDimensions";

interface Props {
  orderCheckoutSummary: OrderCheckoutSummary | undefined;
}

const OrderItems = ({ orderCheckoutSummary }: Props) => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const { height } = useWindowDimensions();

  const colorTranslationTable: Record<string ,string> = {
    GRAY: t("orderPlate.grayColor"),
    WHITE: t("orderPlate.whiteColor"),
    TRANSPARENT: t("orderPlate.transparentColor")
  }
  return (
    <Section>
      <Header>
        <SummaryDescription>{t("orderPlate.order")}</SummaryDescription>
        <SummaryPrice>{`${orderCheckoutSummary?.itemsTotal.price} ${orderCheckoutSummary?.itemsTotal.currency}`}</SummaryPrice>
      </Header>
      <OrderItemsWrapper maxHeight={0.25 * height} isDarkTheme={isDarkTheme}>
        {orderCheckoutSummary?.items.map((item, index) => (
          <OrderItem key={index}>
            <SectionMainLine>
              {`${index + 1}. ${item.personName}`}
            </SectionMainLine>
            <PlatesQuantityWrapper>
              {item.plates.map((plate, index) => (
                <SinglePlateSummary key={index}>
                  <div>{`${plate.count} x ${
                    colorTranslationTable[plate.color]
                  }`}</div>
                  <div>{`${plate.count} x ${plate.price} ${plate.currency}`}</div>
                </SinglePlateSummary>
              ))}
            </PlatesQuantityWrapper>
          </OrderItem>
        ))}
      </OrderItemsWrapper>
    </Section>
  );
};

export default OrderItems;

interface OrderItemsWrapperProps {
  maxHeight: number;
  isDarkTheme: boolean;
}

const OrderItemsWrapper = styled.div<OrderItemsWrapperProps>`
  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  max-height: ${(p) => p.maxHeight}px;
  padding-right: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const OrderItem = styled.div`
  font-size: 16px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const PlatesQuantityWrapper = styled.div`
  padding-left: 20px;
`;

const SinglePlateSummary = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0 4px;
`;
