import styled from "styled-components";

export const LinesWrapper = styled.div`
  width: 100%;
  margin-top: 17px;
  text-align: center;
`;

export const Lines = styled.div`
  width: 100%;
  height: 20px;
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 1px;
    background: ${({ theme }) => theme.colors.darkOrLight};
    width: 50%;
    top: 22%;
  }

  &::after {
    left: 0;
  }

  &::before: {
    right: 0;
  }
`;

export const Diamond = styled.div`
  height: 10px;
  width: 10px;
  border: 2px solid ${({ theme }) => theme.colors.darkOrLight};
  background-color: ${({ theme }) => theme.colors.darkOrLight};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
`;
