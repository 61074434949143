import styled from "styled-components";
import {useTranslation} from "react-i18next";

import {useColorThemeContext} from "global-contexts/ColorThemeContext";

const TermsAndConditionsContent = () => {
  const {isDarkTheme} = useColorThemeContext();

  const {t} = useTranslation();

  return (
    <Container isDarkTheme={isDarkTheme}>
      <Title>{t("termsAndConditions")}</Title>
      <Description>
        <p><strong>REGULAMIN PLATFORMY MEMERO</strong></p>
        <p>prowadzonej pod internetowym adresem www.memero.pl (dalej:
          ,,<strong>REGULAMIN</strong>”)</p>
        <p>Właścicielem i administratorem PLATFORMY MEMERO i SKLEPU
          INTERNETOWEGO jest: MEMERO SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, z
          siedzibą w Pabianicach, przy ul. Gawrońskiej 2/17, KRS; 0000979409, NIP:
          7312077128, kapitał zakładowy: 5.000,00 zł (pięć tysięcy złotych), Sąd
          Rejestrowy: Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi XX Wydział
          Gospodarczy Krajowego Rejestru Sądowego.</p>
        <p><br/>
          REGULAMIN określa zasady świadczenia usług sprzedaży oraz usług
          elektronicznych przez SPRZEDAWCĘ za pośrednictwem SKLEPU INTERNETOWEGO
          działającego na PLATFORMIE MEMERO pod domeną: www.memero.pl.</p>
        <p>Kontakt z ADMINISTRATOREM i SPRZEDAWCĄ jest możliwy za
          pośrednictwem:</p>
        <p>adresu e-mail: <a
          href="mailto:kontakt@memero.pl"><u>kontakt@memero.pl</u></a></p>
        <p>telefonu komórkowego: +48 695 382 852</p>
        <p><strong>§ 1</strong></p>
        <p><strong>DEFINICJE</strong></p>
        <p><strong>PLATFORMA MEMERO</strong> – oznacza platformę internetową,
          która umożliwia umieszczenie<br/>
          w sieci internet informacji odnośnie osoby zmarłej wraz z jej zdjęciem
          oraz zawarcia za pośrednictwem SKLEPU INTERNETOWEGO UMOWY SPRZEDAŻY oraz
          umowy o świadczenie USŁUGI ELEKTRONICZNEJ.</p>
        <p><strong>SKLEP INTERNETOWY</strong> - oznacza platformę internetową
          funkcjonującą w ramach PLATFORMY MEMERO, która umożliwia zawarcie UMOWY
          SPRZEDAŻY oraz świadczenia USŁUGI ELEKTRONICZNEJ i jeżeli stroną tej
          umowy jest KONSUMENT to odbywa się na warunkach opisanych w ustawie z
          dnia 30 moja 2014 r. o prawach konsumenta.</p>
        <p><strong>ADMINISTRATOR, SPRZEDAWCA i USŁUGODAWCA</strong> – MEMERO
          SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, z siedzibą w Pabianicach, KRS;
          0000979409, NIP: 7312077128, kapitał zakładowy: 5.000,00 zł (pięć
          tysięcy złotych), Sąd Rejestrowy: Sąd Rejonowy dla Łodzi-Śródmieścia w
          Łodzi XX Wydział Gospodarczy Krajowego Rejestru Sądowego.</p>
        <p><strong><br/>
        </strong></p>
        <p><strong>KLIENT</strong> – osoba fizyczna, a także osoba prawna oraz
          jednostka organizacyjna niebędąca osobą prawną, której przepisy
          szczególne przyznają zdolność prawną, a która dokonuje lub zamierza
          dokonać ZAMÓWIENIA lub korzysta z innych usług SKLEPU INTERNETOWEGO.</p>
        <p><strong>KONSUMENT</strong> - KLIENT, osoba fizyczna która nie
          prowadzi działalności gospodarczej lub osoba fizyczna prowadząca
          działalność gospodarczą, ale zawierająca UMOWĘ SPRZEDAŻY w celu
          niezwiązanym bezpośrednio z prowadzoną działalnością gospodarczą lub
          zawodową.</p>
        <p><strong>PRODUKT</strong> – dedykowana tabliczka o wymiarach 10 cm /
          11 cm z kodem QR, odsyłającym do PROFILU KLIENTA na stronie internetowej
          www.memero.pl, która jest przedmiotem UMOWY SPRZEDAŻY wraz z USŁUGĄ
          ELEKTRONICZNĄ, zgodnie z REGULAMINEM.</p>
        <p><strong>PROFIL KLIENTA</strong> – profil znajdujący się na PLATFORMIE
          MEMERO, zakładany, prowadzony i zmieniany przez KLIENTA na utworzonym
          KONCIE KLIENTA. Treści stanowiące ten profil mogą być odczytane przez
          nieograniczony krąg osób, którzy zeskanują za pomocą urządzeń mobilnych
          kod QR znajdujący się na PRODUKCIE.</p>
        <p><strong>REGULAMIN</strong> - niniejszy regulamin PLATFORMY MEMERO i
          funkcjonującego w jej ramach SKLEPU INTERNETOWEGO.</p>
        <p><strong>UMOWA SPRZEDAŻY</strong> - umowa sprzedaży PRODUKTU zawierana
          na odległość poprzez SKLEP INTERNETOWY.</p>
        <p><strong>STRONA UMOWY</strong> - SPRZEDAWCA, USŁUGODAWCA, KUPUJĄCY,
          KLIENT, KONSUMENT.</p>
        <p><strong>ZAMÓWIENIE</strong> - oświadczenie złożone elektronicznie o
          chęci zawarcia UMOWY SPRZEDAŻY.</p>
        <p><strong>KONTO KLIENTA</strong> – oznacza indywidualny dla każdego
          KLIENTA panel, uruchomiony na jego rzecz przez SPRZEDAWCĘ, po dokonaniu
          przez KLIENTA REJESTRACJI i zawarciu umowy świadczenia USŁUGI
          ELEKTRONICZNEJ prowadzenia konta klienta.</p>
        <p><strong>FORMULARZ REJESTRACJI</strong> – formularz umożliwiający
          rejestrację.</p>
        <p><strong>USŁUGA ELEKTRONICZNA –</strong> usługa świadczona drogą
          elektroniczną przez USŁUGODAWCĘ na rzecz KLIENTA za pośrednictwem SKLEPU
          INTERNETOWEGO.</p>
        <p><strong>LOGIN</strong> – oznacza indywidualne oznaczenie KLIENTA,
          przez niego ustalone, składające się z ciągu znaków literowych,
          cyfrowych lub innych, wymagane wraz z HASŁEM do założenia KONTA KLIENTA
          na PLATFORMIE MEMERO. LOGIN jest właściwym adresem poczty elektronicznej
          KLIENTA.</p>
        <p><strong>REJESTRACJA</strong> – oznacza czynność faktyczną dokonaną w
          sposób określony w REGULAMINEM, wymaganą dla korzystania przez KLIENTA
          ze wszystkich funkcjonalności PLATFORMY MEMERO</p>
        <p><strong>HASŁO</strong> – oznacza ciąg znaków literowych, cyfrowych
          lub innych wybranych przez KLIENTA podczas REJESTRACJI na PLATFORMIE
          MEMERO, wykorzystywanych w celu zabezpieczenia dostępu do KONTA
          KLIENTA.</p>
        <p><strong>§ 2</strong></p>
        <p><strong>POSTANOWIENIA OGÓLNE</strong></p>
        <p>Wszelkie prawa do PLATFORMY MEMERO i SKLEPU INTERNETOWEGO, w tym
          majątkowe prawa autorskie, prawa własności intelektualnej do ich nazwy,
          domeny internetowej, strony internetowej, a także do wzorców,
          formularzy, logotypów i zdjęć zamieszczanych na stronie internetowej
          PLATFORMY MEMERO należą do SPRZEDAWCY.</p>
        <p>RUGULAMIN jest nieprzerwanie dostępny w witrynie internetowej
          www.memero.pl, w sposób umożliwiający jego pozyskanie, odtwarzanie i
          utrwalanie jego treści poprzez wydrukowanie lub zapisanie na nośniku w
          każdej chwili.</p>
        <p>SPRZEDAWCA stosuje mechanizm plików „cookies”, które podczas
          korzystania przez KLIENTÓW z PLATFORMY MEMERO, zapisywane są przez
          serwer SPRZEDAWCY na dysku twardym urządzenia końcowego KLIENTA.
          Stosowanie plików „cookies” ma na celu poprawne działanie PLATFORMY
          MEMERO na urządzeniach końcowych KLIENTÓW. Mechanizm ten nie niszczy
          urządzenia końcowego KLIENTA oraz nie powoduje zmian konfiguracyjnych w
          urządzeniach końcowych KLIENTÓW ani w oprogramowaniu zainstalowanym na
          tych urządzeniach. Każdy KLIENT może wyłączyć mechanizm „cookies” w
          przeglądarce internetowej swojego urządzenia końcowego. SPZEDAWCA
          wskazuje, że wyłączenie „cookies” może jednak spowodować utrudnienia lub
          uniemożliwić korzystanie z PLATFORMY MEMERO.</p>
        <p>SPRZEDAWCA informuje, że korzystanie z usług świadczonych drogą
          elektroniczną może wiązać się z zagrożeniem po stronie każdego
          użytkownika sieci Internet, polegającym na możliwości wprowadzenia do
          systemu teleinformatycznego KLIENTA szkodliwego oprogramowania oraz
          pozyskania i modyfikacji jego danych przez osoby nieuprawnione. By
          uniknąć ryzyka wystąpienia zagrożeń KLIENT powinien stosować właściwe
          środki techniczne, które zminimalizują ich wystąpienie, a w
          szczególności programy antywirusowe i zaporę sieciową typu firewall.</p>
        <p><strong>§ 3</strong></p>
        <p><strong>PRZEDMIOT DZIAŁALNOŚCI SPRZEDAWCY</strong></p>
        <p>SPRZEDAWCA oferuje zawarcie UMOWY SPRZEDAŻY, której przedmiotem mogą
          być:</p>
        <p>PRODUKT</p>
        <p>USŁUGA ELEKTRONICZNA</p>
        <blockquote>
          <p>(PRODUKTY) za pośrednictwem SKLEPU INTERNETOWEGO.</p>
        </blockquote>
        <p>Wszystkie PRODUKTY dostępne na stronie SKLEPU INTERNETOWEGO są
          fabrycznie nowe, wolne od wad fizycznych i prawnych oraz zostały
          legalnie wprowadzone na rynek Polski,<br/>
          a także mogą posiadać gwarancję producenta, importera lub
          SPRZEDAWCY.</p>
        <p><strong>§ 4</strong></p>
        <p><strong>USŁUGI ELEKTRONICZNE SPRZEDAWCY</strong></p>
        <p>W celu umożliwienia zawarcia UMOWY SPRZEDAŻY, SPRZEDAWCA świadczy
          poprzez stronę www.memero.pl następujące USŁUGI ELEKTRONICZNE:</p>
        <p>utworzenie i administrowanie KONTA KLIENTA na PLATFORMIE MEMERO;</p>
        <p>przetwarzanie formularza ZAMÓWIENIA PRODUKTÓW w SKLEPIE
          INTERNETOWYM;</p>
        <p>przesyłanie informacji handlowych, ofert promocyjnych itp. na adres
          e-mail KLIENTA.</p>
        <p>utworzenie PROFILU KLIENTA na KONCIE KLIENTA zarejestrowanym<br/>
          i utworzonym na PLATFORMIE MEMERO; treści stanowiące ten profil mogą być
          odczytane przez nieograniczony krąg osób, którzy zeskanują za pomocą
          urządzeń mobilnych kod QR znajdujący się na PRODUKCIE.</p>
        <p>Świadczenie usług elektronicznych jest bezpłatne.</p>
        <p><strong>§ 5</strong></p>
        <p><strong>OKRES OBOWIĄZYWANIA I ROZWIĄZYWANIE UMÓW O ŚWIADCZENIE USŁUG
          ELEKTRONICZNYCH</strong></p>
        <p>Umowa o świadczenie USŁUGI ELEKTRONICZNEJ jest zawierana na czas
          nieoznaczony.</p>
        <p>Umowa o świadczenie usługi elektronicznej polegającej na umożliwieniu
          złożenia ZAMÓWIENIA w SKLEPIE INTERNETOWYM poprzez wypełnienie
          formularza ZAMÓWIENIA zawierana jest na czas oznaczony - na okres
          wypełniania i przetwarzania ZAMÓWIENIA - i ulega rozwiązaniu z chwilą
          złożenia i zaakceptowania ZAMÓWIENIA;</p>
        <p>W przypadku usługi zawartej na czas nieokreślony obu STRONOM UMOWY
          przysługuje prawo do wypowiedzenia umowy danej USŁUGI
          ELEKTRONICZNEJ:</p>
        <p>KONSUMENT może wypowiedzieć umowę o świadczenie USŁUGI ELEKTRONICZNEJ
          w każdej chwili składając odpowiednie oświadczenie w formie
          elektronicznej lub pisemnej na adres SPRZEDAWCY. SPRZEDAWCA w ciągu 7
          dni od otrzymania wypowiedzenia usunie z danych zapisy objęte ochroną
          danych osobowych związane z usługą i zaprzestanie jej świadczenia;</p>
        <p>USŁUGODAWCA może wypowiedzieć umowę o świadczenie USŁUGI
          ELEKTRONICZNEJ, jeżeli KONSUMENT obiektywnie i bezprawnie rażąco narusza
          REGULAMIN - z zachowaniem terminu wypowiedzenia 30 dni licząc od dnia
          złożenia wypowiedzenia. Jeżeli obiema STRONAMI UMOWY USŁUGI
          ELEKTRONICZNEJ są przedsiębiorcy USŁUGODAWCA może w takiej sytuacji
          wypowiedzieć umowę o świadczenie USŁUGI ELEKTRONICZNEJ ze skutkiem
          natychmiastowym;</p>
        <p>USŁUGODAWCA i KLIENT mogą rozwiązać umowę o świadczenie USŁUGI
          ELEKTRONICZNEJ w każdym czasie w drodze porozumienia stron.</p>
        <ol  start={ 4 } type="1">
          <li><p>Reklamacje związane ze świadczeniem USŁUGI ELEKTRONICZNEJ należy
            składać na adres USŁUGODAWCY - w formie wiadomości e-mail lub pisemnie
            na adres siedziby SPRZEDAWCY. Reklamacje zostaną rozpatrzone niezwłoczne
            nie później niż w ciągu 14 dni. Nierozpatrzenie reklamacji w tym okresie
            oznacza uznanie reklamacji.</p></li>
        </ol>
        <p><strong>§ 6</strong></p>
        <p><strong>REJESTRACJA</strong></p>
        <p>W celu REJESTRACJI, KLIENT powinien wypełnić FORMULARZ REJESTRACJI
          udostępniony przez SPRZEDAWCĘ na PLATFORMIE MEMERO i przesłać wypełniony
          drogą elektroniczną do SPRZEDAWCY poprzez wybór odpowiedniej funkcji
          znajdującej się w FORMULARZU REJESTRACJI. Podczas REJESTRACJI KLIENT
          ustala indywidualne HASŁO.</p>
        <p>KLIENT z wyłączeniem ADMINISTRATORA ponosi odpowiedzialność za
          ochronę własnego hasła i nigdy nie powinien podawać go osobom
          trzecim.</p>
        <p>Z chwilą REJESTRACJI zawarta zostaje umowa o świadczenie USŁUGI
          ELEKTRONICZNEJ KONTA KLIENTA, zaś KLIENT uzyskuje możliwość dostępu do
          KONTA KLIENTA i dokonywania zmian podanych podczas REJESTRACJI
          danych</p>
        <p>Do współpracy z systemem informatycznym USŁUGODAWCY po stronie
          KLIENTA konieczny jest dostęp do komputera lub innego urządzenia
          umożliwiającego połączenie z siecią internet. Ponadto konieczne jest
          posiadanie aktywnego konta e-mail.</p>
        <p>KLIENT zobowiązany jest do wprowadzania danych do systemu zgodnych ze
          stanem faktycznym, zgodnych z prawem i dobrymi obyczajami oraz zasadami
          współżycia społecznego. Udostępniane dane nie mogą naruszać dóbr
          osobistych ani praw własności osób trzecich. Muszą ponadto dotyczyć
          opisywanych osób. Wszelką odpowiedzialność za umieszczane przez KLIENTA
          informacje ponosi wyłącznie KLIENT. ADMINISTRATOR nie dokonuje
          sprawdzania i weryfikacji treści umieszczanych przez KLIENTA</p>
        <p>W trakcie Rejestracji KLIENT może dobrowolnie wyrazić zgodę na
          przetwarzanie swoich danych osobowych w celach marketingowych poprzez
          zaznaczenie odpowiedniego pola formularza rejestracyjnego. W takim
          przypadku SPRZEDAWCA wyraźnie informuje o celu zbierania danych
          osobowych KLIENTA, a także o znanych SPRZEDAWCY lub przewidywanych
          odbiorcach tych danych.</p>
        <p><strong>§ 7</strong></p>
        <p><strong>ZAMÓWIENIE - REALIZACJA ZAMÓWIENIA</strong></p>
        <p>Informacje zawarte na stronie internetowej SKLEPU INTERNETOWEGO nie
          stanowią oferty SPRZEDAWCY w rozumieniu kodeksu cywilnego, a jedynie
          zaproszenie KLIENTÓW do składania ofert zawarcia UMOWY SPRZEDAŻY -
          stanowią zaproszenie do zawarcia umowy, w rozumieniu art. 71 kodeksu
          cywilnego.</p>
        <p>KLIENT może składać ZAMÓWIENIA w SKLEPIE INTERNETOWYM za
          pośrednictwem SKLEPU INTERNETOWEGO przez 7 dni w tygodniu, 24 godziny na
          dobę.</p>
        <p>SKLEP INTERNETOWY realizuje ZAMÓWIENIA z wysyłką na terenie Polski
          złożone przez KLIENTÓW</p>
        <p>Przed złożeniem zamówienia KUPUJĄCY musi się zapoznać z niniejszym
          REGULAMINEM. Fakt zapoznania się i akceptacji wszystkich postanowień
          REGULAMINU potwierdza przy rejestracji w systemie oraz przed złożeniem
          ZAMÓWIENIA.</p>
        <p>Do realizacji ZAMÓWIENIA niezbędne jest prawidłowe wypełnienie
          formularza ZAMÓWIENIA.</p>
        <p>ZAMÓWIENIA składane drogą telefoniczną nie będą realizowane.</p>
        <p>Dla zrealizowania UMOWY SPRZEDAŻY i dostawy zamawianych PRODUKTÓW
          konieczne jest podanie przez KLIENTA danych osobowych: imię i nazwisko
          (lub nazwa), adres, adres do doręczenia PRODUKTÓW, adres poczty
          elektronicznej e-mail i w przypadku przedsiębiorców NIP.</p>
        <p>KLIENT składający ZAMÓWIENIE za pośrednictwem SKLEPU INTERNETOWEGO,
          kompletuje ZAMÓWIENIE wybierając PRODUKT, którym jest zainteresowany.
          Dodanie PRODUKTU do ZAMÓWIENIA następuje przez wybór polecenia
          „ZAMAWIAM” pod danym PRODUKTEM prezentowanym na stronie internetowej
          SKLEPU INTERNETOWEGO.</p>
        <p>Jeśli pożądanym przez KLIENTA dokumentem sprzedaży jest faktura,
          KLIENT zobowiązany jest do poinformowania SPRZEDAWCY o swojej
          preferencji, poprzez wysłanie wiadomości do SPRZEDAWCY na podany na
          stronie adres poczty elektronicznej e-mail.</p>
        <p>KLIENT po skompletowaniu całości ZAMÓWIENIA i wskazaniu w „KOSZYKU”
          sposobu dostawy oraz formy płatności, składa ZAMÓWIENIE przez wysłanie
          formularza ZAMÓWIENIE do SPRZEDAWCY, wybierając na stronie internetowej
          SKLEPU INTERNEGOWEGO przycisk „ZAMAWIAM”.</p>
        <p>Każdorazowo przed wysyłką ZAMÓWIENIA, KLIENT jest informowany o
          łącznej cenie za wybrany PRODUKT/PRODUKTY i dostawę, jak też o
          wszystkich dodatkowych kosztach jakie jest zobowiązany ponieść w związku
          z UMOWĄ SPRZEDAŻY.</p>
        <p>Złożenie ZAMÓWIENIA stanowi złożenie SPRZEDAWCY przez KLIENTA oferty
          zawarcia UMOWY SPRZEDAŻY PRODUKTÓW będących przedmiotem ZAMÓWIENIA.</p>
        <p>Po złożeniu ZAMÓWIENIA, SPRZEDAWCA przesyła na podany przez KLIENTA
          adres poczty elektronicznej potwierdzenie jego złożenia.</p>
        <p>Następnie, po potwierdzeniu złożenia ZAMÓWIENIA, SPRZEDAWCA przesyła
          na podany przez KLIENTA adres poczty elektronicznej informację o
          przyjęciu ZAMÓWIENIA do realizacji. Informacja o przyjęciu ZAMÓWIENIA do
          realizacji jest oświadczeniem SPRZEDAWCY o przyjęciu oferty i z chwilą
          jego otrzymania przez KLIENTA zostaje zawarta UMOWA SPRZEDAŻY.</p>
        <p>W przypadku, gdy danego PRODUKTU nie ma w ofercie, KUPUJĄCY drogą
          telefoniczną bądź mailową zostanie o tym fakcie powiadomiony.</p>
        <p>W przypadku zamówień nietypowych SPRZEDAWCA zastrzega sobie możliwość
          dodatkowej weryfikacji ZAMÓWIENIA drogą internetową lub
          telefoniczną.</p>
        <p>Jeżeli po złożeniu ZAMÓWIENIA okaże się, iż zamówiony PRODUKT jest
          obecnie wyprzedany lub chwilowo niedostępny z innych powodów, KLIENT
          zostanie o tym niezwłocznie poinformowany e-mailem lub telefonicznie. W
          przypadku zaistnienia takiej sytuacji, KLIENT podejmuje decyzje o jego
          realizacji (częściowa realizacja, wydłużenie czasu oczekiwania,
          anulowanie całości zamówienia).</p>
        <p><strong>§ 8</strong></p>
        <p><strong>PRODUKT</strong></p>
        <p>SPRZEDAWCA na stronie internetowej SKLEPU INTERNETOWEGO oferuje
          konfigurator PRODUKTU.</p>
        <p>PRODUKT przeznaczony jest do samodzielnego montażu przez KLIENTA.</p>
        <p>Konfigurator pozwala KLIENTOWI na wybór koloru PRODUKTU i liczby
          zamawianych PRODUKTÓW:</p>
        <p>W celu dokonania ZAMÓWIENIA oraz zawarcia UMOWY SPRZEDAŻY, KLIENT
          postępuje zgodnie z zasadami określonymi w § 7 REGULAMINU.</p>
        <p><strong>SPRZEDAWCA informuje, że zgodnie z treścią art. 38 ustawy z
          dnia 30 maja 2014 r. o prawach konsumenta konsumenckie prawo do
          odstąpienia od umowy nie przysługuje w odniesieniu do umów, w której
          przedmiotem świadczenia jest między innymi rzecz wyprodukowana według
          specyfikacji konsumenta lub służąca zaspokojeniu jego
          zindywidualizowanych potrzeb</strong>.</p>
        <p><strong>§ 9</strong></p>
        <p><strong>REALIZACJA ZAMÓWIENIA</strong></p>
        <p>Przygotowanie i wysyłka PRODUKTÓW przez SPRZEDAWCĘ następuje, z
          zastrzeżeniem ust. 3 poniżej:</p>
        <p>1) po potwierdzeniu ZAMÓWIENIA - jeżeli wybrano płatność przy
          odbiorze;</p>
        <p>2) przy płatnościach on-line - od momentu uzyskania pozytywnej
          autoryzacji.</p>
        <p>Termin realizacji ZAMÓWIENIA w przypadku PRODUKTÓW znajdujących się w
          magazynie SPRZEDAWCY - nie dłużej niż 7 dni roboczych, licząc od daty
          ustalonej jak w ustępie poprzednim.</p>
        <p>Z przyczyn niezależnych od SPRZEDAWCY zastrzega on sobie prawo do
          zmiany terminu realizacji ZAMÓWIENIA oraz czasu dostawy zamówionych
          PRODUKTÓW, dokonujący zakupy będzie wówczas o tym poinformowany drogą
          mailową lub/i telefoniczną. Podawane terminy realizacji są terminami
          planowanych dostaw przez SPRZEDAWCĘ, nie są terminami wiążącymi.</p>
        <p>Z chwilą pisemnego pokwitowania odbioru towaru przez KLIENTA, prawo
          własności PRODUKTU oraz wszelkie ryzyko związane z posiadaniem i
          użytkowaniem, a w szczególności ryzyko utraty lub uszkodzenia przechodzi
          na KUPUJĄCEGO.</p>
        <p><strong>§ 10</strong></p>
        <p><strong>CENY</strong></p>
        <p>Podane w ofercie SKLEPU INTERNETOWEGO ceny PRODUKTÓW są cenami w
          walucie polskiej i są cenami brutto (zawierają wymagane prawem podatki w
          tym podatek VAT oraz akcyzowy - jeżeli obowiązuje dla danego produktu,
          oraz opłaty celne).</p>
        <p>Podane ceny są cenami detalicznymi i nie uwzględniają rabatów i
          bonifikat. Rabaty i bonifikaty przyznawane są indywidualnie. Informacje
          o wysokości przyznanego rabatu są udzielane po kontakcie ze
          SPRZEDAWCĄ</p>
        <p>Podane ceny PRODUKTÓW nie obejmują kosztów dostawy. Koszt dostawy w
          dokładnej wysokości jest podawany w trakcie przyjmowania ZAMÓWIENIA i
          zależy od sposobu wysyłki i wartości ZAMÓWIENIA.</p>
        <p>W przypadku braku akceptacji kosztu transportu należy poinformować
          sklep o rezygnacji z ZAMÓWIENIA. KUPUJĄCY w trakcie wypełniania
          formularza zamówienia wybiera sposób dostawy i płatności, jednocześnie
          akceptując swój wybór.</p>
        <p>SPRZEDAWCA zastrzega sobie prawo do zmiany cen PRODUKTÓW znajdujących
          się w ofercie, wprowadzania nowych towarów do oferty, wprowadzania cen
          klubowych i promocyjnych oraz przeprowadzania i odwoływania wszelkiego
          rodzaju akcji promocyjnych i wyprzedaży. Uprawnienie, o którym mowa w
          zdaniu poprzednim, nie ma wpływu na ZAMÓWIENIA złożone przed datą
          wejścia w życie zmiany cen, warunków akcji promocyjnych lub wyprzedaży i
          będą one realizowane na zasadach dotychczasowych.</p>
        <p>Ceną wiążącą dla KLIENTA jest cena obowiązująca w chwili złożenia
          ZAMÓWIENIA.</p>
        <p>SPRZEDAWCA jest płatnikiem VAT i do każdej transakcji sprzedaży
          PRODUKTU wystawia dokument sprzedaży - paragon lub fakturę.</p>
        <p><strong>§ 11</strong></p>
        <p><strong>FORMY PŁATNOŚCI</strong></p>
        <p>SPRZEDAWCA udostępnia KLIENTOWI następujące sposoby płatności z
          tytułu UMOWY SPRZEDAŻY:</p>
        <p>za pobraniem - gotówką - przy odbiorze zakupionych PRODUKTÓW;</p>
        <p>system płatności on-line.</p>
        <p>W wypadku bezskutecznego upływu terminu na dokonanie płatności,
          SPRZEDAWCA prześle KLIENTOWI oświadczenie o odstąpieniu od umowy na
          podstawie art. 491 kodeksu cywilnego.</p>
        <p>Faktura lub paragon dostarczane są w momencie dostawy lub odbioru
          PRODUKTU.</p>
        <p><strong>§ 12</strong></p>
        <p><strong>WYSYŁKA TOWARU</strong></p>
        <p>KUPUJĄCY może wybrać następujące formy dostawy zamówionego
          PRODUKTU:</p>
        <p>dostawa przez Pocztę Polską pod wskazany w ZAMÓWIENIU adres;</p>
        <p>dostawa do paczkomatu INPOST.</p>
        <p>Koszty wysyłki (dostawy) naliczane są zgodnie z aktualnymi cennikami
          firm transportowych i zależą od wagi, rozmiarów przesyłki, wyboru
          sposobu dostawy oraz od wybranej formy płatności.</p>
        <p>Koszt dostawy przez SPRZEDAWCĘ wynika z cennika zamieszczonego na
          stronie internetowej SKLEPU INTERNETOWEGO.</p>
        <p>KUPUJĄCY w trakcie realizacji ZAMÓWIENIA jest informowany o kosztach
          i dokonuje wyboru sposobu, tym samym akceptując przedstawione warunki. W
          przypadku braku akceptacji kosztu transportu należy poinformować sklep o
          rezygnacji z ZAMÓWIENIA.</p>
        <p>Koszty dostawy ponosi KUPUJĄCY.</p>
        <p>KLIENT obowiązany jest zbadać doręczoną przesyłkę w czasie i w sposób
          przyjęty przy przesyłkach danego rodzaju. W razie stwierdzenia ubytku
          lub uszkodzenia przesyłki KLIENT ma prawo żądać od dostawcy spisania
          właściwego protokołu.</p>
        <p><strong>§ 13</strong></p>
        <p><strong>ODPOWIEDZIALNOŚĆ Z TYTUŁU RĘKOJMI I GWARANCJI PRZY SPRZEDAŻY
          Z UDZIAŁEM KONSUMENTA</strong></p>
        <p>SPRZEDAWCA jest zobowiązany dostarczyć PRODUKT będący przedmiotem
          UMOWY SPRZEDAŻY - bez wad.</p>
        <p>SPRZEDAWCA jest odpowiedzialny z tytułu rękojmi za wady fizyczne,
          które istniały w chwili przejścia niebezpieczeństwa na KLIENTA lub
          wynikły z przyczyny tkwiącej w rzeczy sprzedanej w tej samej chwili -
          przez okres 2 lat.</p>
        <p>SPRZEDAWCA informuje, że w przypadku PRODUKTÓW objętych także
          gwarancją uprawnienia KUPUJĄCEGO i obowiązki gwaranta z tego tytułu są
          określone i należy je wykonywać zgodnie z warunkami zamieszczonymi w
          karcie gwarancyjnej. Udzielona gwarancja na sprzedany PRODUKT jest
          uprawnieniem dodatkowym i nie wyłącza, nie ogranicza, ani nie zawiesza
          uprawnień KONSUMENTA z tytułu odpowiedzialności SPRZEDAWCY z tytułu
          przepisów o rękojmi za wady rzeczy sprzedanej (art. 577 Kodeksu
          Cywilnego).</p>
        <p>SPRZEDAWCA jest zwolniony od odpowiedzialności z tytułu rękojmi,
          jeżeli KONSUMENT wiedział o wadzie w chwili zawarcia UMOWY
          SPRZEDAŻY.</p>
        <p>W przypadku stwierdzenia wady lub niezgodności dostarczonego PRODUKTU
          z UMOWĄ SPRZEDAŻY, KONSUMENTOWI przysługuje prawo do złożenia
          reklamacji.</p>
        <p>W celu zgłoszenia reklamacji zalecamy, aby KONSUMENT opisał podstawy
          reklamacji oraz żądania odnośnie do usunięcia wad i przesłał zgłoszenie
          drogą elektroniczną (e-mail) lub pocztą na adres do korespondencji
          SPRZEDAWCY.</p>
        <p>Wadliwy PRODUKT - w uzgodnieniu ze SPRZEDAWCĄ - jeżeli będzie to
          możliwe i celowe - KONSUMENT, który wykonuje uprawnienia z tytułu
          rękojmi, będzie zobowiązany na koszt SPRZEDAWCY dostarczyć
          <strong>PRODUKT</strong> do SPRZEDAWCY. Jeżeli ze względu na rodzaj
          rzeczy lub sposób jej zamontowania dostarczenie PRODUKTU przez KLIENTA
          byłoby nadmiernie utrudnione, KLIENT obowiązany jest udostępnić rzecz
          SPRZEDAWCY w miejscu, w którym PRODUKTU się znajduje</p>
        <p>Jeżeli rzecz sprzedana ma wadę, KLIENT może złożyć oświadczenie z
          wnioskiem o obniżeniu ceny, chyba że SPRZEDAWCA niezwłocznie i bez
          nadmiernych niedogodności dla kupującego wymieni rzecz wadliwą na wolną
          od wad albo wadę usunie. Ograniczenie to nie ma zastosowania, jeżeli
          rzecz była już wymieniona lub naprawiana przez SPRZEDAWCĘ albo
          SPRZEDAWCA uczynił zadość obowiązkowi wymiany rzeczy na wolną od wad lub
          usunięcia wad</p>
        <p>KONSUMENT może zamiast zaproponowanego przez SPRZEDAWCĘ usunięcia
          wady żądać wymiany rzeczy na wolną od wad albo zamiast wymiany rzeczy
          żądać usunięcia wady, chyba że doprowadzenie rzeczy do zgodności z umową
          w sposób wybrany przez KUPUJĄCEGO jest niemożliwe albo wymagałoby
          nadmiernych kosztów w porównaniu ze sposobem proponowanym przez
          SPRZEDAWCĘ. Przy ocenie nadmierności kosztów uwzględnia się wartość
          rzeczy wolnej od wad, rodzaj i znaczenie stwierdzonej wady, a także
          bierze się pod uwagę niedogodności, na jakie narażałby kupującego inny
          sposób zaspokojenia.</p>
        <p>Obniżona cena powinna pozostawać w takiej proporcji do ceny
          wynikającej z UMOWY SPRZEDAŻY, w jakiej wartość rzeczy z wadą pozostaje
          do wartości rzeczy bez wady.</p>
        <p>KLIENT nie może odstąpić od UMOWY SPRZEDAŻY, jeżeli wada jest
          nieistotna.</p>
        <p>SPRZEDAWCA rozpatrzy reklamację bez zbędnej zwłoki. Nie później niż w
          ciągu 30 dni roboczych od daty otrzymania zgłoszenia reklamacji i
          poinformuje w tym czasie drogą pisemną lub elektroniczną KONSUMENTA o
          stanie realizacji reklamacji. Pozostawienie reklamacji w tym czasie bez
          odpowiedzi jest równoznaczne z uznaniem reklamacji.</p>
        <p>Po uwzględnieniu reklamacji SPRZEDAWCA zwróci KONSUMENTOWI koszty
          związane z reklamacją a poniesione przez KONSUMENTA. SPRZEDAWCA przekaże
          należną kwotę KONSUMENTOWI na rachunek bankowy lub przekazem
          pocztowym.</p>
        <p>Jeżeli uznanie reklamacji jest związane z naprawą lub wymianą towaru
          - SPRZEDAWCA odeśle produkt KONSUMENTOWI na własny koszt.</p>
        <p>SPRZEDAWCA nie odpowiada za treści zamieszczone przez KONSUMENTA na
          stronie internetowej <a
            href="http://www.memero.pl"><u>www.memero.pl</u></a> w ramach USŁUGI
          ELEKTRONICZNEJ opisanej w §4 ust. 1 pkt, 4 REGULAMINU, świadczonej
          KONSUMENTOWI.</p>
        <p><strong>§ 14</strong></p>
        <p><strong>ZGŁASZANIE ZAGROŻENIA LUB NARUSZENIA PRAW</strong></p>
        <p>W przypadku, gdy KLIENT lub inna osoba lub podmiot uzna, iż treść
          publikowana na PLATFORMIE MEMERO narusza ich prawa, dobra osobiste,
          dobre obyczaje, uczucia, moralność, przekonania może powiadomić
          ADMINISTRATORA o potencjalnym naruszeniu.</p>
        <p>ADMINISTRATOR powiadomiony o potencjalnym naruszeniu, podejmuje
          niezwłoczne działania mające na celu poinformowanie KLIENTA o
          zaistniałym fakcie oraz w szczególnie uzasadnionych wypadkach może z
          KONTA KLIENTA usunąć treści będące przyczyną naruszenia.</p>
        <p>ADMINISTRATOR nie moderuje i nie filtruje treści zamieszczanych przez
          KLIENTA w ramach świadczonej na jego rzecz USŁUGI ELEKTRONICZNEJ
          <strong>oraz nie ponosi odpowiedzialności za treści zamieszczane przez
            KLIENTA.</strong></p>
        <p>KLIENT ponosi odpowiedzialność prawną za treści zamieszczane na
          PROFILU KLIENTA.</p>
        <p><strong>§ 15</strong></p>
        <p><strong>POSTANOWIENIA KOŃCOWE</strong></p>
        <p>UMOWA SPRZEDAŻY zawierana jest w języku polskim i pod jurysdykcją
          prawa polskiego.</p>
        <p>W sprawach nieuregulowanych REGULAMINEM zastosowanie będą miały
          właściwe przepisy prawa.</p>
        <p>Jeśli którekolwiek z postanowień niniejszego REGULAMINU zostanie
          uznane za niezgodne z prawem, nieważne lub w inny sposób niewykonalne w
          zakresie przewidzianym przepisami prawa, to w tym zakresie zostaje ono
          wyłączone. W pozostałym zakresie REGULAMIN pozostaje obowiązujący.</p>
        <p>Spory powstałe pomiędzy SPRZEDAWCĄ, a KLIENTEM będącym jednocześnie
          KONSUMENTEM zostają poddane właściwym sądom powszechnym. Spory powstałe
          pomiędzy SPRZEDAWCĄ, a KLIENTEM niebędącym jednocześnie KONSUMENTEM
          zostają poddane sądowi właściwemu ze względu na siedzibę
          USŁUGODAWCY.</p>
        <p>Wszystkie zdjęcia zamieszczone w SKLEPIE INTERNETOWYM chronione są na
          podstawie ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach
          pokrewnych - posiadają twórczy i indywidualny charakter. Nie można ich
          kopiować bez zgody SPRZEDAWCY.</p>
      </Description>
    </Container>
  );
};

export default TermsAndConditionsContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({theme}) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({theme}) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 24px;
  overflow-y: auto;
`;
