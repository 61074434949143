import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SectionContent,
  SectionMainLine,
  SummaryDescription,
} from "global-styles/commonOrderStyles";

import { PlacedOrderFullData } from "types/Order";

interface Props {
  orderFullData: PlacedOrderFullData | undefined;
}

const PlacedOrderAddress = ({ orderFullData }: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Header>
        <SummaryDescription>
          {t("orders.purchaserDataAndAddress")}
        </SummaryDescription>
      </Header>
      <SectionContent>
        <SectionMainLine>{`${orderFullData?.shippingAddress.firstName} ${orderFullData?.shippingAddress.lastName}`}</SectionMainLine>
        <SectionMainLine>{`${orderFullData?.shippingAddress.street}/${orderFullData?.shippingAddress.flatNumber}`}</SectionMainLine>
        <SectionMainLine>{`${orderFullData?.shippingAddress.zipCode} ${orderFullData?.shippingAddress.city}, ${orderFullData?.shippingAddress.country}`}</SectionMainLine>
      </SectionContent>
    </Section>
  );
};

export default PlacedOrderAddress;
