import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "global-styles/commonOrderStyles";
import {
  darkThemeScrollbarStyle,
  firefoxLightColorBackgroundScrollbar,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import Address from "components/order-plate/order-summary/Address";
import Discount from "components/order-plate/order-summary/Discount";
import OrderItems from "components/order-plate/order-summary/OrderItems";
import Payment from "components/order-plate/order-summary/Payment";
import Shipment from "components/order-plate/order-summary/Shipment";
import SubmitOrderButton from "components/order-plate/order-summary/SubmitOrderButton";
import TotalPrice from "components/order-plate/order-summary/TotalPrice";

import { OrderCheckoutSummary } from "types/Order";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  orderCheckoutSummary: OrderCheckoutSummary | undefined;
  setOrderCheckoutSummary: Dispatch<
    SetStateAction<OrderCheckoutSummary | undefined>
  >;
}

const OrderSummary = ({
  orderCheckoutSummary,
  setOrderCheckoutSummary,
}: Props) => {
  const { detect } = require("detect-browser");
  const browser = detect();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  return (
    <Container
      isDarkTheme={isDarkTheme}
      isFirefox={browser && browser.name === "firefox"}
    >
      <OrderSummaryTitle>{t("orderPlate.summary")}</OrderSummaryTitle>
      {orderCheckoutSummary && (
        <DataSummaryWrapper>
          <OrderItems orderCheckoutSummary={orderCheckoutSummary} />
          <Address />
          <Shipment orderCheckoutSummary={orderCheckoutSummary} />
          <Payment orderCheckoutSummary={orderCheckoutSummary} />
          <Discount
            orderCheckoutSummary={orderCheckoutSummary}
            setOrderCheckoutSummary={setOrderCheckoutSummary}
          />
          <TotalPrice orderCheckoutSummary={orderCheckoutSummary} />
          <SubmitOrderButton />
        </DataSummaryWrapper>
      )}
    </Container>
  );
};

export default OrderSummary;

interface ContainerProps {
  isDarkTheme: boolean;
  isFirefox: boolean;
}

const Container = styled.div<ContainerProps>`
  flex-basis: 43%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  padding: 32px;

  border-radius: ${(p) =>
    p.isFirefox ? "0 12px 12px 0" : "5px 12px 12px 5px"};

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight};`}

  ${(p) =>
    p.isDarkTheme
      ? darkThemeScrollbarStyle
      : p.isFirefox
      ? firefoxLightColorBackgroundScrollbar
      : lightThemeScrollbarStyle}
`;

const OrderSummaryTitle = styled(Title)`
  margin-bottom: 15px;
`;

const DataSummaryWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
