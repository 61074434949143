import styled, { css } from "styled-components";

export const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 10px;
  white-space: pre-line;
`;

export const SubformWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;

export const Price = styled.div`
  font-size: 19px;
  padding-top: 2px;
`;

export const subformContentStyle = css`
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 6px;
  width: 100%;
  padding: 25px 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  height: 24px;
  margin-bottom: 4px;
`;

export const Section = styled.div`
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

export const SectionContent = styled.div`
  font-size: 16px;
`;

export const SummaryDescription = styled(Description)`
  font-size: 15px;
  font-weight: 600;
`;

export const SummaryPrice = styled.div`
  font-size: 15px;
  padding-top: 1px;
`;

export const SectionMainLine = styled.div`
  line-height: 150%;
  font-size: 16px;
`;
