import { pl } from "utils/translations/locales/pl";

export enum AvailableLanguages {
  pl = "pl",
}

const resources = {
  pl,
};

export default resources;
