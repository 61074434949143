import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useWatch, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Description,
  Price,
  subformContentStyle,
  SubformWrapper,
} from "global-styles/commonOrderStyles";

import RadioButtonRHF from "components/inputs/RadioButtonRHF";
import MainButton from "components/inputs/MainButton";
import ParcelLockersModal from "components/order-plate/order-data-forms/ParcelLockersModal";

import { DELIVERY_METHODS_API_NAMES } from "utils/consts";
import { OrderFormData, OrderPricing } from "types/Order";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as AlertIcon } from "assets/alert-icon.svg";
import { ReactComponent as RightArrowIcon } from "assets/right-arrow-icon.svg";

interface Props {
  orderPricing: OrderPricing | undefined;
}

const ShipmentMethodsForm = ({ orderPricing }: Props) => {
  const [isParcelLockersModalOpen, setIsParcelLockersModalOpen] =
    useState<boolean>(false);
  const [chosenParcelLocker, setChosenParcelLocker] = useState<
    string | undefined
  >();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();

  const radioButtonsWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "shipment.method",
  });

  const inPostPricing = orderPricing?.shipping.find(
    (method) => method.name === DELIVERY_METHODS_API_NAMES.IN_POST
  );

  const postPricing = orderPricing?.shipping.find(
    (method) => method.name === DELIVERY_METHODS_API_NAMES.POST
  );

  useEffect(
    function setParcelLockerStateIfChosenEarlier() {
      const parcelLockerAddressFormValue = orderPlateFormMethods.getValues(
        "shipment.inPostAddress"
      );

      if (parcelLockerAddressFormValue !== "") {
        setChosenParcelLocker(parcelLockerAddressFormValue);
      }
    },
    [orderPlateFormMethods]
  );

  useEffect(
    function setParcelLockerFormValue() {
      if (chosenParcelLocker && chosenParcelLocker !== "") {
        orderPlateFormMethods.setValue(
          "shipment.inPostAddress",
          chosenParcelLocker
        );
      }
    },
    [chosenParcelLocker, orderPlateFormMethods]
  );

  return (
    <>
      <SubformWrapper>
        <Description>{t("orderPlate.chooseDeliveryMethod")}</Description>
        <ShipmentMethods isDarkTheme={isDarkTheme}>
          <Method>
            <RadioButtonRHF
              {...orderPlateFormMethods.register("shipment.method")}
              label={t("orderPlate.parcelLockers")}
              biggerFontSize
              noMarginTop
              value="IN_POST"
            />
            {orderPricing && (
              <Price>{`${inPostPricing?.price} ${inPostPricing?.currency}`}</Price>
            )}
          </Method>
          {radioButtonsWatcher === "IN_POST" && (
            <ParcelLockerChoiceWrapper>
              <MainButton
                onClick={() => setIsParcelLockersModalOpen(true)}
                noMarginTop
              >
                {chosenParcelLocker
                  ? t("orderPlate.chooseDifferentPickupPoint")
                  : t("orderPlate.choosePickupPoint")}
                <StyledRightArrowIcon />
              </MainButton>
              {chosenParcelLocker && (
                <ParcelLockerAddress>
                  <span>{t("orderPlate.chosenPickupPoint")}</span>
                  {chosenParcelLocker}
                </ParcelLockerAddress>
              )}
              {!chosenParcelLocker && (
                <Alert>
                  <AlertIcon /> <span>{t("orderPlate.choosePickupPoint")}</span>
                </Alert>
              )}
            </ParcelLockerChoiceWrapper>
          )}
          <Method>
            <RadioButtonRHF
              {...orderPlateFormMethods.register("shipment.method")}
              label={t("orderPlate.post")}
              biggerFontSize
              noMarginTop
              value="POST"
            />
            {orderPricing && (
              <Price>{`${postPricing?.price} ${postPricing?.currency}`}</Price>
            )}
          </Method>
        </ShipmentMethods>
      </SubformWrapper>
      <ParcelLockersModal
        isParcelLockersModalOpen={isParcelLockersModalOpen}
        setIsParcelLockersModalOpen={setIsParcelLockersModalOpen}
        setChosenParcelLocker={setChosenParcelLocker}
      />
    </>
  );
};

export default ShipmentMethodsForm;

const ShipmentMethods = styled.div<{ isDarkTheme: boolean }>`
  ${subformContentStyle}

  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Method = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    display: grid;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ParcelLockerChoiceWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  margin-left: 5px;
`;

const parcelLockerInfoStyle = css`
  font-size: 13px;
  margin-top: 7px;
  margin-left: 5px;
`;

const Alert = styled.div`
  ${parcelLockerInfoStyle}
  color: ${(p) => p.theme.colors.validationErrorRed};

  & span {
    vertical-align: -2px;
    margin-left: 5px;
  }
`;

const ParcelLockerAddress = styled.div`
  ${parcelLockerInfoStyle}
  font-size: 13px;

  & span {
    font-weight: 600;
  }
`;
