import { ReactNode } from "react";
import Button from "react-bootstrap/Button";
import styled, { css } from "styled-components";

import { ButtonType } from "types/Others";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  onClick?: () => void;
  type?: ButtonType;
  variant?: string;
  className?: string;
  disabled?: boolean;
  expandWidth?: boolean;
  isGray?: boolean;
  lateralPadding?: number;
  noMarginTop?: boolean;
  keepLightTheme?: boolean;
  children: ReactNode;
}

const OutlineButton = ({
  onClick,
  type = "button",
  variant = "outline-dark",
  className,
  disabled,
  expandWidth,
  isGray,
  lateralPadding,
  noMarginTop,
  keepLightTheme,
  children,
}: Props) => {
  const { isDarkTheme } = useColorThemeContext();

  return (
    <>
      <StyledButton
        className={className}
        variant={isGray ? "outline-secondary" : variant}
        type={type}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (onClick) onClick();
          e.currentTarget.blur();
        }}
        expandwidth={expandWidth ? "true" : "false"}
        lateralpadding={lateralPadding}
        nomargintop={noMarginTop ? "true" : "false"}
        disabled={disabled}
        isdarktheme={
          keepLightTheme ? undefined : isDarkTheme ? "true" : undefined
        }
      >
        {children}
      </StyledButton>
    </>
  );
};

export default OutlineButton;

interface ButtonProps {
  expandwidth?: string;
  lateralpadding?: number;
  nomargintop?: string;
  isdarktheme: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`

  ${(p) => p.expandwidth === "true" && "width: 100%;"}
  height: 36px;
  padding-top: 5px;
  padding-bottom: 6px;
  font-family: Lato;
  margin-top: ${(p) => (p.nomargintop === "true" ? "0" : "16px")};
  transition: 0.5s;

  ${(p) => p.isdarktheme && `color: ${p.theme.colors.darkOrLight};`}
  ${(p) => p.isdarktheme && `border-color: ${p.theme.colors.darkOrLight};`}

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    ${(p) => p.isdarktheme && `background-color: ${p.theme.colors.darkGray};`}
    ${(p) => p.isdarktheme && `border-color: ${p.theme.colors.darkGray};`}
  }

  ${({ lateralpadding }) =>
    lateralpadding &&
    css`
      padding-left: ${lateralpadding}px;
      padding-right: ${lateralpadding}px;
    `}
`;
