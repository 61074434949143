import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { store } from "store/store";
import { t } from "i18next";

import httpService from "utils/httpService";

import { LOCAL_STORAGE_AUTH_TOKEN } from "utils/consts";
import {renderErrorToast, renderSuccessToast} from "utils/toasts";
import { RootState } from "store/store";

export interface AuthState {
  token: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  token: localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN),
  loading: false,
  error: null,
};

interface Credentials {
  email: string;
  password: string;
}

type OauthProvider = "google" | "facebook";

interface OauthLoginRequest {
  provider: OauthProvider;
  access_token: string;
}

export const loginOauth = createAsyncThunk(
  "auth/loginOauth",
  async (credentials: OauthLoginRequest, thunkApi) => {
    try {
      const response = await httpService.post(
        `auth/${credentials.provider}/`,
        {
          access_token: credentials.access_token,
        }
      );
      renderSuccessToast(t("auth.accountCreatedWillLogIn"));
      const token = `Token ${response.data.key}`;
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, token);

      return { token };
    } catch (error: any) {
      renderErrorToast(t("loginError"));
      return thunkApi.rejectWithValue(error?.message);
    }
  }
)

export const logIn = createAsyncThunk(
  "auth/logIn",
  async (credentials: Credentials, thunkApi) => {
    try {
      const logInResponse = await httpService.post("api/auth/v1/login/", {
        email: credentials.email,
        password: credentials.password,
      });

      const token = `Token ${logInResponse.data.key}`;
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, token);

      return { token };
    } catch (error: any) {
      renderErrorToast(t("loginError"));
      return thunkApi.rejectWithValue(error?.message);
    }
  }
);

export const logOut = createAsyncThunk("auth/logOut", async () => {
  const token = store.getState().auth.token;

  const logOutResponse = await httpService.post("api/auth/v1/logout/", {
    headers: {
      Accept: "application/json",
      Authorization: token,
    },
  });

  localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);

  return logOutResponse.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.loading = false;
        state.error = null;
      })
      .addCase(logIn.rejected, (state, action: any) => {
        state.token = null;
        state.loading = false;
        state.error = "error";
      })
      .addCase(logOut.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.token = null;
        state.loading = false;
        state.error = null;
      })
      .addCase(logOut.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginOauth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginOauth.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginOauth.rejected, (state, action: any) => {
        state.loading = false;
        state.error = "error";
      });
  },
});

export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthIsLoading = (state: RootState) => state.auth.loading;

export default authSlice.reducer;
