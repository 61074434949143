import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import { BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS } from "utils/consts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

const ProfileSummary = () => {
  const { profileGeneralData } = useProfileDataContext();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const profileId = profileGeneralData?.id;

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const firstName = profileGeneralData?.fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.NAME
  )?.content;

  const lastName = profileGeneralData?.fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.SURNAME
  )?.content;

  return (
    <SummaryWrapper>
      <Header>{t("addProfile.summary")}</Header>
      <ContentWrapper isDarkTheme={isDarkTheme}>
        <div>
          <Subheader>{t("addProfile.profileCreated")}</Subheader>
          <Name>{`${firstName} ${lastName}`}</Name>
          <Paragraph>
            Chwile bywają ulotne. Historie z biegiem czasu zacierają się, a
            pamięć o bliskich osobach blaknie. Memero pozwoli Ci zachować
            wspomnienia o ważnej dla Ciebie osobie, a tabliczka - do nich
            powracać.
          </Paragraph>
        </div>
        <div>
          <QrCode>
            <QrCodeImg src={`${baseUrl}api/passedaway/person/v1/${profileId}/qr-code/`} alt="qrcode" />
            <QrCodeDscription>
              {t("seeYourCreatedProfile")}
            </QrCodeDscription>
          </QrCode>
        </div>
      </ContentWrapper>
    </SummaryWrapper>
  );
};

export default ProfileSummary;

const SummaryWrapper = styled.div`
  grid-area: form;
`;

const Header = styled.div`
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 150%;
  font-weight: 600;
`;

const ContentWrapper = styled.div<{ isDarkTheme: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 40px;
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 6px;
  padding: 24px;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const Subheader = styled.div`
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 20px;
`;

const Name = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 40px;
`;

const Paragraph = styled.div`
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 30px;

  &:nth-child(4) {
    margin-bottom: 10px;
  }
`;

const QrCode = styled.div`
  grid-area: qrcode;
  flex-direction: column;
  justify-content: center;


`;

const QrCodeDscription = styled.div`
  margin-bottom: 30px;
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
`;

const QrCodeImg = styled.img`
  filter: brightness(90%);
  max-width: 100%;
`;
