import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import DatePickerRHF from "components/inputs/DatePickerRHF";
import InputRHF from "components/inputs/InputRHF";
import SectionPhotosUpload from "components/profile/SectionPhotosUpload";
import TextEditorRHF from "components/inputs/TextEditorRHF";

import { AddSectionDataFormValues } from "components/profile/forms/AddSectionDataFormController";
import { PersonDescriptionSubsection } from "types/Profile";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";
import { useQuestionModalContext } from "global-contexts/QuestionModalContext";

import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";
import { ReactComponent as XIcon } from "assets/x-icon.svg";

interface Props {
  defaultFormValues: AddSectionDataFormValues;
  sectionIndex: number;
  subsectionsInitialValuesForTextEditor:
    | PersonDescriptionSubsection[]
    | undefined;
  setSubsectionsInitialValuesForTextEditor: Dispatch<
    SetStateAction<PersonDescriptionSubsection[] | undefined>
  >;
}

const AddSectionDataFormFields = ({
  defaultFormValues,
  sectionIndex,
  subsectionsInitialValuesForTextEditor,
  setSubsectionsInitialValuesForTextEditor,
}: Props) => {
  const { personDescription, setPersonDescription, setFocusedSectionIndex } =
    useProfileDataContext();

  const { openQuestionModal } = useQuestionModalContext();

  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<AddSectionDataFormValues>();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "subsections",
  });

  const addSubsection = () => {
    append({
      subtitle: undefined,
      startDate: undefined,
      endDate: undefined,
      rawContent: undefined,
    });
  };

  const removeSubsection = (subsectionIndex: number) => {
    remove(subsectionIndex);

    const updatedPersonDescription = personDescription.map((section, index) => {
      if (index !== sectionIndex) return section;
      else {
        return {
          ...section,
          subsections: personDescription[sectionIndex].subsections.filter(
            (_, index) => index !== subsectionIndex
          ),
        };
      }
    });

    setPersonDescription(updatedPersonDescription);
  };

  const renderStartDateDefaultValue = (index: number) =>
    defaultFormValues.subsections[index]
      ? defaultFormValues.subsections[index].startDate
      : undefined;

  const renderEndDateDefaultValue = (index: number) =>
    defaultFormValues.subsections[index]
      ? defaultFormValues.subsections[index].endDate
      : undefined;

  const renderTextEditorDefaultValue = (index: number) =>
    subsectionsInitialValuesForTextEditor &&
    subsectionsInitialValuesForTextEditor[index] &&
    subsectionsInitialValuesForTextEditor[index].rawContent
      ? subsectionsInitialValuesForTextEditor[index].rawContent
      : undefined;

  const updateDefaultSubsectionsValues = useCallback(() => {
    const defaultSubsectionsValuesArray = personDescription[
      sectionIndex
    ].subsections.map((subsection) => subsection);
    setSubsectionsInitialValuesForTextEditor(defaultSubsectionsValuesArray);
  }, [
    personDescription,
    sectionIndex,
    setSubsectionsInitialValuesForTextEditor,
  ]);

  useEffect(
    function setDefaultValuesForTextEditor() {
      if (personDescription) {
        updateDefaultSubsectionsValues();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionIndex]
  );

  return (
    <Container>
      <AddPhotos>
        {t("addProfile.addPhotos")}
        <SectionPhotosUpload sectionIndex={sectionIndex} />
      </AddPhotos>
      <form>
        {fields.map((field, index) => (
          <InputsContainer key={field.id}>
            <InputRHF
              formFieldName={
                `subsections.${index}.subtitle` as "subsections.0.subtitle"
              }
              control={control}
              label={t("addProfile.paragraphTitle")}
              placeholder={t("addProfile.enterParagraphTitle")}
              error={errors.subsections?.[index]?.subtitle?.message}
              noMarginTop
              onFocus={() => setFocusedSectionIndex(sectionIndex)}
            />
            <DatePickersWrapper>
              <div>
                <DatePickerRHF
                  control={control}
                  formFieldName={
                    `subsections.${index}.startDate` as "subsections.0.startDate"
                  }
                  placeholder={t("addProfile.startDate")}
                  defaultValue={renderStartDateDefaultValue(index)}
                  label={t("addProfile.datesRange")}
                  onFocus={() => setFocusedSectionIndex(sectionIndex)}
                  onlyMonthAndYear
                />
              </div>
              <SecondDatePickerWrapper>
                <DatePickerRHF
                  control={control}
                  formFieldName={
                    `subsections.${index}.endDate` as "subsections.0.endDate"
                  }
                  placeholder={t("addProfile.endDate")}
                  defaultValue={renderEndDateDefaultValue(index)}
                  onFocus={() => setFocusedSectionIndex(sectionIndex)}
                  onlyMonthAndYear
                />
              </SecondDatePickerWrapper>
            </DatePickersWrapper>
            <TextEditorRHF
              control={control}
              formFieldName={
                `subsections.${index}.rawContent` as "subsections.0.rawContent"
              }
              label={t("addProfile.description")}
              defaultValue={renderTextEditorDefaultValue(index)}
              error={errors.subsections?.[index]?.rawContent?.message}
              onFocus={() => setFocusedSectionIndex(sectionIndex)}
            />
            {fields.length === index + 1 && (
              <ExtraButton onClick={addSubsection} type="button">
                <StyledSmallerPlusIcon />
                {t("addProfile.addAnotherParagraph")}
              </ExtraButton>
            )}
            {fields.length > index + 1 && (
              <ExtraButton
                onClick={() =>
                  openQuestionModal({
                    text: t("addProfile.wantToDeleteParagraphQuestion"),
                    rightButtonAction: () => removeSubsection(index + 1),
                  })
                }
                type="button"
              >
                <DeleteParapgraphXIcon />
                {t("addProfile.deleteParagraphBelow")}
              </ExtraButton>
            )}
          </InputsContainer>
        ))}
      </form>
    </Container>
  );
};

export default AddSectionDataFormFields;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkWhiteOrDark};
  border-radius: 10px;
  padding: 0 10px;
`;

const AddPhotos = styled.div`
  margin-top: 12px;
`;

const InputsContainer = styled.div`
  padding: 5px 0 0;
`;

const DatePickersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

const ExtraButton = styled.button<{ color?: string }>`
  all: unset;
  display: inline-block;
  margin-bottom: 8px;
  color: ${(p) => (p.color ? p.color : p.theme.colors.grayOrLightGray)};
  font-size: 14px;
  transition: 0.5s;
  margin-top: 10px;

  &:hover {
    opacity: 0.6;
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.colors.inputFocusBlue};
  }
`;

const StyledSmallerPlusIcon = styled(PlusIcon)`
  width: 22px;
  height: 22px;
  vertical-align: -6px;
  margin-left: 0;
  margin-right: 2px;
`;

const DeleteParapgraphXIcon = styled(XIcon)`
  vertical-align: -5px;
  margin-left: 0;
  margin-right: 2px;
`;

const SecondDatePickerWrapper = styled.div`
  margin-top: 15px;
`;
