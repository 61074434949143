import { t } from "i18next";

import { useProfileDataContext } from "global-contexts/ProfileDataContext";

import { BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS } from "utils/consts";
import { ProfileGeneralData } from "types/Profile";

export const useBasicDataUtils = () => {
  const { profileGeneralData } = useProfileDataContext();

  const isReorderButtonDisabled =
    profileGeneralData &&
    profileGeneralData.fields
      ?.map((field) => field.fieldName)
      .filter(
        (el) =>
          !Object.values(BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS).includes(
            el.toLowerCase()
          )
      ).length < 2;

  const basicDataReorderableFields = profileGeneralData?.fields.filter(
    (field) =>
      !Object.values(BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS).includes(
        field.fieldName.toLowerCase()
      )
  );
  const basicDataToReorder = basicDataReorderableFields?.map((field, index) => {
    return { cardId: index, text: field.fieldName, objectContent: field };
  });

  const basicDataNonReorderableFields = profileGeneralData?.fields.filter(
    (field) =>
      Object.values(BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS).includes(
        field.fieldName.toLowerCase()
      )
  );

  return {
    isReorderButtonDisabled,
    basicDataToReorder,
    basicDataNonReorderableFields,
  };
};

export const renderLabel = (
  profileGeneralData: ProfileGeneralData,
  index: number
) => `${profileGeneralData?.fields[index].fieldName}`;

export const renderTextInputPlaceholder = (
  profileGeneralData: ProfileGeneralData,
  index: number
) =>
  t("addProfile.newFieldPlaceholder", {
    newFieldName: profileGeneralData?.fields[index].fieldName
      .toLowerCase()
      .includes("data")
      ? profileGeneralData?.fields[index].fieldName
          .toLowerCase()
          .replace("data", "datę")
      : profileGeneralData?.fields[index].fieldName.toLowerCase(),
  });
