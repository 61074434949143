import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from "react-i18next";

import { colors } from "utils/outsideThemeColors";

import httpService from "utils/httpService";
import InputPasswordRHF from "components/inputs/InputPasswordRHF";
import MainButton from "components/inputs/MainButton";
import OutlineButton from "components/inputs/OutlineButton";

import { renderErrorToast, renderSuccessToast } from "utils/toasts";
import { UserSettingsData } from "types/Others";
import {
  SettingsPasswordChangeFormValues,
  useSettingsPasswordChangeFormController,
} from "components/settings/forms/SettingsPasswordChangeFormController";

import { ReactComponent as EditIcon } from "assets/edit-icon.svg";

interface Props {
  rowData: UserSettingsData;
  isAnySettingsRowBeingEdited: boolean;
  setIsAnySettingsRowBeingEdited: Dispatch<SetStateAction<boolean>>;
}

const SettingsPasswordTableRow = ({
  rowData,
  isAnySettingsRowBeingEdited,
  setIsAnySettingsRowBeingEdited,
}: Props) => {
  const [isThisRowBeingEdited, setIsThisRowBeingEdited] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useSettingsPasswordChangeFormController();

  const submitPasswordChangeForm = (
    values: SettingsPasswordChangeFormValues
  ) => {
    setIsLoading(true);

    httpService
      .post(`api/auth/v1/password/change/`, {
        newPassword1: values.newPassword,
        newPassword2: values.newPasswordConfirmation,
      })
      .then(() => {
        setIsLoading(false);
        setIsThisRowBeingEdited(false);
        setIsAnySettingsRowBeingEdited(false);
        renderSuccessToast("Hasło zostało zmienione");
      })
      .catch(() => {
        setIsLoading(false);
        renderErrorToast(t("errorOccurred"));
      });
  };

  const toggleEditing = () => {
    setIsThisRowBeingEdited((prevState) => !prevState);
    setIsAnySettingsRowBeingEdited((prevState) => !prevState);
  };

  const renderPasswordValueField = () => (
    <>
      {!isThisRowBeingEdited && <PasswordRowItem>XXXXXXXXXX</PasswordRowItem>}
      {isThisRowBeingEdited && (
        <PasswordEditRowItem>
          <InputPasswordRHF
            formFieldName="newPassword"
            control={control}
            label={t("auth.newPassword")}
            sublabel={t("auth.passwordRequirements")}
            placeholder={t("auth.enterNewPassword")}
            error={errors.newPassword?.message}
            inputTabIndex={1}
            noMarginTop
          />
          <InputPasswordRHF
            formFieldName="newPasswordConfirmation"
            control={control}
            label={t("auth.repeatNewPassword")}
            placeholder={t("auth.repeatNewPassword")}
            error={errors.newPasswordConfirmation?.message}
            inputTabIndex={2}
            noMarginTop
          />
        </PasswordEditRowItem>
      )}
    </>
  );

  return (
    <RowWrapper isRowExpanded={isThisRowBeingEdited}>
      <RowItem>{rowData.dataName}</RowItem>
      {renderPasswordValueField()}
      {!isThisRowBeingEdited && (
        <RowItem>
          <EditButton
            onClick={toggleEditing}
            disabled={isAnySettingsRowBeingEdited}
          >
            <StyledEditIcon />
            <EditText>{t("edit")}</EditText>
          </EditButton>
        </RowItem>
      )}
      {isThisRowBeingEdited && !isLoading && (
        <RowItem>
          <OutlineButton noMarginTop onClick={toggleEditing}>
            {t("cancel")}
          </OutlineButton>
          <MainButton
            type="submit"
            noMarginTop
            onClick={handleSubmit(submitPasswordChangeForm)}
          >
            {t("save")}
          </MainButton>
        </RowItem>
      )}
      {isThisRowBeingEdited && isLoading && (
        <LoaderWrapper>
          <SyncLoader color={colors.dark} size={10} />
        </LoaderWrapper>
      )}
    </RowWrapper>
  );
};

export default SettingsPasswordTableRow;

const RowWrapper = styled.div<{ isRowExpanded: boolean }>`
  height: ${(p) => (p.isRowExpanded ? "215px" : "74px")};
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};

  &:last-child {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  }
`;

const RowItem = styled.div`
  font-size: 19px;

  &:nth-child(1) {
    flex-basis: 30%;
    font-weight: 600;
  }
  &:nth-child(2) {
    flex-basis: 50%;
    padding-right: 140px;
  }
  &:nth-child(3) {
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;

const PasswordRowItem = styled(RowItem)`
  -webkit-text-security: disc;
`;

const PasswordEditRowItem = styled(RowItem)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:nth-child(2) {
    margin-bottom: 10px;
  }
`;

const EditButton = styled.button`
  all: unset;
  margin-top: 5px;
  margin-left: 11px;
  margin-bottom: 6px;
  transition: 0.5s;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.midGrayOrGray};
    cursor: unset;
  }

  &:hover:disabled {
    opacity: 1;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  width: 21px;
  height: 21px;
`;

const EditText = styled.div`
  font-size: 14px;
  margin-left: 5px;
`;

const LoaderWrapper = styled.div`
  margin-left: 80px;
`;
