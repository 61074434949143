import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export interface ForgottenPasswordFormValues {
  userEmail: string;
}

export const useForgottenPasswordFormController = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      userEmail: Yup.string()
        .trim()
        .required(t("validation.obligatoryField"))
        .email(t("validation.invalidEmail")),
    });
  }, [t]);

  return useForm<ForgottenPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
