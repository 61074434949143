import styled from "styled-components";

import {
  LinesWrapper,
  Lines,
  Diamond,
} from "global-styles/horizontalLineWithDiamond";

import { BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS } from "utils/consts";
import { ProfileGeneralData } from "types/Profile";

interface Props {
  profileGeneralData: ProfileGeneralData;
}

const FirstAndLastNameField = ({ profileGeneralData }: Props) => {
  const firstName = profileGeneralData?.fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.NAME
  )?.content;

  const lastName = profileGeneralData?.fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.SURNAME
  )?.content;

  return (
    <NameWrapper>
      <Name>{firstName}</Name>
      <Name>{lastName}</Name>
      <LinesWrapper>
        <Lines>
          <Diamond />
        </Lines>
      </LinesWrapper>
    </NameWrapper>
  );
};

export default FirstAndLastNameField;

const NameWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding: 0 30px;
`;

const Name = styled.div`
  display: inline-block;
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  line-height: 1.2;

  &:first-child {
    margin-right: 10px;
  }
`;
