import { Notyf } from "notyf";
import "notyf/notyf.min.css";

import { colors } from "utils/outsideThemeColors";

const notyf = new Notyf();

export const renderErrorToast = (
  text: string,
  delay: number = 0,
  duration: number = 6000
) => {
  return setTimeout(
    () =>
      notyf.error({
        message: text,
        duration: duration,
        position: { x: "right", y: "bottom" },
        dismissible: true,
        className: "font-size-12",
        backgroundColor: colors.errorRed,
      }),
    delay
  );
};

export const renderSuccessToast = (
  text: string,
  delay: number = 0,
  duration: number = 6000
) => {
  return setTimeout(
    () =>
      notyf.success({
        message: text,
        duration: duration,
        position: { x: "right", y: "bottom" },
        dismissible: true,
        className: "font-size-12",
        backgroundColor: colors.successGreen,
      }),
    delay
  );
};

export const renderInformationToast = (
  text: string,
  delay: number = 0,
  duration: number = 6000
) => {
  return setTimeout(
    () =>
      notyf.success({
        message: text,
        duration: duration,
        position: { x: "right", y: "bottom" },
        dismissible: true,
        className: "font-size-12",
        backgroundColor: colors.infoOrange,
        icon: false,
      }),
    delay
  );
};
