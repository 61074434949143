import { FormEvent, useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from "react-i18next";

import "cropperjs/dist/cropper.css";
import "./styles/Cropper.css";
import { colors } from "utils/outsideThemeColors";
import { lightTheme } from "global-styles/colorThemes";
import { smallOutlineButtonStyle } from "global-styles/sharedStyles";

import httpService from "utils/httpService";

import { renderErrorToast, renderSuccessToast } from "utils/toasts";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

import { ReactComponent as XThickIcon } from "assets/x-thick-icon.svg";

interface Props {
  closeModal: () => void;
}

export const ProfilePhotoCropper = ({ closeModal }: Props) => {
  const [image, setImage] = useState<string>();
  const [cropper, setCropper] = useState<Cropper>();
  const [originalPhotoName, setOriginalPhotoName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { profileGeneralData, setProfileGeneralData } = useProfileDataContext();

  const { t } = useTranslation();

  const selectFileInputRef = useRef<HTMLInputElement>(null);

  const onChange = (e: any) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files.length !== 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const img = cropper?.getCroppedCanvas().toDataURL();

    if (selectFileInputRef.current && profileGeneralData) {
      httpService
        .post(
          `api/passedaway/person/v1/${profileGeneralData?.id}/photos/from-base64/`,
          {
            photo: img,
            originalFileName: originalPhotoName,
          }
        )
        .then((response) => {
          setProfileGeneralData({
            ...profileGeneralData,
            photo: response.data,
          });
          closeModal();
          renderSuccessToast(t("addProfile.photoHasBeenSavedInProfile"));
          setIsLoading(false);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    }
  };

  const selectValue =
    selectFileInputRef.current && selectFileInputRef.current.value;

  useEffect(
    function defineOriginalPhotoName() {
      if (selectFileInputRef.current && selectFileInputRef.current.value) {
        setOriginalPhotoName(
          selectFileInputRef.current.value.substring(
            selectFileInputRef.current.value.lastIndexOf("\\") + 1
          )
        );
      }
    },
    [selectValue]
  );

  return (
    <Wrapper>
      <Description>
        {t("addProfile.uploadProfilePhotoModalDescription")}
      </Description>
      <UploadForm onSubmit={getCropData}>
        <ButtonsWrapper>
          <SelectFile
            type="file"
            onChange={(e) => onChange(e)}
            ref={selectFileInputRef}
          />
          {image && (
            <SelectedFileActions>
              {!isLoading && (
                <>
                  <UploadFileInput
                    type="submit"
                    value={t("addProfile.savePhoto")}
                  />
                </>
              )}
              {isLoading && (
                <LoaderWrapper>
                  <SyncLoader color={colors.dark} size={7} />
                </LoaderWrapper>
              )}
            </SelectedFileActions>
          )}
        </ButtonsWrapper>
      </UploadForm>
      <CancelUploadButton type="button" onClick={closeModal}>
        <StyledXIcon />
      </CancelUploadButton>
      {image && (
        <Cropper
          style={{
            height: "70vh",
            width: "70vw",
            margin: "0 auto",
          }}
          zoomTo={0.2}
          aspectRatio={1 / 1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
      )}
      {!image && <NoPhotoFrame>{t("noPhoto")}</NoPhotoFrame>}
    </Wrapper>
  );
};

export default ProfilePhotoCropper;

const Wrapper = styled.div`
  position: relative;
`;

const Description = styled.div`
  text-align: center;
  font-size: 19px;
  margin: 20px 0 30px;
`;

const UploadForm = styled.form`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

const SelectFile = styled.input`
  font-size: 14px;
  margin-right: 10px;
  position: relative;

  &::file-selector-button {
    ${smallOutlineButtonStyle}
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 390px;
`;

const UploadFileInput = styled.input`
  ${smallOutlineButtonStyle}
  background-color: ${lightTheme.colors.darkOrLight};
  color: ${lightTheme.colors.whiteOrDark};
  border: 1px solid ${lightTheme.colors.darkOrLight};
  margin-right: 0;
`;

const LoaderWrapper = styled.div`
  margin-right: 30px;
`;

const SelectedFileActions = styled.div`
  display: inline-block;
`;

const CancelUploadButton = styled.button`
  all: unset;
  position: absolute;
  top: 0;
  right: 30px;
`;

const StyledXIcon = styled(XThickIcon)`
  width: 30px;
  height: 30px;
  color: ${lightTheme.colors.darkOrLight};

  &:hover {
    opacity: 0.6;
  }
`;

const NoPhotoFrame = styled.div`
  height: 70vh;
  width: 70vw;
  margin: 0 auto;
  border: 1px solid ${lightTheme.colors.darkOrLight};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
