import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const StyledSocialMediaAuthButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
  padding: 3px 7px;
  display: flex;
`;

export const ButtonText = styled.div`
  align-self: center;
  margin-left: 6px;
`;
