export const pl = {
  translation: {
    auth: {
      createAccount: "Utwórz konto",
      createAccountDescription: "Zapewnij swoim najbliższym wieczne wspomnienia - załóż darmowy profil na Memero i dodaj swoje historie.",
      accountCreated: "Utworzono konto!",
      accountCreatedDescription:
        "Twoje konto jest już aktywne. Możesz się teraz zalogować.",
      create: "Utwórz",
      login: "Logowanie",
      loginSubtitle: "Zapewnij swoim najbliższym wieczne wspomnienia - zaloguj się na Memero i dodaj swoje historie.",
      emailAddress: "Adres e-mail",
      enterEmailAddress: "Wpisz adres email",
      password: "Hasło",
      repeatPassword: "Powtórz hasło",
      enterPassword: "Wpisz hasło",
      newPassword: "Nowe hasło",
      enterNewPassword: "Wpisz nowe hasło",
      repeatNewPassword: "Powtórz nowe hasło",
      passwordRequirements:
        "Hasło musi zawierać przynajmniej 8 znaków.",
      passwordRequirementsNotMet: "Hasło niewystarczająco silne",
      setPassword: "Ustaw hasło",
      dontRememberPassword: "Nie pamiętasz hasła?",
      rulesCheckboxLabel: "Zgoda na Regulamin i Politykę Prywatności",
      sendLinkAgain: "Wyślij link ponownie",
      logIn: "Zaloguj się",
      loginViaFb: "Logowanie przez Facebook",
      loginViaGoogle: "Logowanie przez Google",
      signInViaFb: "Rejestracja przez Facebook",
      signInViaGoogle: "Rejestracja przez Google",
      noAccountQuestion: "Nie masz konta?",
      haveAccountQuestion: "Masz już konto?",
      register: "Zarejestruj się",
      resetPassword: "Zresetuj hasło",
      send: "Wyślij",
      forgottenPasswordDescription:
        "Aby zresetować hasło podaj adres e-mail użyty przy rejestracji - wyślemy na niego link do formularza zmiany hasła.",
      resetPasswordLinkSentDescription:
        "Jeśli podałeś prawidłowy adres \n e-mail, został na niego wysłany link do formularza zmiany hasła.",
      goToSignInPage: "Przejdź do strony logowania",
      setNewPassword: "Ustaw nowe hasło",
      passwordsMustMatch: "Podane hasła muszą być takie same",
      passwordHasBeenChanged:
        "Hasło zostało zmienione. Możesz się teraz zalogować.",
      loginError: "Logowanie nieudane",
      accountCreatedWillLogIn:
        "Konto zostało utworzone. Zaraz nastąpi zalogowanie.",
    },
    validation: {
      obligatoryField: "Pole obowiązkowe",
      invalidEmail: "Niepoprawny adres email",
      maxSixChars: "Maksymalnie 6 znaków",
      invalidPhoneNumber: "Niepoprawny numer telefonu",
    },
    addProfile: {
      title: "Dodaj profil",
      saveDraft: "Zapisz wersję roboczą",
      addDeceasedPersonBasicData: "Dodaj dane zmarłego",
      addNewField: "Dodaj nowe pole:",
      add: "Dodaj",
      enterNewFieldName: "Wpisz tytuł nowego pola",
      newFieldPlaceholder: "Wpisz {{newFieldName}}",
      addFieldValidation: "Należy podać tytuł pola",
      preview: "Podgląd",
      description: "Opis:",
      addDescription: "Dodaj opis",
      addDescriptionAndPhotos: "Dodaj opis i zdjęcia",
      addDescriptionWithEllipsis: "Dodaj opis...",
      summary: "Podsumowanie",
      dateOfBirth: "Data urodzenia",
      placeOfBirth: "Miejsce urodzenia",
      dateAndPlaceOfBirth: "Data i miejsce urodzenia",
      dateOfDeath: "Data śmierci",
      placeOfDeath: "Miejsce śmierci",
      dateAndPlaceOfDeath: "Data i miejsce śmierci",
      enterParagraphTitle: "Wpisz tytuł akapitu",
      paragraphTitle: "Tytuł akapitu:",
      saveSection: "Zapisz sekcję",
      addNewSection: "Dodaj nową sekcję:",
      enterNewSectionName: "Wpisz tytuł nowej sekcji",
      sections: "Sekcje:",
      startDate: "Data początkowa",
      endDate: "Data końcowa",
      from: "(od {{startDate}})",
      to: "(do {{endDate}})",
      fromTo: "({{startDate}} - {{endDate}})",
      deleteSection: "Usuń sekcję",
      deleteField: "Usuń pole",
      profileCreated: "Utworzony profil:",
      editProfile: "Edytuj profil",
      wantGoOut: "Czy na pewno chcesz wyjść z edycji profilu?",
      wantGoOutUnsavedChangesWillBeLost:
        "Czy na pewno chcesz wyjść z edycji profilu? \n Niezapisane zmiany zostaną utracone.",
      wantGoOutProfileWillBeDeleted:
        "Czy na pewno chcesz wyjść z edycji profilu? \n Jeśli wyjdziesz bez zapisania profilu, zostanie on usunięty.",
      exit: "Wyjdź",
      descriptionWithEllipsis: "Opis...",
      addAnotherParagraph: "Dodaj kolejny akapit",
      deleteParagraphBelow: "Usuń poniższy akapit",
      profilePhoto: "Zdjęcie profilowe:",
      savePhoto: "Zapisz zdjęcie",
      wantToDeleteProfilePhotoQuestion:
        "Czy na pewno chcesz usunąć zdjęcie profilowe?",
      wantToDeletePhotoQuestion: "Czy na pewno chcesz usunąć to zdjęcie?",
      cannotAddFieldThatExists: "Nie możesz dodać pola, które już istnieje",
      cannotAddSectionThatExists: "Nie możesz dodać sekcji, któreajuż istnieje",
      quitWithoutSaving: "Wyjdź bez zapisywania",
      saveAndQuit: "Zapisz i wyjdź",
      profileHasBeenSaved: "Profil został zapisany",
      closePreview: "Zamknij podglad",
      basicDataReorderText:
        "Przeciągając poniższe kafelki, możesz zmienić kolejność dodanych przez siebie pól:",
      sectionsReorderText:
        "Przeciągając poniższe kafelki, możesz zmienić kolejność sekcji:",
      changeNewFieldsOrder: "Zmień kolejność nowych pól",
      changeSectionsOrder: "Zmień kolejność sekcji",
      addPhotos: "Dodaj zdjęcia:",
      datesRange: "Zakres dat:",
      displayPreviewOnly: "Wyświetl sam podgląd",
      uploadPhoto: "Prześlij zdjęcie",
      uploadDifferentPhoto: "Prześlij inne zdjęcie",
      currentPhoto: "Obecne zdjęcie:",
      maxTenPhotos: "Możesz dodać maksymalnie 10 zdjęć do jednej sekcji",
      uploadProfilePhotoModalDescription:
        "Wybierz plik, zaznacz obszar zdjęcia profilowego i zapisz",
      photoHasBeenSavedInProfile: "Zdjęcie zostało zapisane w profilu",
      photoHasBeenRemovedFromProfile: "Zdjęcie zostało usunięte z profilu",
      wantToDeleteFieldQuestion: "Czy na pewno chcesz usunąć to pole?",
      wantToDeleteSectionQuestion: "Czy na pewno chcesz usunąć tę sekcję?",
      wantToDeleteParagraphQuestion: "Czy na pewno chcesz usunąć ten akapit?",
      personNameValidation: "Podanie imienia jest wymagane",
    },
    dashboard: {
      title: "Pulpit",
      description:
        "Poniżej możesz przeglądać i edytować stworzone przez siebie profile.",
      nameAndSurname: "Imię i nazwisko",
      profileCreationDate: "Data utworzenia profilu",
      lastEditDate: "Ostatnia zmiana",
      noProfileCreatedYet: "Nie utworzono jeszcze żadnego profilu.",
      createProfile: "Utwórz profil",
    },
    orderPlate: {
      saveProfileAndOrderPlate: "Zapisz profil i przejdź do zamawiania",
      saveProfile: "Zapisz profil",
      link: "Zamów \n tabliczkę",
      streetAndHouseNumber: "Ulica i numer domu:",
      enterStreetAndHouseNumber: "Wpisz ulicę i numer domu",
      flatNumber: "Numer lokalu:",
      enterFlatNumber: "Wpisz number lokalu",
      city: "Miasto:",
      enterCity: "Wpisz nazwę miasta",
      zipCode: "Kod pocztowy:",
      enterZipCode: "Wpisz kod pocztowy",
      country: "Kraj:",
      enterCountry: "Wpisz nazwę kraju",
      parcelLockers: "Paczkomaty InPost 24/7",
      inPost: "InPost",
      post: "Poczta Polska",
      firstName: "Imię",
      enterFirstName: "Wpisz swoje imię",
      lastName: "Nazwisko",
      enterLastName: "Wpisz swoje nazwisko",
      przelewy24: "Przelewy24",
      paymentOnDelivery: "Płatność przy odbiorze",
      enterYourDataAndAddress: "Podaj swoje dane i adres:",
      paymentMethod: "Metoda płatności",
      iAmOrdering: "Zamawiam",
      summary: "Podsumowanie",
      toPay: "Do zapłaty:",
      submitOrder: "Złóż zamówienie",
      choosePaymentMethod: "Wybierz sposób płatności:",
      profilesChoiceDescription:
        "Zaznacz profile dla których chcesz złożyć zamówienie. \n Wybierz ilość i kolory tabliczek:",
      delivery: "Dostawa",
      yourDataAndAddress: "Twoje dane i adres",
      chooseDeliveryMethod: "Wybierz sposób dostawy:",
      chosenPickupPoint: "Wybrany punkt odbioru: ",
      choosePickupPoint: "Wybierz punkt odbioru",
      chooseDifferentPickupPoint: "Wybierz inny punkt odbioru",
      pickupPoint: "Punkt odbioru:",
      order: "Zamówienie",
      grayColor: "kolor szary",
      whiteColor: "kolor biały",
      transparentColor: "przezroczysta",
      cannotPlaceOrder:
        "Aby złożyć zamówienie, utwórz przynajmniej jeden profil.",
      discountCode: "Kod rabatowy",
      enterDiscountCode: "Wpisz kod rabatowy",
      calculateDiscount: "Nalicz rabat",
      wrongDiscountCode: "Kod niepoprawny",
      discountCodeValidation: "Należy wpisać kod rabatowy",
      usedCode: "Użyty kod:",
      paymentFailed: "Płatność nie powiodła się",
      goBackToOrder: "Wróć do zamówienia",
      email: "E-mail",
      enterEmail: "Wpisz swój adres e-mail",
      phoneNumber: "Numer telefonu",
      enterPhoneNumber: "Wpisz numer telefonu",
    },
    orders: {
      title: "Zamówienia",
      description: "Sprawdź historię i szczegóły swoich zamówień.",
      nameAndSurname: "Imię i nazwisko",
      orderDate: "Data zamówienia",
      shipmentDate: "Data wysyłki",
      content: "Zawartość",
      status: "Status",
      orderNumber: "Numer zamówienia",
      number: "nr",
      total: "Razem",
      seeOrdersList: "Zobacz listę zamówień",
      goToDashboard: "Przejdź do pulpitu",
      purchaserDataAndAddress: "Dane zamawiającego i adres",
      noOrdersNorProfilesYet:
        "Nie masz jeszcze żadnych utowrzonych profili. \n Utwórz przynajmniej jeden, aby złożyć zamówienie.",
      noOrdersYet:
        "Nie masz jeszcze zamowień. \n Kliknij poniżej, aby złożyć pierwsze.",
      placeOrder: "Złóż zamówienie",
      placeNewOrder: "Złóż nowe zamówienie",
      orderDetails: "Szczegóły zamówienia",
      successfulPayment: "Płatność zakończona sukcesem!",
      orderSuccessfullyPlaced: "Zamówienie zostało złożone!",
      showQrCode: "Wyświetl kod QR",
      downloadInvoice: "Pobierz fakturę"
    },
    settings: {
      title: "Ustawienia",
      firstNameHasBeenUpdated: "Imię zostało zaktualizowane",
      lastNameHasBeenUpdated: "Nazwisko zostało zaktualizowane",
    },
    publicProfile: {
      description: "Chcesz stworzyć taki profil sobie lub swoim bliskim?",
      learnMoreAt: "Dowiedz się więcej na:",
      memeroPl: "memero.pl",
      allRightsReserved2022: "2022 Memero, All rights reserved",
      contact: "Kontakt:",
    },
    noPhoto: "Brak zdjęcia",
    errorOccurred: "Wystąpił błąd",
    or: "Lub",
    memero: "Memero",
    biography: "Życiorys",
    parcelLockers: "Paczkomaty",
    textEditor: "Edytor teksu",
    somethingWentWrong: "Coś poszło nie tak. Skontaktuj się z naszym serwisem.",
    logOut: "Wyloguj się",
    scanAndCheckExampleProfile: "Zeskanuj kod telefonem i zobacz przykładowy profil:",
    seeYourCreatedProfile: "Zeskanuj kod telefonem i zobacz profil!",
    exitWithoutSaving: "Wyjdź bez zapisywania",
    goBack: "Wstecz",
    continue: "Dalej",
    cancel: "Anuluj",
    yes: "Tak",
    fieldHasBeenUpdated: "Pole zostało zauktualizowane",
    termsAndConditions: "Regulamin",
    privacyPolicy: "Polityka prywatności",
    contact: "Kontakt",
    save: "Zapisz",
    edit: "Edytuj",
    sessionHasEnded: "Sesja wygasła",
    loginError: "Logowanie nieudane",
    mainPage: "Strona główna"
  },
};
