import { useWatch, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SectionContent,
  SectionMainLine,
  SummaryDescription,
} from "global-styles/commonOrderStyles";

import { OrderFormData } from "types/Order";

const Address = () => {
  const { t } = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();

  const shipmentAddressWatcher = useWatch({
    control: orderPlateFormMethods.control,
    name: "shippingAddress",
  });

  const isAnyAddressFieldFilledIn =
    shipmentAddressWatcher &&
    Object.values(shipmentAddressWatcher).some((value) => value !== "");

  return (
    <Section>
      <Header>
        <SummaryDescription>
          {t("orderPlate.yourDataAndAddress")}
        </SummaryDescription>
      </Header>
      <SectionContent>
        {isAnyAddressFieldFilledIn && (
          <>
            <SectionMainLine>{`${orderPlateFormMethods.getValues(
              "shippingAddress.street"
            )}${
              orderPlateFormMethods.getValues("shippingAddress.flatNumber") !==
              ""
                ? "/"
                : ""
            }${orderPlateFormMethods.getValues(
              "shippingAddress.flatNumber"
            )}`}</SectionMainLine>
            <SectionMainLine>{`${orderPlateFormMethods.getValues(
              "shippingAddress.zipCode"
            )} ${orderPlateFormMethods.getValues("shippingAddress.city")}${
              orderPlateFormMethods.getValues("shippingAddress.country") !==
                "" &&
              (orderPlateFormMethods.getValues("shippingAddress.city") !== "" ||
                orderPlateFormMethods.getValues("shippingAddress.zipCode") !==
                  "")
                ? ", "
                : ""
            }${orderPlateFormMethods.getValues(
              "shippingAddress.country"
            )}`}</SectionMainLine>
          </>
        )}
        {!isAnyAddressFieldFilledIn && <SectionMainLine>...</SectionMainLine>}
      </SectionContent>
    </Section>
  );
};

export default Address;
