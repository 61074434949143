import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { StepsRange } from "types/Profile";

interface Props {
  thisStepIndex: StepsRange;
  activeStepIndex: StepsRange;
  label: string;
  content?: string[];
  isStepActive?: boolean;
  isLastStep?: boolean;
}

const SingleStep = ({
  thisStepIndex,
  activeStepIndex,
  label,
  content,
  isLastStep,
}: Props) => {
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      setContainerHeight(divRef.current.clientHeight);
    }
  }, [content]);

  return (
    <StepContainer ref={divRef}>
      <div>
        <TestCircle
          verticalLineHeight={containerHeight}
          activeStepColors={thisStepIndex === activeStepIndex}
          finishedStepColors={thisStepIndex < activeStepIndex}
          noVerticalLineAfter={isLastStep}
        >
          {thisStepIndex}
        </TestCircle>
      </div>
      <div>
        <Title>{label}</Title>
        {content && (
          <List>
            {content.map((section, index) => (
              <Caption key={index}>{section}</Caption>
            ))}
          </List>
        )}
      </div>
    </StepContainer>
  );
};

export default SingleStep;

const StepContainer = styled.div`
  position: relative;
  min-height: 1em;
  color: ${({ theme }) => theme.colors.grayOrLightGray};

  & > div:first-child {
    position: static;
    height: 0;
  }

  & > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
  }
`;

interface CircleProps {
  verticalLineHeight: number;
  activeStepColors: boolean;
  finishedStepColors: boolean;
  noVerticalLineAfter?: boolean;
}

const TestCircle = styled.div<CircleProps>`
  background: ${(p) =>
    p.activeStepColors
      ? p.theme.colors.whiteOrDark
      : p.finishedStepColors
      ? p.theme.colors.darkOrLight
      : p.theme.colors.grayOrLightGray};
  position: relative;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 100%;
  color: ${(p) =>
    p.activeStepColors
      ? p.theme.colors.darkOrLight
      : p.theme.colors.lightOrDark};
  text-align: center;
  box-shadow: 0 0 0 1px
    ${(p) =>
      p.activeStepColors
        ? p.theme.colors.darkOrLight
        : p.finishedStepColors
        ? p.theme.colors.darkOrLight
        : p.theme.colors.grayOrLightGray};
  font-size: 16px;

  &:after {
    content: " ";
    position: absolute;
    display: block;
    top: 33px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: ${(p) => `${p.verticalLineHeight - 18}px`};
    width: 1px;
    background-color: ${({ theme }) => theme.colors.midGrayOrGray};
    z-index: 0;
  }

  &:after {
    ${(p) => p.noVerticalLineAfter && "display: none"}
  }
`;

const Title = styled.div`
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
`;

const List = styled.ul`
  padding-left: 0;
  margin-top: 5px;
  margin-left: 13px;
`;

const Caption = styled.li`
  font-size: 14px;
  line-height: 150%;
`;
