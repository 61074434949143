import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SectionContent,
  SectionMainLine,
  SummaryDescription,
  SummaryPrice,
} from "global-styles/commonOrderStyles";

import { DELIVERY_METHODS_API_NAMES } from "utils/consts";
import { OrderCheckoutSummary, OrderFormData } from "types/Order";

interface Props {
  orderCheckoutSummary: OrderCheckoutSummary | undefined;
}

const Shipment = ({ orderCheckoutSummary }: Props) => {
  const { t } = useTranslation();

  const orderPlateFormMethods = useFormContext<OrderFormData>();

  return (
    <Section>
      <Header>
        <SummaryDescription>{t("orderPlate.delivery")}</SummaryDescription>
        <SummaryPrice>{`${orderCheckoutSummary?.delivery.price} ${orderCheckoutSummary?.delivery.currency}`}</SummaryPrice>
      </Header>
      <SectionContent>
        {orderCheckoutSummary?.delivery.name ===
          DELIVERY_METHODS_API_NAMES.POST && (
          <>
            <SectionMainLine>{t("orderPlate.post")}</SectionMainLine>
          </>
        )}
        {orderCheckoutSummary?.delivery.name ===
          DELIVERY_METHODS_API_NAMES.IN_POST &&
          orderPlateFormMethods.getValues("shipment.inPostAddress") !== "" && (
            <>
              <SectionMainLine>{t("orderPlate.inPost")}</SectionMainLine>
              <SectionMainLine>{`${t(
                "orderPlate.pickupPoint"
              )} ${orderPlateFormMethods.getValues(
                "shipment.inPostAddress"
              )}`}</SectionMainLine>
            </>
          )}
        {orderCheckoutSummary?.delivery.name === "" && (
          <SectionMainLine>...</SectionMainLine>
        )}
      </SectionContent>
    </Section>
  );
};

export default Shipment;
