import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export interface AddSectionFormValues {
  newFieldName: string;
}

export const useAddSectionFormController = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      newFieldName: Yup.string()
        .trim()
        .required(t("addProfile.addFieldValidation")),
    });
  }, [t]);

  return useForm<AddSectionFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
