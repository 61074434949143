import { AnyAction } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import type { RootState, AppDispatch } from "store/store";

export const useAppDispatch: () => ThunkDispatch<
  AppDispatch,
  undefined,
  AnyAction
> = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
