import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { PersonBasicData } from "types/Profile";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

export interface AddBasicDataFormValues {
  personFields: Pick<PersonBasicData, "content">[];
}

export const useAddBasicDataFormController = () => {
  const { profileGeneralData } = useProfileDataContext();

  const defaultFormValues = useMemo(() => {
    if (profileGeneralData) {
      return {
        personFields: profileGeneralData.fields.map((field) => {
          return {
            content: field.content,
          };
        }),
      };
    }
  }, [profileGeneralData]);

  return useForm<AddBasicDataFormValues>({
    defaultValues: defaultFormValues,
    mode: "onSubmit",
  });
};
