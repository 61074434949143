import { useTranslation } from "react-i18next";

import PaymentRadioButtonsRHF from "components/order-plate/order-data-forms/PaymentRadioButtonsRHF";

import { Description, SubformWrapper } from "global-styles/commonOrderStyles";

const PaymentMethodsForm = () => {
  const { t } = useTranslation();

  return (
    <SubformWrapper>
      <Description>{t("orderPlate.choosePaymentMethod")}</Description>
      <PaymentRadioButtonsRHF />
    </SubformWrapper>
  );
};

export default PaymentMethodsForm;
