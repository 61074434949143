import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "global-styles/commonOrderStyles";
import {
  darkThemeScrollbarStyle,
  firefoxLightColorBackgroundScrollbar,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import PaymentMethodsForm from "components/order-plate/order-data-forms/PaymentMethodsForm";
import ProfilesFieldArrayForm from "components/order-plate/order-data-forms/ProfilesFieldArrayForm";
import ShipmentDataForm from "components/order-plate/order-data-forms/ShipmentDataForm";
import ShipmentMethodsForm from "components/order-plate/order-data-forms/ShipmentMethodsForm";

import { OrderPricing } from "types/Order";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  orderPricing: OrderPricing | undefined;
}

const OrderPlateForm = ({ orderPricing }: Props) => {
  const { detect } = require("detect-browser");
  const browser = detect();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  return (
    <OrderFormWrapper
      isDarkTheme={isDarkTheme}
      isFirefox={browser && browser.name === "firefox"}
    >
      <Title>{t("orderPlate.submitOrder")}</Title>
      <ProfilesFieldArrayForm />
      <ShipmentDataForm />
      <ShipmentMethodsForm orderPricing={orderPricing} />
      <PaymentMethodsForm />
    </OrderFormWrapper>
  );
};

export default OrderPlateForm;

interface OrderFormWrapperProps {
  isDarkTheme: boolean;
  isFirefox: boolean;
}

const OrderFormWrapper = styled.div<OrderFormWrapperProps>`
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px 5px 5px 12px;
  flex-basis: 67%;
  height: 100%;
  padding: 32px 32px 10px 32px;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight};`}
  ${(p) =>
    p.isDarkTheme
      ? darkThemeScrollbarStyle
      : p.isFirefox
      ? firefoxLightColorBackgroundScrollbar
      : lightThemeScrollbarStyle}
`;
