import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";
import { Section } from "global-styles/commonOrderStyles";

import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";
import OutlineButton from "components/inputs/OutlineButton";
import PlacedOrderAddress from "components/orders/single-order-elements/PlacedOrderAddress";
import PlacedOrderItems from "components/orders/single-order-elements/PlacedOrderItems";
import PlacedOrderPayment from "components/orders/single-order-elements/PlacedOrderPayment";
import PlacedOrderShipment from "components/orders/single-order-elements/PlacedOrderShipment";

import { DASHBOARD_PATH, ORDERS_PATH, USER_PATH } from "utils/routes";
import { PAYMENT_METHODS_API_NAMES_KEYS } from "utils/consts";
import {PlacedOrderFullData, PlacedOrderSummary} from "types/Order";
import { renderErrorToast } from "utils/toasts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  isViewAfterOrderFinilizing?: boolean;
}

const SingleOrderSummaryContent = ({ isViewAfterOrderFinilizing }: Props) => {
  const [orderFullData, setOrderFullData] = useState<PlacedOrderFullData>();

  const params = useParams();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  useEffect(
    function fetchExitstingProfiles() {
      httpService
        .get(`api/ecommerce/order/v1/${params.orderNumber}/`)
        .then((response) => {
          setOrderFullData(response.data);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    },
    [params.orderNumber, t]
  );

  const onDownloadButtonClick = (details: PlacedOrderSummary) => {
    const invoiceUrl = details.invoice;
    if(!invoiceUrl){ return; }
    fetch(invoiceUrl).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `memero-faktura-${details.orderNumber}.pdf`;
        alink.click();
      })
    })
  }
  return (
    <Container isDarkTheme={isDarkTheme}>
      {orderFullData && (
        <>
          <Title>
            {isViewAfterOrderFinilizing &&
              orderFullData.payment.method ===
                PAYMENT_METHODS_API_NAMES_KEYS.PRZELEWY_24 &&
              t("orders.successfulPayment")}
            {isViewAfterOrderFinilizing &&
              orderFullData.payment.method ===
                PAYMENT_METHODS_API_NAMES_KEYS.PAYMENT_ON_DELIVERY &&
              t("orders.orderSuccessfullyPlaced")}
            {!isViewAfterOrderFinilizing && t("orders.orderDetails")}
          </Title>

          <OrderSummaryWrapper isDarkTheme={isDarkTheme}>
            <PlacedOrderItems orderFullData={orderFullData} />
            <PlacedOrderAddress orderFullData={orderFullData} />
            <PlacedOrderShipment orderFullData={orderFullData} />
            <PlacedOrderPayment orderFullData={orderFullData} />
            <Section>
              <TotalPriceLine>
                <div>{t("orders.total")}</div>
                <div>{`${orderFullData?.details.total.price} ${orderFullData?.details.total.currency}`}</div>
              </TotalPriceLine>
            </Section>
            <ButtonsWrapper>
              {orderFullData.details.invoice &&
              <OutlineButton onClick={() => onDownloadButtonClick(orderFullData.details)}>{t("orders.downloadInvoice")}</OutlineButton>
              }
              <Link to={`${USER_PATH}${ORDERS_PATH}`}>
                <OutlineButton>{t("orders.seeOrdersList")}</OutlineButton>
              </Link>
              <Link to={`${USER_PATH}${DASHBOARD_PATH}`}>
                <MainButton>{t("orders.goToDashboard")}</MainButton>
              </Link>
            </ButtonsWrapper>
          </OrderSummaryWrapper>
        </>
      )}
    </Container>
  );
};

export default SingleOrderSummaryContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;

const OrderSummaryWrapper = styled.div<{ isDarkTheme: boolean }>`
  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  background: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 12px;
  padding: 24px;
  min-width: 310px;
  max-width: 600px;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const TotalPriceLine = styled.div`
  font-weight: 700;
  font-size: 19px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  display: flex;
  justify-content: space-between;
  line-height: 2;
  margin-top: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
`;
