export const lightTheme = {
  colors: {
    // Colors which change depending on the chosen theme:
    darkOrLight: "#1D2022",
    darkOpacityOrWhiteOpacity: "rgba(29, 32, 34, 0.5)",
    grayOrLightGray: "#737373",
    midGrayOrGray: "rgba(0, 0, 0, 0.25)",
    lightOrDark: "#E5E1DE",
    darkWhiteOrDark: "#f8f7f7",
    whiteOpacityOrDarkOpacity: "#f2f0ef",
    whiteOrDark: "#fff",

    // Colors which are the same in both themes:
    darkGray: "#5E6569",
    moderateGray: "#8E9298",
    extraLightGray: "rgba(161, 165, 170, 0.2)",
    lightGray: "#c9c9c9",
    actionBlue: "#008EDF",
    inputWrapperGray: "rgb(206, 212, 218)",
    inputFocusBlue: "#8fbbfb",
    disbaledGray: "#e9ecef",
    validationErrorRed: "rgb(220, 53, 69)",
    successGreen: "#5F9A43",
  },
};

export const darkTheme = {
  colors: {
    // Colors which change depending on the chosen theme:
    darkOrLight: "#E5E1DE",
    darkOpacityOrWhiteOpacity: "#f2f0ef",
    grayOrLightGray: "#c9c9c9",
    midGrayOrGray: "#737373",
    lightOrDark: "#1D2022",
    darkWhiteOrDark: "#1D2022",
    whiteOpacityOrDarkOpacity: "rgba(29, 32, 34, 0.5)",
    whiteOrDark: "#1D2022",

    // Colors which are the same in both themes:
    darkGray: "#5E6569",
    moderateGray: "#8E9298",
    lightGray: "#c9c9c9",
    extraLightGray: "rgba(161, 165, 170, 0.2)",
    actionBlue: "#008EDF",
    inputWrapperGray: "rgb(206, 212, 218)",
    inputFocusBlue: "#8fbbfb",
    disbaledGray: "#e9ecef",
    validationErrorRed: "rgb(220, 53, 69)",
    successGreen: "#5F9A43",
  },
};
