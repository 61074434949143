import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputRHF from "components/inputs/InputRHF";
import MainButton from "components/inputs/MainButton";

import { AddSectionFormValues } from "components/profile/forms/AddSectionFormController";

const AddSectionForm = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<AddSectionFormValues>();

  return (
    <AddFieldWrapper>
      <InputRHF
        formFieldName="newFieldName"
        control={control}
        label={t("addProfile.addNewSection")}
        placeholder={t("addProfile.enterNewSectionName")}
        error={errors.newFieldName?.message}
        noMarginTop
      />
      <AddButton type="submit">{t("addProfile.add")}</AddButton>
    </AddFieldWrapper>
  );
};

export default AddSectionForm;

const AddFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddButton = styled(MainButton)`
  margin-top: 28px !important;
  margin-left: 10px;
`;
