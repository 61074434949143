import {useCallback} from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import {FormProvider} from "react-hook-form";
import {useAppDispatch} from "store/storeHooks";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import LoginForm from "components/auth/forms/LoginForm";

import {AUTH_PATH, SIGN_IN_PATH} from "utils/routes";
import {logIn} from "store/auth/AuthSlice";
import {LoginFormValues, useLoginFormController,} from "components/auth/forms/LoginFormController";
import {LoginFacebook} from "./LoginFacebook";
import {LoginGoogle} from "./LoginGoogle";

const Login = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const {t} = useTranslation();

  const loginFormMethods = useLoginFormController();

  const onSignIn = () => {
    navigate(`${AUTH_PATH}${SIGN_IN_PATH}`, {replace: true});
  };

  const handleLogIn = useCallback(
    (values: LoginFormValues) => {
      dispatch(
        logIn({
          email: values.userEmail,
          password: values.password,
        })
      );
    },
    [dispatch]
  );

  const FACEBOOK_APP_ID: string | undefined = process.env.REACT_APP_FACEBOOK_APP_ID;


  return (
    <AuthContentWrapper>
      <AuthTitle>{t("auth.login")}</AuthTitle>
      <AuthSubtitle>{t("auth.loginSubtitle")}</AuthSubtitle>
      <FormProvider {...loginFormMethods}>
        <Form onSubmit={loginFormMethods.handleSubmit(handleLogIn)} noValidate>
          <LoginForm/>
        </Form>
      </FormProvider>
        <>
          <HorizontalLineWrapper>
            <span>{t("or")}</span>
          </HorizontalLineWrapper>
          <SocialMediaAuthButtonsWrapper>
            <LoginFacebook></LoginFacebook>
            <LoginGoogle></LoginGoogle>
          </SocialMediaAuthButtonsWrapper>
        </>
      <RegistrationWrapper>
        <NoAccount>{t("auth.noAccountQuestion")}</NoAccount>
        <Register onClick={onSignIn}>{t("auth.register")}</Register>
      </RegistrationWrapper>
    </AuthContentWrapper>
  );
};

export default Login;

const AuthContentWrapper = styled.div`
  margin: 0 24px;
  color: ${({theme}) => theme.colors.darkOrLight};
`;

const AuthTitle = styled.div`
  margin: 30px 0 30px;
  font-family: "Jost", serif;
  font-weight: 500;
  font-size: 34px;
  color: ${({theme}) => theme.colors.darkOrLight};
  line-height: 1.2;
`;

const AuthSubtitle = styled.div`
  margin-top: 20px;
  margin-bottom: 66px;
  font-family: "Jost", serif;
  font-weight: 400;
  font-size: 20px;
  color: ${({theme}) => theme.colors.darkOrLight};
  line-height: 1.1;
`;

const HorizontalLineWrapper = styled.div`
  display: block;
  margin-top: 45px;
  margin-bottom: 40px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  & > span {
    position: relative;
    display: inline-block;
  }

  & > span:before,
  & > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: ${({theme}) => theme.colors.grayOrLightGray};
  }

  & > span:before {
    right: 100%;
    margin-right: 15px;
  }

  & > span:after {
    left: 100%;
    margin-left: 15px;
  }
`;

const SocialMediaAuthButtonsWrapper = styled.div`
  margin: 45px 0;
`;

const RegistrationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
const NoAccount = styled.div`
  font-size: 16px;
`;
const Register = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({theme}) => theme.colors.actionBlue};
  transition: 0.5s;

  &:hover {
    color: ${({theme}) => theme.colors.darkOrLight};
  }
`;
