import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export interface SettingsRegularFieldFormValues {
  field: string;
}

export const useSettingsRegularFieldFormController = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      field: Yup.string().trim().required(t("validation.obligatoryField")),
    });
  }, [t]);

  return useForm<SettingsRegularFieldFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
