import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { USER_PATH } from "utils/routes";
import { NavbarRoute } from "types/Others";

const NavbarLink = ({ path, icon, text }: NavbarRoute) => {
  return (
    <StyledNavLink to={`${USER_PATH}${path}`}>
      {({ isActive }) => (
        <LinkContent isActive={isActive}>
          {icon}
          <LinkText>{text}</LinkText>
        </LinkContent>
      )}
    </StyledNavLink>
  );
};

export default NavbarLink;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkOrLight};

  &:hover {
    color: ${({ theme }) => theme.colors.lightOrDark};
  }
`;

const LinkContent = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 26px;

  ${(p) => p.isActive && `background-color: ${p.theme.colors.darkOrLight};`}
  ${(p) => p.isActive && `color: ${p.theme.colors.lightOrDark};`}

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkOrLight};
  }
`;

const LinkText = styled.div`
  margin-left: 8px;

  @media (max-width: 1560px) {
    white-space: pre-line;
  }
`;
