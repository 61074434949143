import styled from "styled-components";
import { useTranslation } from "react-i18next";

import DashboardTable from "components/dashboard/DashboardTable";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";

const DashboardContent = () => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  return (
    <Container isDarkTheme={isDarkTheme}>
      <Title>{t("dashboard.title")}</Title>
      <Description>{t("dashboard.description")}</Description>
      <DashboardTable />
    </Container>
  );
};

export default DashboardContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
  @media (max-width: 720px) {
    display: grid;
    height: 95vh;
    grid-template-rows: 1fr 1fr 8fr
  }
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 24px;
`;
