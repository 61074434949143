import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PASSWORD_REGEXP from "../PasswordRequirements";

export interface SignInFormValues {
  userEmail: string;
  password: string;
  passwordConfirmation: string;
  rules: boolean;
}

export const useSignInFormController = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      userEmail: Yup.string()
        .trim()
        .required(t("validation.obligatoryField"))
        .email(t("validation.invalidEmail")),
      password: Yup.string()
        .required(t("validation.obligatoryField"))
        .matches(
          PASSWORD_REGEXP,
          t("auth.passwordRequirementsNotMet")
        ),
      passwordConfirmation: Yup.string()
        .required(t("validation.obligatoryField"))
        .oneOf([Yup.ref("password"), null], t("auth.passwordsMustMatch")),
      rules: Yup.boolean()
        .required(t("validation.obligatoryField"))
        .oneOf([true], t("validation.obligatoryField")),
    });
  }, [t]);

  return useForm<SignInFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
