import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS } from "utils/consts";
import { PersonBasicData } from "types/Profile";

interface Props {
  fields: PersonBasicData[];
}

const DateAndPlaceOfBirthField = ({ fields }: Props) => {
  const { t } = useTranslation();

  const dateOfBirth = fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.DATE_OF_BIRTH
  )?.content;

  const placeOfBirth = fields.find(
    (field) =>
      field.fieldName.toLowerCase() ===
      BASIC_DATA_SPIECIALLY_POSITIONED_FIELDS.PLACE_OF_BIRTH
  )?.content;

  if (dateOfBirth && placeOfBirth) {
    return (
      <>
        <FieldName>{t("addProfile.dateAndPlaceOfBirth")}</FieldName>
        <Data>{`${dateOfBirth}, ${placeOfBirth}`}</Data>
      </>
    );
  } else if (!dateOfBirth && placeOfBirth) {
    return (
      <>
        <FieldName>{t("addProfile.placeOfBirth")}</FieldName>
        <Data>{placeOfBirth}</Data>
      </>
    );
  } else if (dateOfBirth && !placeOfBirth) {
    return (
      <>
        <FieldName>{t("addProfile.dateOfBirth")}</FieldName>
        <Data>{dateOfBirth}</Data>
      </>
    );
  } else return null;
};

export default DateAndPlaceOfBirthField;

const FieldName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.grayOrLightGray};
`;

const Data = styled.div`
  font-size: 19px;
  line-height: 150%;
`;
