import styled from "styled-components";
import Switch from "react-switch";

import { lightTheme } from "global-styles/colorThemes";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as MoonIcon } from "assets/moon-icon.svg";
import { ReactComponent as SunIcon } from "assets/sun-icon.svg";

interface Props {
  isPublicProfile?: boolean;
}

const ThemeSwitch = ({ isPublicProfile }: Props) => {
  const { isDarkTheme, setIsDarkTheme, saveUserTheme } = useColorThemeContext();

  const enableDarkTheme = (isDarkOn: boolean) => {
    if (isPublicProfile) {
      setIsDarkTheme(isDarkOn);
    } else {
      saveUserTheme(isDarkOn);
    }
  };

  return (
    <Switch
      checked={isDarkTheme}
      onChange={enableDarkTheme}
      onColor="#8E9298"
      onHandleColor="#5E6569"
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
      height={15}
      width={40}
      className="react-switch"
      id="material-switch"
      uncheckedHandleIcon={
        <IconWrapper>
          <StyledSunIcon />
        </IconWrapper>
      }
      checkedHandleIcon={
        <IconWrapper>
          <StyledMoonIcon />
        </IconWrapper>
      }
    />
  );
};

export default ThemeSwitch;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSunIcon = styled(SunIcon)`
  width: 16px;
  height: 16px;
  margin-top: 3px;
  color: ${lightTheme.colors.darkOrLight};
`;

const StyledMoonIcon = styled(MoonIcon)`
  width: 16px;
  height: 16px;
  margin-top: 3px;
  color: ${lightTheme.colors.whiteOrDark};
`;
