import { SyntheticEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Control, Controller } from "react-hook-form";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  DefaultEditorObjectType,
  defaultEditorParagraphObject,
} from "components/inputs/utils/textEditorUtils";

interface Props {
  control: Control<any>;
  formFieldName: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  onFocus?: () => void;
}

const TextEditorRHF = ({
  control,
  formFieldName,
  label,
  placeholder,
  defaultValue,
  error,
  onFocus,
}: Props) => {
  const [editorState, setEditorState] = useState<EditorState>();
  const [isPaste, setIsPaste] = useState<boolean>(false);
  const [plainPastedText, setPlainPastedText] =
    useState<DefaultEditorObjectType>();

  const { t } = useTranslation();

  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(
    function convertTextToPlainOnPaste() {
      if (editorRef && editorRef.current) {
        editorRef.current!.addEventListener("paste", function (e) {
          setIsPaste(true);
          const editorParagraphObjectToPaste = {
            ...defaultEditorParagraphObject,
          };
          if (e.clipboardData && editorState) {
            editorParagraphObjectToPaste.text =
              e.clipboardData.getData("text/plain");

            editorParagraphObjectToPaste.key = Math.random()
              .toString(36)
              .slice(2, 7);

            const editorStateObject = JSON.parse(
              JSON.stringify(
                convertToRaw(editorState.getCurrentContent()),
                null,
                2
              )
            );

            editorStateObject.blocks = [
              ...editorStateObject.blocks,
              editorParagraphObjectToPaste,
            ];

            setPlainPastedText(editorStateObject);
          }
        });

        setTimeout(() => {
          setIsPaste(false);
        }, 10);
      }
    },
    [editorRef, editorState]
  );

  useEffect(
    function setEditorDefaultContentIfProvided() {
      if (defaultValue) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(defaultValue))
          )
        );
      } else {
        setEditorState(EditorState.createEmpty());
      }
    },
    [defaultValue]
  );

  const updateEditorStateOnPaste = () => {
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(JSON.stringify(plainPastedText)))
      )
    );
  };

  return (
    <>
      <Controller
        control={control}
        name={formFieldName}
        render={({ field: { onChange } }) => (
          <EditorWrapper ref={editorRef}>
            <Label>{label}</Label>
            <Editor
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorState={editorState}
              onFocus={(e: SyntheticEvent) => {
                if (onFocus && e.type === "focus") {
                  onFocus();
                }
              }}
              onEditorStateChange={(state) => {
                if (isPaste) {
                  updateEditorStateOnPaste();

                  onChange(
                    JSON.stringify(
                      convertToRaw(
                        EditorState.createWithContent(
                          convertFromRaw(
                            JSON.parse(JSON.stringify(plainPastedText))
                          )
                        ).getCurrentContent()
                      ),
                      null,
                      2
                    )
                  );
                } else if (!isPaste) {
                  setEditorState(state);

                  onChange(
                    JSON.stringify(
                      convertToRaw(state.getCurrentContent()),
                      null,
                      2
                    )
                  );
                }
              }}
              editorStyle={{
                border: "1px solid darkgray",
                backgroundColor: "white",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "5px",
                color: "#1D2022",
              }}
              placeholder={
                placeholder
                  ? placeholder
                  : t("addProfile.descriptionWithEllipsis")
              }
              toolbar={{
                options: ["inline"],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["bold", "italic", "underline"],
                },
              }}
            />
            {error && <Error>*{error}</Error>}
          </EditorWrapper>
        )}
      />
    </>
  );
};

export default TextEditorRHF;

const EditorWrapper = styled.div`
  position: relative;
`;

const Error = styled.div`
  position: absolute;
  left: 8px;
  bottom: -18px;
  color: ${({ theme }) => theme.colors.validationErrorRed};
  font-size: 12px;
`;

const Label = styled.div`
  margin: 15px 0;
`;
