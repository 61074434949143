import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NavbarLink from "components/navbar/NavbarLink";

import {
  DASHBOARD_PATH,
  ORDER_PLATE_PATH,
  ORDERS_PATH,
  SETTINGS_PATH,
  USER_PATH,
} from "utils/routes";
import { NavbarRoute } from "types/Others";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as HomeIcon } from "assets/home-icon.svg";
import { ReactComponent as GearIcon } from "assets/gear-icon.svg";
import { ReactComponent as QrCodeIcon } from "assets/qr-code-icon.svg";
import { ReactComponent as BagIcon } from "assets/bag-icon.svg";
import {AppMode} from "../../types/AppMode";

const Navbar = ({appMode}: { appMode: AppMode}) => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const onPremRoutes: NavbarRoute[] = [
    {
      path: DASHBOARD_PATH,
      icon: <StyledHomeIcon />,
      text: t("dashboard.title"),
    },
    { path: SETTINGS_PATH, icon: <StyledBagIcon />, text: t("settings.title") },
  ];

  const standaloneRoutes: NavbarRoute[] = [
    {
      path: DASHBOARD_PATH,
      icon: <StyledHomeIcon />,
      text: t("dashboard.title"),
    },
    {
      path: ORDER_PLATE_PATH,
      icon: <StyledGearIcon />,
      text: t("orderPlate.link"),
    },
    { path: ORDERS_PATH, icon: <StyledQrCodeIcon />, text: t("orders.title") },
    { path: SETTINGS_PATH, icon: <StyledBagIcon />, text: t("settings.title") },
  ]

  const navbarRoutes: NavbarRoute[] = appMode === "standalone" ? standaloneRoutes : onPremRoutes;

  return (
    <NavbarContainer isDarkTheme={isDarkTheme}>
      <AppTitleWrapper>
        <StyledLink to={`${USER_PATH}${DASHBOARD_PATH}`}>
          <AppTitle>{t("memero")}</AppTitle>
        </StyledLink>
      </AppTitleWrapper>
      <div>
        {navbarRoutes.map((route, index) => (
          <NavbarLink key={index} {...route} />
        ))}
      </div>
    </NavbarContainer>
  );
};

export default Navbar;

const NavbarContainer = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 14.5%;
  max-width: 195px;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}

  @media (max-width: 720px) {
    display: grid;
    max-width: 100%;

  }
`;

const AppTitleWrapper = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  padding: 24px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  transition: 0.5s;

  &:hover {
    color: inherit;
    opacity: 0.6;
  }
`;

const AppTitle = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
`;

const iconStyle = css`
  width: 20px;
  height: 21px;
  margin-bottom: 1px;
`;

const StyledHomeIcon = styled(HomeIcon)`
  ${iconStyle}
`;
const StyledGearIcon = styled(GearIcon)`
  ${iconStyle}
`;
const StyledQrCodeIcon = styled(QrCodeIcon)`
  ${iconStyle}
`;
const StyledBagIcon = styled(BagIcon)`
  ${iconStyle}
`;
