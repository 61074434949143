import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import OrdersTableRow from "components/orders/OrdersTableRow";
import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";

import {
  EDIT_PROFILE_PATH,
  NEW_PROFILE_PATH,
  ORDER_PLATE_PATH,
  SINGLE_ORDER_PATH,
  USER_PATH,
} from "utils/routes";
import { PlacedOrderSummary } from "types/Order";
import { ProfileGeneralData } from "types/Profile";
import { renderErrorToast } from "utils/toasts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";

const OrdersTable = () => {
  const [existingProfiles, setExistingProfiles] = useState<
    ProfileGeneralData[] | undefined
  >();
  const [existingOrders, setExistingOrders] = useState<
    PlacedOrderSummary[] | undefined
  >();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const fetchExitstingProfiles = useCallback(() => {
    httpService
      .get(`api/passedaway/person/v1/`)
      .then((response) => {
        setExistingProfiles(response.data);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  }, [t]);

  useEffect(
    function fetchExitstingOrders() {
      httpService
        .get(`api/ecommerce/order/v1/`)
        .then((response) => {
          setExistingOrders(response.data);
          if (response.data.length === 0) {
            fetchExitstingProfiles();
          }
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    },
    [t, fetchExitstingProfiles]
  );

  return (
    <>
      {existingOrders && (
        <>
          {existingOrders.length > 0 && (
            <>
              <Description>{t("orders.description")}</Description>
              <TableWrapper isDarkTheme={isDarkTheme}>
                <TableHeaders>
                  <TableHeader>{t("orders.orderNumber")}</TableHeader>
                  <TableHeader>{t("orders.orderDate")}</TableHeader>
                  <TableHeader>{t("orders.shipmentDate")}</TableHeader>
                  <TableHeader>{t("orders.status")}</TableHeader>
                </TableHeaders>
                <TableContent isDarkTheme={isDarkTheme}>
                  {existingOrders.map((order, index) => (
                    <StyledLink
                      key={index}
                      to={`${USER_PATH}${SINGLE_ORDER_PATH}/${order.orderNumber}`}
                    >
                      <OrdersTableRow {...order} />
                    </StyledLink>
                  ))}
                </TableContent>
              </TableWrapper>
              <Link to={`${USER_PATH}${ORDER_PLATE_PATH}`}>
                <MainButton expandWidth>
                  <StyledPlusIcon />
                  {t("orders.placeNewOrder")}
                </MainButton>
              </Link>
            </>
          )}
          {existingOrders.length === 0 && (
            <NoOrdersYetWrapper>
              {existingProfiles && (
                <NoOrdersYet>
                  {existingProfiles.length === 0 && (
                    <>
                      <Description>
                        {t("orders.noOrdersNorProfilesYet")}
                      </Description>
                      <Link
                        to={`${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}`}
                      >
                        <MainButton expandWidth>
                          <StyledPlusIcon />
                          {t("dashboard.createProfile")}
                        </MainButton>
                      </Link>
                    </>
                  )}
                  {existingProfiles.length > 0 && (
                    <>
                      <Description>{t("orders.noOrdersYet")}</Description>
                      <Link to={`${USER_PATH}${ORDER_PLATE_PATH}`}>
                        <MainButton expandWidth>
                          <StyledPlusIcon />
                          {t("orders.placeOrder")}
                        </MainButton>
                      </Link>
                    </>
                  )}
                </NoOrdersYet>
              )}
            </NoOrdersYetWrapper>
          )}
        </>
      )}
    </>
  );
};

export default OrdersTable;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 24px;
  white-space: pre-line;
`;

const TableWrapper = styled.div<{ isDarkTheme: boolean }>`
  max-height: 82%;
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const TableHeaders = styled.div`
  height: 50px;
  border-bottom: 2px solid
    ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  align-items: center;
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;

  &:nth-child(1) {
    flex-basis: 35%;
    @media only screen and (max-width: 720px) {
      max-width: 100px;
      text-overflow: ellipsis;
    }

  }
  &:nth-child(2) {
    flex-basis: 21%;
    @media only screen and (max-width: 720px) {
      min-width: 100px;
      text-overflow: ellipsis;
    }
  }
  &:nth-child(3) {
    flex-basis: 21%;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
  &:nth-child(4) {
    flex-basis: 23%;
    @media only screen and (max-width: 720px) {
      text-overflow: ellipsis;
    }
  }
`;

const TableContent = styled.div<{ isDarkTheme: boolean }>`
  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  width: 100%;
`;

const NoOrdersYetWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 720px) {
    display: grid;
  }
`;

const NoOrdersYet = styled.div`
  height: 80px;
  width: 400px;
  margin: 70px 0;
  text-align: center;
  @media (max-width: 720px) {
    display: grid;
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin-bottom: 3px;
`;
