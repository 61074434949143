import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SectionContent,
  SectionMainLine,
  SummaryDescription,
} from "global-styles/commonOrderStyles";

import { PAYMENT_METHODS_API_NAMES_KEYS } from "utils/consts";
import { OrderCheckoutSummary } from "types/Order";

interface Props {
  orderCheckoutSummary: OrderCheckoutSummary | undefined;
}
const Payment = ({ orderCheckoutSummary }: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Header>
        <SummaryDescription>{t("orderPlate.paymentMethod")}</SummaryDescription>
      </Header>
      <SectionContent>
        {orderCheckoutSummary?.payment.name ===
          PAYMENT_METHODS_API_NAMES_KEYS.PRZELEWY_24 && (
          <SectionMainLine>{t("orderPlate.przelewy24")}</SectionMainLine>
        )}
        {orderCheckoutSummary?.payment.name ===
          PAYMENT_METHODS_API_NAMES_KEYS.PAYMENT_ON_DELIVERY && (
          <SectionMainLine>{t("orderPlate.paymentOnDelivery")}</SectionMainLine>
        )}
        {orderCheckoutSummary?.payment.name === "" && (
          <SectionMainLine>...</SectionMainLine>
        )}
      </SectionContent>
    </Section>
  );
};

export default Payment;
