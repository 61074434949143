import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import "./styles/ReorderModalStyles.css";
import { lightTheme } from "global-styles/colorThemes";

import Container from "components/profile/reorder-drag-and-drop/Container";
import MainButton from "components/inputs/MainButton";
import OutlineButton from "components/inputs/OutlineButton";

import { Item } from "components/profile/reorder-drag-and-drop/Container";

import { ReactComponent as XThickIcon } from "assets/x-thick-icon.svg";

declare global {
  interface Window {
    chosenParcelLocker: any;
  }
}

Modal.setAppElement(document.getElementById("root")!);

interface Props {
  isReorderModalOpen: boolean;
  setIsReorderModalOpen: Dispatch<SetStateAction<boolean>>;
  dataToReorder?: Item[];
  saveReorderedArray: (reorderedArray: any[]) => void;
  headerText: string;
}

const ReorderModal = ({
  isReorderModalOpen,
  setIsReorderModalOpen,
  dataToReorder,
  saveReorderedArray,
  headerText,
}: Props) => {
  const [cards, setCards] = useState<Item[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (dataToReorder) setCards(dataToReorder);
  }, [dataToReorder]);

  const onSave = () => {
    const reorderedContent = cards.map(
      ({ cardId, text, ...rest }) => rest.objectContent
    );

    saveReorderedArray(reorderedContent);
  };

  return (
    <>
      <Modal
        isOpen={isReorderModalOpen}
        onRequestClose={() => setIsReorderModalOpen(false)}
        overlayClassName={{
          base: "overlay-base-reorder",
          afterOpen: "overlay-after-reorder",
          beforeClose: "overlay-before-reorder",
        }}
        className={{
          base: "content-base-reorder",
          afterOpen: "content-after-reorder",
          beforeClose: "content-before-reorder",
        }}
        closeTimeoutMS={500}
      >
        <CloseButton onClick={() => setIsReorderModalOpen(false)}>
          <StyledXIcon />
        </CloseButton>
        <ContentWrapper>
          <TextWrapper>
            <Header>{headerText}</Header>
            <Text>
              <DndProvider backend={HTML5Backend}>
                <Container cards={cards} setCards={setCards} />
              </DndProvider>
            </Text>
          </TextWrapper>
          <ButtonsWrapper>
            <OutlineButton
              noMarginTop
              onClick={() => setIsReorderModalOpen(false)}
              keepLightTheme
            >
              {t("cancel")}
            </OutlineButton>
            <MainButton
              noMarginTop
              onClick={onSave}
              lateralPadding={40}
              keepLightTheme
            >
              {t("save")}
            </MainButton>
          </ButtonsWrapper>
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default ReorderModal;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const StyledXIcon = styled(XThickIcon)`
  width: 25px;
  height: 25px;
  color: ${lightTheme.colors.darkOrLight};

  &:hover {
    opacity: 0.6;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  width: 80%;
  text-align: center;
  line-height: 130%;
`;

const Text = styled.div`
  margin-top: 33px;
  font-size: 19px;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 30px;
`;
