import { FocusEvent, ReactNode, useId } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { Control, Controller } from "react-hook-form";

interface Props {
  control: Control<any>;
  formFieldName: string;
  type?: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  defaultValue?: string | null;
  error?: string;
  extraButton?: {
    content: ReactNode;
    onClickFunction: (arg?: any) => void;
    color?: string;
  };
  noMarginTop?: boolean;
  inputTabIndex?: number;
  onFocus?: () => void;
  maxInputLength?: number;
}

const InputRHF = ({
  control,
  formFieldName,
  type = "text",
  label,
  sublabel,
  placeholder = "",
  defaultValue,
  error,
  extraButton,
  noMarginTop,
  inputTabIndex,
  onFocus,
  maxInputLength = 255,
}: Props) => {
  const id = useId();

  return (
    <>
      <Controller
        control={control}
        name={formFieldName}
        render={({ field: { onChange, value } }) => (
          <StyledFormGroup controlId={id}>
            {!extraButton && label && (
              <StyledFormLabel nomargintop={noMarginTop ? "true" : "false"}>
                {label}
              </StyledFormLabel>
            )}
            {extraButton && (
              <LabelWithButtonWrapper
                nomargintop={noMarginTop ? "true" : "false"}
              >
                <Form.Label>{label}</Form.Label>
                <ExtraButton
                  onClick={extraButton.onClickFunction}
                  color={extraButton.color}
                  type="button"
                >
                  {extraButton.content}
                </ExtraButton>
              </LabelWithButtonWrapper>
            )}
            {sublabel && <Sublabel>{sublabel}</Sublabel>}
            <StyledFormControl
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value ? value : defaultValue ? defaultValue : ""}
              tabIndex={inputTabIndex}
              onFocus={(e: FocusEvent<HTMLInputElement>) => {
                if (onFocus && e.type === "focus") {
                  onFocus();
                }
              }}
              maxLength={maxInputLength}
            />
            {error && (
              <StyledInputError className="text-danger error-container">
                *{error}
              </StyledInputError>
            )}
          </StyledFormGroup>
        )}
      />
    </>
  );
};

export default InputRHF;

const StyledFormGroup = styled(Form.Group)`
  position: relative;
`;

const StyledFormLabel = styled(Form.Label)<{ nomargintop?: string }>`
  margin-top: ${({ nomargintop }) => (nomargintop === "true" ? "0" : "18px")};
`;

const LabelWithButtonWrapper = styled.div<{ nomargintop?: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ nomargintop }) => (nomargintop === "true" ? "0" : "18px")};
`;

const ExtraButton = styled.button<{ color?: string }>`
  all: unset;
  display: inline-block;
  margin-bottom: 8px;
  color: ${(p) => (p.color ? p.color : p.theme.colors.darkOrLight)};
  font-size: 14px;
  transition: 0.5s;

  &:hover {
    opacity: 0.6;
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.colors.inputFocusBlue};
  }
`;

const Sublabel = styled.div`
  margin-top: -8px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.grayOrLightGray};
  font-style: italic;
`;

const StyledFormControl = styled(Form.Control)`
  &:focus-within {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.inputFocusBlue};
  }

  &::placeholder {
    font-size: 13px;
  }
`;

const StyledInputError = styled(Form.Text)`
  position: absolute;
  bottom: -18px;
  left: 8px;
  font-size: 12px;
`;
