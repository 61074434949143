import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

import {OrderFormData} from "types/Order";

export const useOrderPlateFormController = () => {
  const {t} = useTranslation();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      shippingAddress: Yup.object().shape({
        street: Yup.string().trim().required(t("validation.obligatoryField")),
        flatNumber: Yup.string()
          .trim()
          .required(t("validation.obligatoryField")),
        city: Yup.string().trim().required(t("validation.obligatoryField")),
        zipCode: Yup.string()
          .trim()
          .required(t("validation.obligatoryField"))
          .max(6, t("validation.maxSixChars")),
        country: Yup.string().trim().required(t("validation.obligatoryField")),
        email: Yup.string().required(t("validation.obligatoryField")).email(t("validation.invalidEmail")),
        phoneNumber: Yup.string()
          .required(t("validation.obligatoryField"))
          .matches(phoneRegExp, t("validation.invalidPhoneNumber")),
      }),
    });
  }, [t]);

  return useForm<OrderFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
