import { useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ForgottenPasswordForm from "components/auth/forms/ForgottenPasswordForm";
import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";

import { AUTH_PATH, LOGIN_PATH } from "utils/routes";
import {
  ForgottenPasswordFormValues,
  useForgottenPasswordFormController,
} from "components/auth/forms/ForgottenPasswordFormController";
import { renderErrorToast } from "utils/toasts";

const ForgottenPassword = () => {
  const [isPasswordResetLinkSent, setIsPasswordResetLinkSent] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const forgottenPasswordFormMethods = useForgottenPasswordFormController();

  const onSubmit = (values: ForgottenPasswordFormValues) => {
    httpService
      .post(`api/auth/v1/password/reset/`, {
        email: values.userEmail,
      })
      .then(() => {
        setIsPasswordResetLinkSent(true);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  };

  const goToSignInPage = () => {
    navigate(`${AUTH_PATH}${LOGIN_PATH}`, { replace: true });
  };

  return (
    <AuthContentWrapper>
      <AuthTitle>{t("auth.resetPassword")}</AuthTitle>
      {!isPasswordResetLinkSent && (
        <>
          <Description>{t("auth.forgottenPasswordDescription")}</Description>
          <FormProvider {...forgottenPasswordFormMethods}>
            <Form
              onSubmit={forgottenPasswordFormMethods.handleSubmit(onSubmit)}
              noValidate
            >
              <ForgottenPasswordForm />
            </Form>
          </FormProvider>
        </>
      )}
      {isPasswordResetLinkSent && (
        <>
          <Description>
            {t("auth.resetPasswordLinkSentDescription")}
          </Description>
        </>
      )}
      <StyledMainButton
        onClick={goToSignInPage}
        expandWidth
        extramargintop={!isPasswordResetLinkSent ? "true" : "false"}
      >
        {t("auth.goToSignInPage")}
      </StyledMainButton>
    </AuthContentWrapper>
  );
};

export default ForgottenPassword;

const AuthContentWrapper = styled.div`
  margin: 0 24px;
  color: ${({ theme }) => theme.colors.darkOrLight};
`;

const AuthTitle = styled.div`
  margin: 32px 0 28px;
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  line-height: 1.2;
`;

const Description = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.darkOrLight};
  white-space: pre-line;
`;

const StyledMainButton = styled(MainButton)<{ extramargintop: string }>`
  ${(p) => p.extramargintop === "true" && "margin-top: 60px;"}
`;
