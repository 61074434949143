import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ORDER_PLATE_PATH, USER_PATH } from "utils/routes";

import MainButton from "components/inputs/MainButton";

const FailedPaymentContent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <FlexWrapper>
        <InfoBox>
          <Description>{t("orderPlate.paymentFailed")}</Description>
          <Link to={`${USER_PATH}${ORDER_PLATE_PATH}`}>
            <MainButton expandWidth>{t("orderPlate.goBackToOrder")}</MainButton>
          </Link>
        </InfoBox>
      </FlexWrapper>
    </Container>
  );
};

export default FailedPaymentContent;

const Container = styled.div`
  flex-basis: 85.5%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InfoBox = styled.div`
  height: 80px;
  width: 400px;
  margin: 70px 0;
  text-align: center;
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 24px;
  white-space: pre-line;
`;
