import styled from "styled-components";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import "./styles/QuestionModalStyles.css";
import { lightTheme } from "global-styles/colorThemes";

import MainButton from "components/inputs/MainButton";
import OutlineButton from "components/inputs/OutlineButton";

import { QuestionModalContent } from "global-contexts/QuestionModalContext";
import { useQuestionModalContext } from "global-contexts/QuestionModalContext";

import { ReactComponent as XThickIcon } from "assets/x-thick-icon.svg";

Modal.setAppElement(document.getElementById("root")!);

interface Props {
  questionModalContent?: QuestionModalContent;
}

const ModalComponent = ({ questionModalContent }: Props) => {
  const { isQuestionModalOpen, closeQuestionModal } = useQuestionModalContext();

  const { t } = useTranslation();

  const middleButtonAction = () => {
    if (questionModalContent && questionModalContent.middleButtonAction) {
      questionModalContent.middleButtonAction();
    }
    closeQuestionModal();
  };

  return (
    <>
      <Modal
        isOpen={isQuestionModalOpen}
        onRequestClose={closeQuestionModal}
        overlayClassName={{
          base: "overlay-base-question",
          afterOpen: "overlay-after-question",
          beforeClose: "overlay-before-question",
        }}
        className={{
          base: "content-base-question",
          afterOpen: "content-after-question",
          beforeClose: "content-before-question",
        }}
        closeTimeoutMS={500}
      >
        <CloseButton onClick={closeQuestionModal}>
          <StyledXIcon />
        </CloseButton>
        <ContentWrapper>
          <TextWrapper>
            <Text>{questionModalContent?.text}</Text>
          </TextWrapper>
          <ButtonsWrapper>
            <OutlineButton
              noMarginTop
              onClick={
                questionModalContent?.leftButtonAction
                  ? questionModalContent.leftButtonAction
                  : closeQuestionModal
              }
              keepLightTheme
            >
              {questionModalContent?.leftButtonText
                ? questionModalContent?.leftButtonText
                : t("cancel")}
            </OutlineButton>
            {questionModalContent?.middleButtonText && (
              <MainButton
                noMarginTop
                onClick={
                  questionModalContent?.middleButtonAction
                    ? middleButtonAction
                    : () => {}
                }
                lateralPadding={0}
                keepLightTheme
              >
                {questionModalContent?.middleButtonText
                  ? questionModalContent?.middleButtonText
                  : "..."}
              </MainButton>
            )}
            <MainButton
              noMarginTop
              onClick={() => {
                questionModalContent?.rightButtonAction();
                closeQuestionModal();
              }}
              lateralPadding={questionModalContent?.middleButtonText ? 0 : 40}
              keepLightTheme
            >
              {questionModalContent?.rightButtonText
                ? questionModalContent?.rightButtonText
                : t("yes")}
            </MainButton>
          </ButtonsWrapper>
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default ModalComponent;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const StyledXIcon = styled(XThickIcon)`
  width: 25px;
  height: 25px;
  color: ${lightTheme.colors.darkOrLight};

  &:hover {
    opacity: 0.6;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 720px) {
    display: grid;
  }
`;

const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

const Text = styled.div`
  margin-top: 33px;
  font-size: 19px;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
`;

const ButtonsWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 30px;
  @media (max-width: 720px) {
    display: grid;
  }
`;
