import styled from "styled-components";
import { Gallery } from "react-photoswipe-gallery";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

import "photoswipe/dist/photoswipe.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "global-styles/SwiperStyles.css";

import SubsectionParagraph from "components/profile/SubsectionParagraph";
import SwiperSlideWithPhotoSwipe from "components/profile/SwiperSlideWithPhotoSwipe";

import { PersonDescriptionSection } from "types/Profile";

interface Props {
  section: PersonDescriptionSection;
}

const SectionContent = ({ section }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <TextContentWrapper>
        <Heading>{section.header}</Heading>
        {section.subsections.map((subsection, index) => (
          <div key={index}>
            <SubsectionTitle reduceMarginTop={index !== 0}>
              {subsection.subtitle}{" "}
              {subsection.startDate && subsection.endDate && (
                <Dates>
                  {t("addProfile.fromTo", {
                    startDate: subsection.startDate,
                    endDate: subsection.endDate,
                  })}
                </Dates>
              )}
              {subsection.startDate && !subsection.endDate && (
                <Dates>
                  {t("addProfile.from", {
                    startDate: subsection.startDate,
                  })}
                </Dates>
              )}
              {!subsection.startDate && subsection.endDate && (
                <Dates>
                  {t("addProfile.to", {
                    endDate: subsection.endDate,
                  })}
                </Dates>
              )}
            </SubsectionTitle>
            {subsection.rawContent && (
              <SubsectionParagraph editorRawContent={subsection.rawContent} />
            )}
          </div>
        ))}
      </TextContentWrapper>
      <PhotosWrapper>
        <Gallery>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            centeredSlides={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            {section.photos.map((photoObject, index) => (
              <SwiperSlide key={index}>
                <SwiperSlideWithPhotoSwipe photoObject={photoObject} isSwiper />
              </SwiperSlide>
            ))}
          </Swiper>
        </Gallery>
      </PhotosWrapper>
    </>
  );
};

export default SectionContent;

const TextContentWrapper = styled.div`
  padding: 30px 0 0;
  margin: 0 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
`;

const Heading = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  line-height: 1.2;
  margin-bottom: 20px;
`;

const SubsectionTitle = styled.div<{ reduceMarginTop: boolean }>`
  font-size: 20px;
  line-height: 150%;
  ${(p) => p.reduceMarginTop && "margin-top: -10px;"}
`;

const Dates = styled.div`
  font-size: 16px;
  line-height: 150%;
  font-style: italic;
`;

const PhotosWrapper = styled.div`
  margin-top: 30px;
`;
