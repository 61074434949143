import styled from "styled-components";
import { Outlet, useLocation } from "react-router-dom";

import {EDIT_PROFILE_PATH, USER_PATH} from "utils/routes";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import Footer from "components/footer/Footer";
import Navbar from "components/navbar/Navbar";
import {AppMode} from "../types/AppMode";

const MainTemplate = ({appMode}: { appMode: AppMode}) => {
  const { isThemeLoaded } = useColorThemeContext();
  const location = useLocation();

  return (
    <>
      <Background>
        {isThemeLoaded && (
          <>
            <GridWrapper>
              <ContentWrapper>
                {!location.pathname.includes(EDIT_PROFILE_PATH) && <Navbar appMode={appMode} />}
                <Outlet />
              </ContentWrapper>
            </GridWrapper>
            <FooterWrapper>
              <Footer basePath={USER_PATH}/>
            </FooterWrapper>
          </>
        )}
      </Background>
    </>
  );
};

export default MainTemplate;

const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 18fr 1fr;
  grid-template-columns: 1fr;

  @media (max-width: 720px) {
    grid-template-rows: 11fr 1fr;
    height: auto;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-row-start: 1;
`

const FooterWrapper = styled.div`
  display: grid;
  grid-row-start: 2;

`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  height: 90vh;



  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.lightOrDark};
  @media (max-width: 720px) {
    //height: 97vh;
    display: grid;
    position: static;
    transform: translate(2%, 0%);
  }
`;
