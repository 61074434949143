import { createRef, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Gallery } from "react-photoswipe-gallery";
import { useTranslation } from "react-i18next";

import "photoswipe/dist/photoswipe.css";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
  smallButtonStyle,
} from "global-styles/sharedStyles";

import BasicDataField from "components/profile-preview-commons/BasicDataField";
import FirstAndLastNameField from "components/profile-preview-commons/FirstAndLastNameField";
import PreviewModal from "components/profile/PreviewModal";
import SectionContent from "components/profile-preview-commons/SectionContent";
import SwiperSlideWithPhotoSwipe from "components/profile/SwiperSlideWithPhotoSwipe";

import { scrollToTopOfDivElement } from "utils/utilityFunctions";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

const ProfilePreview = () => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

  const {
    profileGeneralData,
    activeStepIndex,
    personDescription,
    focusedSectionIndex,
  } = useProfileDataContext();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const previewWrapperRef = useRef<HTMLDivElement>(null);
  const sectionsRefs = useMemo(
    () => personDescription.map(() => createRef<HTMLDivElement>()),
    [personDescription]
  );

  useEffect(
    function scrollFocusedSectionIntoView() {
      if (
        focusedSectionIndex !== undefined &&
        sectionsRefs[focusedSectionIndex]
      ) {
        sectionsRefs[focusedSectionIndex].current!.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    [focusedSectionIndex, sectionsRefs]
  );

  useEffect(
    function scrollToTopOfPreviewOnBasicDataEditing() {
      if (activeStepIndex === 1) {
        scrollToTopOfDivElement(previewWrapperRef);
      }
    },
    [activeStepIndex]
  );

  return (
    <Container isDarkTheme={isDarkTheme}>
      <HeadlineWrapper>
        <Headline>{t("addProfile.preview")}</Headline>
        <ShowPreviewButton onClick={() => setIsPreviewModalOpen(true)}>
          {t("addProfile.displayPreviewOnly")}
        </ShowPreviewButton>
      </HeadlineWrapper>
      {profileGeneralData && personDescription && (
        <PreviewWrapper ref={previewWrapperRef} isDarkTheme={isDarkTheme}>
          <ProfilePhotoWrapper>
            {profileGeneralData?.photo && (
              <Gallery>
                <SwiperSlideWithPhotoSwipe
                  photoObject={profileGeneralData.photo}
                />
              </Gallery>
            )}
            {!profileGeneralData?.photo && (
              <NoPhoto>
                <div>{t("noPhoto")}</div>
              </NoPhoto>
            )}
          </ProfilePhotoWrapper>
          <FirstAndLastNameField profileGeneralData={profileGeneralData} />
          <BasicDataField profileGeneralData={profileGeneralData} />
          {personDescription.map((section, index) => (
            <Section key={index} ref={sectionsRefs[index]}>
              <SectionContent section={section} />
            </Section>
          ))}
        </PreviewWrapper>
      )}
      <PreviewModal
        isPreviewModalOpen={isPreviewModalOpen}
        setIsPreviewModalOpen={setIsPreviewModalOpen}
      />
    </Container>
  );
};

export default ProfilePreview;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 35%;
  max-width: 31vw;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px 22px 32px 25px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1300px) {
    display: none;
  }

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Headline = styled.div`
  margin-top: 9px;
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
`;

const ShowPreviewButton = styled.button`
  ${smallButtonStyle}
`;

const PreviewWrapper = styled.div<{ isDarkTheme: boolean }>`
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 4px;
  padding: 30px 0;
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
`;

const ProfilePhotoWrapper = styled.div`
  padding: 0 30px;
  width: 100%;
  max-width: 400px;
`;

const NoPhoto = styled.div`
  aspect-ratio: 1.5 / 1;
  min-width: 200px;
  max-width: 350px;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0 auto;
`;

const Section = styled.section`
  width: 100%;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }
`;
