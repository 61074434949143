import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PASSWORD_REGEXP from "../../auth/PasswordRequirements";

export interface SettingsPasswordChangeFormValues {
  newPassword: string;
  newPasswordConfirmation: string;
}

export const useSettingsPasswordChangeFormController = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .required(t("validation.obligatoryField"))
        .matches(
          PASSWORD_REGEXP,
          t("auth.passwordRequirementsNotMet")
        ),
      newPasswordConfirmation: Yup.string()
        .required(t("validation.obligatoryField"))
        .oneOf([Yup.ref("newPassword"), null], t("auth.passwordsMustMatch")),
    });
  }, [t]);

  return useForm<SettingsPasswordChangeFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
};
