import { ReactNode } from "react";

import { ProfileDataContextProvider } from "global-contexts/ProfileDataContext";
import { QuestionModalContextProvider } from "global-contexts/QuestionModalContext";
import { UserDataContextProvider } from "global-contexts/UserDataContext";

interface Props {
  children: ReactNode;
}

const ContextProviders = ({ children }: Props) => {
  return (
    <>
      <QuestionModalContextProvider>
        <UserDataContextProvider>
          <ProfileDataContextProvider>{children}</ProfileDataContextProvider>
        </UserDataContextProvider>
      </QuestionModalContextProvider>
    </>
  );
};

export default ContextProviders;
