import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import DashboardTableRow from "components/dashboard/DashboardTableRow";
import httpService from "utils/httpService";
import MainButton from "components/inputs/MainButton";

import { EDIT_PROFILE_PATH, NEW_PROFILE_PATH, USER_PATH } from "utils/routes";
import { ProfileGeneralData } from "types/Profile";
import { renderErrorToast } from "utils/toasts";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";

const DashboardTable = () => {
  const [existingProfiles, setExistingProfiles] = useState<
    ProfileGeneralData[]
  >([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const { fetchUserTheme, isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  useEffect(
    function fetchExitstingProfiles() {
      httpService
        .get(`api/passedaway/person/v1/`)
        .then((response) => {
          setExistingProfiles(response.data);
          setIsDataLoaded(true);
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    },
    [fetchUserTheme, t]
  );

  return (
    <>
      {isDataLoaded && (
        <>
          <TableWrapper isDarkTheme={isDarkTheme}>
            <TableHeaders>
              <TableHeader>{t("dashboard.nameAndSurname")}</TableHeader>
              <TableHeader>{t("dashboard.profileCreationDate")}</TableHeader>
              <TableHeader>{t("dashboard.lastEditDate")}</TableHeader>
              <TableHeader>{t("edit")}</TableHeader>
            </TableHeaders>
            <TableContent
              isDisplayFlex={existingProfiles.length === 0}
              isDarkTheme={isDarkTheme}
            >
              {existingProfiles.length === 0 && (
                <NoProfilesYet>
                  <Description>
                    {t("dashboard.noProfileCreatedYet")}
                  </Description>
                  <NavLink
                    to={`${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}`}
                  >
                    <MainButton type="submit" expandWidth>
                      <StyledPlusIcon />
                      <AddTextWrapper>{t("dashboard.createProfile")}</AddTextWrapper>
                    </MainButton>
                  </NavLink>
                </NoProfilesYet>
              )}
              {existingProfiles.length > 0 &&
                existingProfiles.map((profile, index) => (
                  <DashboardTableRow key={index} {...profile} />
                ))}
            </TableContent>
          </TableWrapper>
          {existingProfiles.length > 0 && (
            <NavLink to={`${USER_PATH}${EDIT_PROFILE_PATH}${NEW_PROFILE_PATH}`}>
              <MainButton lateralPadding={120} expandWidth={true}>
                <StyledPlusIcon />
                <AddTextWrapper>{t("dashboard.createProfile")}</AddTextWrapper>
              </MainButton>
            </NavLink>
          )}
        </>
      )}
    </>
  );
};

export default DashboardTable;

const AddTextWrapper = styled.span`
  @media (max-width: 720px) {
    display: none;
  }
`
const TableWrapper = styled.div<{ isDarkTheme: boolean }>`
  max-height: 75%;
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  }

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
@media (max-width: 720px) {
  display: grid;
  max-height: 100%;
  overflow-y: scroll;
}
`;

const TableHeaders = styled.div`
  height: 50px;
  margin-bottom: 10px;
  border-bottom: 2px solid
    ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  align-items: center;
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  text-align: center;

  &:nth-child(1) {
    flex-basis: 49%;
    text-align: left;
  }
  &:nth-child(2) {
    flex-basis: 27%;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
  &:nth-child(3) {
    flex-basis: 27%;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
  &:nth-child(4) {
    flex-basis: 27%;
  }
`;

interface TableContentProps {
  isDisplayFlex: boolean;
  isDarkTheme: boolean;
}

const TableContent = styled.div<TableContentProps>`
  width: 100%;

  ${({ isDisplayFlex }) =>
    isDisplayFlex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
`;

const NoProfilesYet = styled.div`
  height: 80px;
  width: 350px;
  margin: 70px 0;
  text-align: center;
`;

const Description = styled.div`
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 0;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin-bottom: 3px;
`;
