import { ReactNode, useEffect, useId, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import pl from "date-fns/locale/pl";
import styled from "styled-components";
import { Control, Controller } from "react-hook-form";
import { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./styles/datePickerRHFStyles.css";
import { lightTheme } from "global-styles/colorThemes";

import {
  formatDateWithDay,
  formatDateWithoutDay,
} from "utils/utilityFunctions";

import { ReactComponent as CalendarIcon } from "assets/calendar-icon.svg";

import moment from "moment";

registerLocale("pl", pl);

interface Props {
  control: Control<any>;
  formFieldName: string;
  onlyMonthAndYear?: boolean;
  label?: string;
  placeholder?: string;
  defaultValue?: string | null;
  error?: string;
  extraButton?: {
    content: ReactNode;
    onClickFunction: (arg?: any) => void;
    color?: string;
  };
  noMarginTop?: boolean;
  disabled?: boolean;
  inputTabIndex?: number;
  onFocus?: () => void;
}

const DatePickerRHF = ({
  control,
  formFieldName,
  onlyMonthAndYear,
  label,
  placeholder = "DD/MM/RRRR",
  defaultValue,
  error,
  extraButton,
  noMarginTop,
  disabled,
  inputTabIndex,
  onFocus,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>();

  const id = useId();

  const setDate = (date: Date | null) => {
    setSelectedDate(date);
  };

  const isDefaultDataLoaded = useRef(false);
  useEffect(() => {
    if (!isDefaultDataLoaded.current) {
      isDefaultDataLoaded.current = true;
      if (defaultValue) {
        setSelectedDate(
          new Date(
            moment(defaultValue, [
              onlyMonthAndYear ? "MM/yyyy" : "DD/MM/yyyy",
            ]).valueOf()
          )
        );
      }
    }
  }, [defaultValue, selectedDate, onlyMonthAndYear]);

  return (
    <>
      <Controller
        control={control}
        name={formFieldName}
        render={({ field: { onChange } }) => (
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setDate(date);
              if (date)
                onChange(
                  onlyMonthAndYear
                    ? formatDateWithoutDay(date)
                    : formatDateWithDay(date)
                );
              if (!date) {
                onChange(date);
              }
            }}
            locale="pl"
            showMonthDropdown
            showYearDropdown
            isClearable
            showMonthYearPicker={onlyMonthAndYear}
            popperPlacement="bottom"
            dropdownMode="select"
            disabled={disabled}
            maxDate={new Date()}
            customInput={
              <StyledFormGroup controlId={id}>
                {!extraButton && (
                  <StyledFormLabel nomargintop={noMarginTop ? "true" : "false"}>
                    {label}
                  </StyledFormLabel>
                )}
                {extraButton && (
                  <LabelWithButtonWrapper nomargintop={noMarginTop}>
                    <Form.Label>{label}</Form.Label>
                    <ExtraButton
                      onClick={extraButton.onClickFunction}
                      color={extraButton.color}
                      type="button"
                    >
                      {extraButton.content}
                    </ExtraButton>
                  </LabelWithButtonWrapper>
                )}
                <InputWrapper onClick={onFocus}>
                  <StyledFormControl
                    type="text"
                    placeholder={placeholder}
                    onChange={onChange}
                    value={
                      disabled
                        ? ""
                        : selectedDate && !onlyMonthAndYear
                        ? formatDateWithDay(selectedDate)
                        : selectedDate && onlyMonthAndYear
                        ? formatDateWithoutDay(selectedDate)
                        : ""
                    }
                    autoComplete="off"
                    disabled={disabled}
                    tabIndex={inputTabIndex}
                  />
                  <CalendarIconButton type="button" disabled={disabled}>
                    <StyledCalendarIcon />
                  </CalendarIconButton>
                </InputWrapper>
                {error && (
                  <StyledInputError className="text-danger error-container">
                    *{error}
                  </StyledInputError>
                )}
              </StyledFormGroup>
            }
          />
        )}
      />
    </>
  );
};

export default DatePickerRHF;

const StyledFormGroup = styled(Form.Group)`
  position: relative;
  margin-bottom: 0 !important;
  cursor: pointer;
`;

const StyledFormLabel = styled(Form.Label)<{ nomargintop?: string }>`
  margin-top: ${({ nomargintop }) => (nomargintop === "true" ? "0" : "18px")};
`;

const LabelWithButtonWrapper = styled.div<{ nomargintop?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ nomargintop }) => (nomargintop ? "0" : "18px")};
`;

const ExtraButton = styled.button<{ color?: string }>`
  all: unset;
  display: inline-block;
  margin-bottom: 8px;
  color: ${(p) => (p.color ? p.color : p.theme.colors.darkOrLight)};
  font-size: 14px;
  transition: 0.5s;

  &:hover {
    opacity: 0.6;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.inputWrapperGray};
  border-radius: 6px;
  display: flex;
  height: 38px;
  font-size: 0;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.inputFocusBlue};
  }
`;

const StyledFormControl = styled(Form.Control)`
  border: none;
  border-radius: 5px 0 0 5px;
  caret-color: transparent;

  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus-within {
    box-shadow: none;
  }

  &::placeholder {
    font-size: 13px;
  }
`;

const CalendarIconButton = styled.button<{ disabled?: boolean }>`
  all: unset;
  cursor: pointer;
  background-color: ${lightTheme.colors.whiteOrDark};
  border-radius: 0 5px 5px 0;
  font-size: 0;
  ${(p) => p.disabled && `background-color: ${p.theme.colors.disbaledGray};`}

  svg {
    height: 30px;
    width: 30px;
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-right: 5px;
`;

const StyledInputError = styled(Form.Text)`
  position: absolute;
  bottom: -18px;
  left: 8px;
  font-size: 12px;
`;
