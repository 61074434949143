import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputRHF from "components/inputs/InputRHF";
import MainButton from "components/inputs/MainButton";

import { ForgottenPasswordFormValues } from "components/auth/forms/ForgottenPasswordFormController";

const ForgottenPasswordForm = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<ForgottenPasswordFormValues>();

  return (
    <>
      <InputRHF
        type="email"
        formFieldName="userEmail"
        control={control}
        label={t("auth.emailAddress")}
        placeholder={t("auth.enterEmailAddress")}
        error={errors.userEmail?.message}
      />
      <MainButton type="submit" expandWidth>
        {t("auth.send")}
      </MainButton>
    </>
  );
};

export default ForgottenPasswordForm;
