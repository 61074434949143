import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { colors } from "utils/outsideThemeColors";

import InputPasswordRHF from "components/inputs/InputPasswordRHF";
import MainButton from "components/inputs/MainButton";

import { ResetPasswordFormValues } from "components/auth/forms/ResetPasswordFormController";

interface Props {
  isLoading: boolean;
}

const ResetPasswordForm = ({ isLoading }: Props) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<ResetPasswordFormValues>();

  return (
    <>
      <InputPasswordRHF
        formFieldName="newPassword"
        control={control}
        label={t("auth.newPassword")}
        sublabel={t("auth.passwordRequirements")}
        placeholder={t("auth.enterNewPassword")}
        error={errors.newPassword?.message}
        inputTabIndex={1}
      />
      <InputPasswordRHF
        formFieldName="newPasswordConfirmation"
        control={control}
        label={t("auth.repeatNewPassword")}
        placeholder={t("auth.repeatNewPassword")}
        error={errors.newPasswordConfirmation?.message}
        inputTabIndex={2}
      />
      {!isLoading && (
        <StyledMainButton type="submit" expandWidth>
          {t("auth.setPassword")}
        </StyledMainButton>
      )}
      {isLoading && (
        <LoaderWrapper>
          <SyncLoader color={colors.dark} size={10} />
        </LoaderWrapper>
      )}
    </>
  );
};

export default ResetPasswordForm;

const StyledMainButton = styled(MainButton)`
  margin-top: 21px;
`;

const LoaderWrapper = styled.div`
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
