import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { smallOutlineButtonStyle } from "global-styles/sharedStyles";

import httpService from "utils/httpService";
import UploadProfilePhotoModal from "components/profile/upload-profile-photo/UploadProfilePhotoModal";

import { renderErrorToast, renderSuccessToast } from "utils/toasts";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";
import { useQuestionModalContext } from "global-contexts/QuestionModalContext";

import { ReactComponent as XIcon } from "assets/x-icon.svg";

const ProfilePhotoUpload = () => {
  const [isUploadProfilePhotoModalOpen, setIsUploadProfilePhotoModalOpen] =
    useState<boolean>(false);

  const { profileGeneralData, setProfileGeneralData } = useProfileDataContext();

  const { openQuestionModal } = useQuestionModalContext();

  const { t } = useTranslation();

  const deletePhoto = () => {
    if (profileGeneralData && profileGeneralData.photo) {
      httpService
        .delete(
          `api/passedaway/person/v1/${profileGeneralData.id}/photos/${profileGeneralData.photo.id}/`
        )
        .then(() => {
          setProfileGeneralData({
            ...profileGeneralData,
            photo: null,
          });
          renderSuccessToast(t("addProfile.photoHasBeenRemovedFromProfile"));
        })
        .catch(() => {
          renderErrorToast(t("errorOccurred"));
        });
    }
  };

  return (
    <>
      {profileGeneralData?.photo && (
        <ReuploadWrapper>
          <UploadButton onClick={() => setIsUploadProfilePhotoModalOpen(true)}>
            {t("addProfile.uploadDifferentPhoto")}
          </UploadButton>
          {profileGeneralData.photo && (
            <UploadedFileDescription>
              {`${t("addProfile.currentPhoto")} ${
                profileGeneralData.photo.originalFileName
              }`}
            </UploadedFileDescription>
          )}
          <DeleteFileButton
            onClick={() =>
              openQuestionModal({
                text: t("addProfile.wantToDeleteProfilePhotoQuestion"),
                rightButtonAction: deletePhoto,
              })
            }
          >
            <XIcon />
          </DeleteFileButton>
        </ReuploadWrapper>
      )}
      {!profileGeneralData?.photo && (
        <UploadButtonWrapper>
          <UploadButton onClick={() => setIsUploadProfilePhotoModalOpen(true)}>
            {t("addProfile.uploadPhoto")}
          </UploadButton>
        </UploadButtonWrapper>
      )}
      <UploadProfilePhotoModal
        isUploadProfilePhotoModalOpen={isUploadProfilePhotoModalOpen}
        setIsUploadProfilePhotoModalOpen={setIsUploadProfilePhotoModalOpen}
      />
    </>
  );
};

export default ProfilePhotoUpload;

const ReuploadWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

const UploadButtonWrapper = styled.div`
  margin-bottom: 13px;
`;

const UploadButton = styled.button`
  ${smallOutlineButtonStyle}
`;

const UploadedFileDescription = styled.div`
  font-size: 12px;
  margin-top: 2px;
  margin-left: 7px;
  margin-right: 15px;
`;

const DeleteFileButton = styled.button`
  ${smallOutlineButtonStyle}

  padding: 3px 0;
  height: 13px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;
