import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources, { AvailableLanguages } from "utils/translations/resource";

export const initLang = (): string => {
  const defaultLang = AvailableLanguages.pl;
  return defaultLang;
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: initLang(),
    fallbackLng: AvailableLanguages.pl,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((e) => e);
