import { ReactNode } from "react";
import Button from "react-bootstrap/Button";
import styled, { css } from "styled-components";

import { lightTheme } from "global-styles/colorThemes";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  onClick?: () => void;
  type?: string;
  variant?: string;
  className?: string;
  disabled?: boolean;
  expandWidth?: boolean;
  lateralPadding?: number;
  noMarginTop?: boolean;
  keepLightTheme?: boolean;
  children: ReactNode;
}

const MainButton = ({
  onClick,
  type = "button",
  variant = "primary",
  className,
  disabled,
  expandWidth,
  lateralPadding,
  noMarginTop,
  keepLightTheme,
  children,
}: Props) => {
  const { isDarkTheme } = useColorThemeContext();

  return (
    <>
      <StyledButton
        variant={variant}
        className={className}
        type={type}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (onClick) onClick();
          e.currentTarget.blur();
        }}
        expandwidth={expandWidth ? "true" : "false"}
        lateralpadding={lateralPadding}
        nomargintop={noMarginTop ? "true" : "false"}
        disabled={disabled}
        keeplighttheme={keepLightTheme ? "true" : undefined}
        isdarktheme={isDarkTheme ? "true" : undefined}
      >
        {children}
      </StyledButton>
    </>
  );
};

export default MainButton;

interface ButtonProps {
  expandwidth?: string;
  lateralpadding?: number;
  nomargintop?: string;
  isdarktheme: boolean;
  keeplighttheme: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`
  ${(p) => p.expandwidth === "true" && "width: 100%;"}
  height: 36px;
  background-color: ${(p) =>
    p.keeplighttheme
      ? lightTheme.colors.darkOrLight
      : p.theme.colors.darkOrLight};
  border: none;
  color: ${(p) =>
    p.keeplighttheme
      ? lightTheme.colors.whiteOrDark
      : p.theme.colors.whiteOrDark};
  margin-top: ${(p) => (p.nomargintop === "true" ? "0" : "16px")};
  transition: 0.5s;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: ${({ theme }) => theme.colors.moderateGray};
    border: none;
    ${(p) =>
      p.isdarktheme &&
      !p.keeplighttheme &&
      `color: ${lightTheme.colors.darkOrLight};`}
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  }

  ${({ lateralpadding }) =>
    lateralpadding &&
    css`
      padding-left: ${lateralpadding}px;
      padding-right: ${lateralpadding}px;
    `}
`;
