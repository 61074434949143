import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import {useColorThemeContext} from "global-contexts/ColorThemeContext";
import ImageGallery from 'react-image-gallery';

import QrCodeImage from "assets/qrcode.png";
import Footer from "../components/footer/Footer";
import {AUTH_PATH, SIGN_IN_PATH, STATIC_PAGE_PATH, USER_PATH} from "../utils/routes";
import {useAppSelector} from "../store/storeHooks";
import {selectAuthToken} from "../store/auth/AuthSlice";
import Button from "react-bootstrap/Button";
import Photo1 from "assets/photos/plate/photo1.png"
import Photo2 from "assets/photos/plate/photo2.png"
import Photo1Thumbnail from "assets/photos/plate/photo1_t.png"
import Photo2Thumbnail from "assets/photos/plate/photo2_t.png"

import Profil from "assets/photos/profile/profil.png"
import ProfilThumbnail from "assets/photos/profile/profil_t.png"
import Profil2 from "assets/photos/profile/profil2.png"
import Profil2Thumbnail from "assets/photos/profile/profil2.png"
import Tombstone from "assets/photos/tombstone/tombstone.png"
import Logo from "assets/LOGO_Light.png"

const LandingPage = () => {
  const {setIsDarkTheme} = useColorThemeContext();
  const navigate = useNavigate();
  const authToken = useAppSelector(selectAuthToken);

  const {t} = useTranslation();

  useEffect(() => {
    setIsDarkTheme(false);
  }, [setIsDarkTheme]);

  useEffect(() => {
    if (authToken) {
      navigate(USER_PATH);
    }
  })


  const plaguePhotos = [
    {
      original: Photo1,
      thumbnail: Photo1Thumbnail
    },
    {
      original: Photo2,
      thumbnail: Photo2Thumbnail
    }
  ]

  const profilePhotos = [
    {
      original: Profil,
      thumbnail: ProfilThumbnail
    },
    {
      original: Profil2,
      thumbnail: Profil2Thumbnail
    }
  ]
  return (
    <>
      <Background className={"container-fluid"}>
        <HappyCustomerPart className={"row"}>
          <Col>
            <Row>
              <LogoImage src={Logo} alt={"Logo memero"}></LogoImage>
            </Row>

            <Row>
              <HappyCustomerLeft className={"col-lg-5 col-md-12"}>
                <Row>
                  <Col>
                    <AppName>Więcej niż tradycyjny nagrobek</AppName>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Subtitle>
                      Stwórz profil bliskiej Ci osoby, dodaj historie i zdjęcia.
                      Umieść tabliczkę z kodem QR na nagrobku, skanuj i pielęgnuj wspomnienia.
                    </Subtitle>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <CreateAccountButton onClick={() => navigate(`${AUTH_PATH}/${SIGN_IN_PATH}`)}>
                      <ButtonText>Załóż darmowe konto i stwórz profil zmarłego</ButtonText>
                    </CreateAccountButton>
                  </Col>
                </Row>

              </HappyCustomerLeft>
              <HappyCustomerPhotoColumn className={"col-lg-7"}>
                <HappyCustomerPhoto src={Tombstone} alt="tabliczka memero zamontowana na grobie"/>
              </HappyCustomerPhotoColumn>
            </Row>
          </Col>
        </HappyCustomerPart>


        {/*<ProblemPart>*/}
        {/*  <ProblemDescription>*/}
        {/*    <h3>Wspomnienia się zacierają</h3>*/}
        {/*    <h3>Zwykły nagrobek przekazuje tylko suche, podstawowe informacje</h3>*/}
        {/*    <h3>Ciężko zachęcić młodych, żeby poznawali historię rodziny</h3>*/}
        {/*  </ProblemDescription>*/}
        {/*</ProblemPart>*/}

        <SolutionDescription className={"row"}>
          <Col>
            <Row>
              <div className={"d-flex justify-content-center"}>
                <SolutionHeader>Jak to działa?</SolutionHeader>
              </div>
            </Row>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <div className={"ratio ratio-16x9"}>
                  <YoutubeVideo src="https://www.youtube.com/embed/Veg783qj3Lk"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></YoutubeVideo>
                </div>
              </Col>
              <Col className={"gy-5"}>
                <Row><Col><DescriptionHeader>Załóż darmowe konto na platformie</DescriptionHeader></Col></Row>
                <Row><Col><DescriptionHeader>Stwórz profil zmarłego i zamów tabliczkę</DescriptionHeader></Col></Row>
                <Row><Col><DescriptionHeader>Umieść tabliczkę z kodem QR na nagrobku</DescriptionHeader></Col></Row>
                <Row><Col><DescriptionHeader>Skanuj i wspominaj bliskich</DescriptionHeader></Col></Row>
              </Col>

            </Row>
          </Col>
        </SolutionDescription>

        <ProfileDescriptionPart className={"row gx-5"}>
          <ProfileTextDescription className={"col-lg-4 col-md-12 col-sm-12"}>
            <Header3>Profil</Header3>
            <DescriptionInBrown>
              Profil na Memero to interaktywna pamiątka, która pozwala na tworzenie i przechowywanie pełnej
              historii życia zmarłej osoby. Możesz dodawać zdjęcia i informacje o wydarzeniach, miejscach, pasjach
              i zainteresowaniach. To miejsce, gdzie pamięć staje się żywa i dostępna dla wszystkich, którzy chcą
              odkrywać historię bliskich.
            </DescriptionInBrown>
          </ProfileTextDescription>

          <ProfileScreens className={"col-lg-5 col-md-12 col-sm-12"}>
            <ImageGallery items={profilePhotos}/>
          </ProfileScreens>
          <QrCode className={"col-lg-3 col-md-12 col-sm-12"}>
            <Row>
              <div className={"d-flex justify-content-center"}>
              <QrCodeDescriptionUp>
                  {t("scanAndCheckExampleProfile")}
                </QrCodeDescriptionUp>
              </div>
            </Row>
            <Row>
              <Col className={"justify-content-center"}>
                <QrCodeImg src={QrCodeImage} className={"img-fluid"} alt="qrcode"/>
              </Col>
            </Row>
            <Row>
              <div className={"d-flex justify-content-center"}>

                <QrCodeDescriptionDown>
                  Lub kliknij <a
                  href={"https://memero.pl/app/user/public-profile/c6715166-f03a-4cea-88ba-80c9e8b6d879"}
                  target={"_blank"}
                  rel="noreferrer"
                >tutaj</a>
                </QrCodeDescriptionDown>
              </div>
            </Row>

          </QrCode>


        </ProfileDescriptionPart>

        <PlaguePart className={"row gx-5"}>


          <PlagueDescription className={"col-lg-8 col-md-12 col-sm-12"}>
            <Row>
              <Header3>Tabliczka</Header3>
            </Row>
            <Row>
              <PlagueDescriptionText>
                Tabliczka z kodem QR na szkle akrylowym to nie tylko elegancka, ale także trwała i odporne na różne
                warunki pogodowe forma upamiętnienia bliskiej osoby. Bez względu na deszcz, słońce czy wiatr,
                tabliczka będzie zachowywać swoją jakość, umożliwiając skanowanie i odkrywanie historii zmarłego
                nawet po wielu latach.
                Uwiecznij pamięć o bliskich na tabliczce Memero i zapewnij trwałość ich historii.
              </PlagueDescriptionText>
            </Row>

          </PlagueDescription>
          <PlaguePhotos className={"col"}>
            <ImageGallery items={plaguePhotos}/>
          </PlaguePhotos>
        </PlaguePart>


        <BenefistSection className={"row"}>
          <Row>
            <div className={"d-flex justify-content-center"}>
              <WhyMemero>Dlaczego Memero?</WhyMemero>
            </div>
          </Row>
          <Row gx={5}>
            <Col md={12} lg={3}>
              <Row>
                <Col>
                  <BenefitHeader>Nowoczesne narzędzie upamiętniania</BenefitHeader>
                </Col>
              </Row>
              <Row>
                <BenefitBody>Nie musisz ograniczać się jedynie do imienia i daty na tradycyjnej płycie nagrobnej. Z
                  tabliczką QR możesz dzielić się pełnymi historiami, zdjęciami i innymi informacjami. Korzystając z
                  cyfrowego zapisu danych, eliminujesz ryzyko związane ze zniszczeniem lub utratą
                  wspomnień.</BenefitBody>
              </Row>
            </Col>
            <Col md={12} lg={3}>
              <Row>
                <BenefitHeader>Posiada intuicyjny edytor</BenefitHeader>
              </Row>
              <Row>
                <BenefitBody>Edytor profili Memero jest niezwykle prosty w użyciu. Dzięki intuicyjnemu interfejsowi
                  użytkownika i przyjaznym funkcjom każdy może łatwo tworzyć i edytować profile bliskich osób, dodając
                  zdjęcia, opisy i wspomnienia.</BenefitBody>
              </Row>
            </Col>
            <Col md={12} lg={3}>
              <Row>
                <BenefitHeader>Dostępne kiedy potrzebujesz</BenefitHeader>
              </Row>
              <Row>
                <BenefitBody>Możesz przeglądać historię i zdjęcia bliskiej osoby o dowolnej porze. W każdym momencie
                  możesz aktualizować profil dodając nowe fotografie i informacje
                </BenefitBody>
              </Row>
            </Col>
            <Col md={12} lg={3}>
              <Row>
                <BenefitHeader>Wystarczy telefon</BenefitHeader>
              </Row>
              <Row>
                <BenefitBody>
                  Kod QR można zeskanować przy użyciu domyślnej aplikacji aparatu w smartfonie. Wystarczy, nakierować
                  aparat na kod, a na ekranie wyświetli się link lub inna informacja, na którą należy nacisnąć.
                </BenefitBody>
              </Row>
            </Col>
          </Row>
        </BenefistSection>
        <FooterWrapper>
          <Footer basePath={STATIC_PAGE_PATH}/>
        </FooterWrapper>
      </Background>
    </>
  );
};

export default LandingPage;
const Header2 = styled.h2`
  font-size: 50px;
  text-align: justify;
  font-family: "Lato", serif;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`;

const Header3 = styled.h3`
  font-size: 30px;
  font-family: "Lato", serif;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 25px;
  }
`;

const Background = styled.div`
  width: 95%;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled.div`
  margin-top: 5vh;
`


const AppName = styled.h1`
  font-family: "Jost", serif;
  font-weight: 500;
  font-size: 60px;
  margin-bottom: 1em;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 50px;
  }
`;

const Subtitle = styled(Header3)`
  margin-bottom: 1em;
`;


const DescriptionHeader = styled(Header3)`
  @media(min-width: 720px) {
    margin-bottom: 1em;
  }

  @media(min-width: 960px){
    margin-bottom: 3em;
  }


`;
const QrCode = styled.div`
  justify-content: center;
  @media(max-width: 540px) {
    display: none;
  }
`;

const ProfileScreens = styled.div`

`
const ProfileTextDescription = styled.div`
`;

const PlaguePart = styled.div`
  background: ${({theme}) => theme.colors.lightOrDark};
  color: ${({theme}) => theme.colors.darkOrLight};
  border-radius: 20px;
  padding: 20px;
  margin-top: 10em;


`;

const PlagueDescription = styled.div`
`;

const DescriptionInBrown = styled.p`
  font-size: 24px;
  line-height: 130%;
  text-align: justify;
  font-family: "Lato", serif;

  @media (max-width: 720px) {
    font-size: 18px;
    line-height: 120%;
  }
`
const PlagueDescriptionText = styled(DescriptionInBrown)`
`

const PlaguePhotos = styled.div`
`;

const QrCodeDescriptionUp = styled(DescriptionInBrown)`
  @media (max-width: 720px) {
    margin-top: 1em;
  }
`;

const QrCodeDescriptionDown = styled(DescriptionInBrown)`
`;

const QrCodeImg = styled.img`
  filter: brightness(90%);

  @media(max-width: 959px) {
    max-width: 50vw;
    margin-left: 20vw;
  }
`;

export const CreateAccountButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  background-color: ${({theme}) => theme.colors.whiteOrDark};
  color: ${({theme}) => theme.colors.darkOrLight};
  justify-content: center;
`;

export const ButtonText = styled.div`
  align-self: center;
  margin-left: 6px;
  font-size: 24px;
  text-align: justify;
  font-family: "Lato", serif;
`;

const SolutionDescription = styled.div`
  color: ${({theme}) => theme.colors.lightOrDark};

  margin-top: 10em;
  min-height: 40vh;
`;


const SolutionHeader = styled(Header2)`
  min-height: 15vh;
`;

const YoutubeVideo = styled.iframe`

`

const ProfileDescriptionPart = styled.div`
  background: ${({theme}) => theme.colors.lightOrDark};
  color: ${({theme}) => theme.colors.darkOrLight};
  border-radius: 20px;
  padding: 20px;
  margin-top: 10em;
`;

const BenefistSection = styled.div`
  color: ${({theme}) => theme.colors.lightOrDark};
  margin-top: 10em;
  font-family: "Lato", serif;

`;


const WhyMemero = styled(Header2)`
  min-height: 15vh;
  @media(max-width: 540px) {
    font-size: 35px;

  }
`
const BenefitHeader = styled(Header3)`
  text-align: center;
  min-height: 7vh;
`;

const BenefitBody = styled(DescriptionInBrown)`
`;


const HappyCustomerPart = styled.div`

`;

const HappyCustomerLeft = styled.div`
  color: ${({theme}) => theme.colors.lightOrDark};

`;

const HappyCustomerPhotoColumn = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`
const HappyCustomerPhoto = styled.img`
  max-width: 100%;

  @media (max-width: 720px) {
    display: none;
  }
`;

const LogoImage = styled.img`
  max-width: 25vw;
  grid-area: happy-customer-logo;
  margin-bottom: 5em;

  @media (max-width: 720px) {
    max-width: 80vw;
  }
`;

