import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { PersonDescriptionSection } from "types/Profile";

export type AddSectionDataFormValues = Pick<
  PersonDescriptionSection,
  "subsections"
>;

export const useAddSectionDataFormValidator = () => {
  const { t } = useTranslation();

  const sectionSchema = Yup.object().shape({
    subtitle: Yup.string().trim().required(t("validation.obligatoryField")),
    editorRawContent: Yup.string()
      .trim()
      .required(t("validation.obligatoryField")),
  });

  return Yup.object().shape({
    sectionContent: Yup.array().of(sectionSchema),
  });
};
