import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from "react-i18next";

import { colors } from "utils/outsideThemeColors";

import httpService from "utils/httpService";
import InputRHF from "components/inputs/InputRHF";
import MainButton from "components/inputs/MainButton";
import OutlineButton from "components/inputs/OutlineButton";

import { renderErrorToast, renderSuccessToast } from "utils/toasts";
import {
  SettingsRegularFieldFormValues,
  useSettingsRegularFieldFormController,
} from "components/settings/forms/SettingsRegularFieldFormController";
import { USER_REGULAR_DATA_API_NAMES } from "utils/consts";
import { useUserDataContext } from "global-contexts/UserDataContext";
import { UserRegularDataApiName, UserSettingsData } from "types/Others";

import { ReactComponent as EditIcon } from "assets/edit-icon.svg";

interface Props {
  rowData: UserSettingsData;
  apiReuqestKeyName?: UserRegularDataApiName;
  isAnySettingsRowBeingEdited: boolean;
  isNotEditable?: boolean;
  setIsAnySettingsRowBeingEdited: Dispatch<SetStateAction<boolean>>;
}

const SettingsRegularTableRow = ({
  rowData,
  apiReuqestKeyName,
  isAnySettingsRowBeingEdited,
  isNotEditable,
  setIsAnySettingsRowBeingEdited,
}: Props) => {
  const [isThisRowBeingEdited, setIsThisRowBeingEdited] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { fetchUserData } = useUserDataContext();

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useSettingsRegularFieldFormController();

  const onSubmit = (values: SettingsRegularFieldFormValues) => {
    setIsLoading(true);

    httpService
      .patch(`api/auth/v1/user/`, {
        [`${apiReuqestKeyName}`]: values.field,
      })
      .then(() => {
        fetchUserData();
        setIsLoading(false);
        setIsThisRowBeingEdited(false);
        setIsAnySettingsRowBeingEdited(false);
        renderSuccessToast(
          apiReuqestKeyName === USER_REGULAR_DATA_API_NAMES.FIRST_NAME
            ? t("settings.firstNameHasBeenUpdated")
            : apiReuqestKeyName === USER_REGULAR_DATA_API_NAMES.LAST_NAME
            ? t("settings.lastNameHasBeenUpdated")
            : t("fieldHasBeenUpdated")
        );
      })
      .catch(() => {
        setIsLoading(false);
        renderErrorToast(t("errorOccurred"));
      });
  };

  const toggleEditing = () => {
    setIsThisRowBeingEdited((prevState) => !prevState);
    setIsAnySettingsRowBeingEdited((prevState) => !prevState);
  };

  const renderValueField = () => (
    <>
      <RowItem>
        {!isThisRowBeingEdited &&
          (rowData.dataValue !== "" ? rowData.dataValue : "----------------")}
        {isThisRowBeingEdited && (
          <InputRHF
            formFieldName="field"
            control={control}
            defaultValue={rowData.dataValue}
            error={errors.field?.message}
            noMarginTop
          />
        )}
      </RowItem>
    </>
  );

  return (
    <RowWrapper>
      <RowItem>{rowData.dataName}</RowItem>
      {renderValueField()}
      {!isNotEditable && !isThisRowBeingEdited && (
        <RowItem>
          <EditButton
            onClick={toggleEditing}
            disabled={isAnySettingsRowBeingEdited}
          >
            <StyledEditIcon />
            <EditText>{t("edit")}</EditText>
          </EditButton>
        </RowItem>
      )}
      {isThisRowBeingEdited && !isLoading && (
        <RowItem>
          <OutlineButton noMarginTop onClick={toggleEditing}>
            {t("cancel")}
          </OutlineButton>
          <MainButton
            type="submit"
            noMarginTop
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </MainButton>
        </RowItem>
      )}
      {isThisRowBeingEdited && isLoading && (
        <LoaderWrapper>
          <SyncLoader color={colors.dark} size={10} />
        </LoaderWrapper>
      )}
    </RowWrapper>
  );
};

export default SettingsRegularTableRow;

const RowWrapper = styled.div`
  height: 74px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};

  &:last-child {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  }
`;

const RowItem = styled.div`
  font-size: 19px;

  &:nth-child(1) {
    flex-basis: 30%;
    font-weight: 600;
  }
  &:nth-child(2) {
    flex-basis: 10%;
    padding-right: 140px;
  }
  &:nth-child(3) {
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;

const EditButton = styled.button`
  all: unset;
  margin-top: 5px;
  margin-left: 11px;
  margin-bottom: 6px;
  transition: 0.5s;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.midGrayOrGray};
    cursor: unset;
  }

  &:hover:disabled {
    opacity: 1;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  width: 21px;
  height: 21px;
`;

const EditText = styled.div`
  font-size: 14px;
  margin-left: 5px;
`;

const LoaderWrapper = styled.div`
  margin-left: 80px;
`;
