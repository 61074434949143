import { ReactNode, useId, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { Control, Controller } from "react-hook-form";

import { lightTheme } from "global-styles/colorThemes";

import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { ReactComponent as EyeSlashIcon } from "assets/eye-slash-icon.svg";

interface Props {
  control: Control<any>;
  formFieldName: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  error?: string;
  extraButton?: {
    content: ReactNode;
    onClickFunction: (arg?: any) => void;
    color?: string;
  };
  noMarginTop?: boolean;
  inputTabIndex?: number;
}

const InputRHF = ({
  control,
  formFieldName,
  label,
  sublabel,
  placeholder = "",
  error,
  extraButton,
  noMarginTop,
  inputTabIndex,
}: Props) => {
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const id = useId();

  return (
    <>
      <Controller
        control={control}
        name={formFieldName}
        render={({ field: { onChange } }) => (
          <StyledFormGroup controlId={id}>
            {!extraButton && (
              <StyledFormLabel nomargintop={noMarginTop ? "true" : "false"}>
                {label}
              </StyledFormLabel>
            )}
            {extraButton && (
              <LabelWithButtonWrapper>
                <Form.Label>{label}</Form.Label>
                <ExtraButton
                  onClick={extraButton.onClickFunction}
                  color={extraButton.color}
                  type="button"
                >
                  {extraButton.content}
                </ExtraButton>
              </LabelWithButtonWrapper>
            )}
            {sublabel && <Sublabel>{sublabel}</Sublabel>}
            <InputWrapper>
              <StyledFormControl
                type={isPasswordShown ? "text" : "password"}
                placeholder={placeholder}
                onChange={onChange}
                tabIndex={inputTabIndex}
                maxLength={50}
              />
              <EyeIconButton
                onClick={(e) => {
                  e.preventDefault();
                  setIsPasswordShown(!isPasswordShown);
                }}
                type="button"
              >
                {isPasswordShown ? <EyeSlashIcon /> : <EyeIcon />}
              </EyeIconButton>
            </InputWrapper>
            {error && (
              <StyledInputError className="text-danger error-container">
                *{error}
              </StyledInputError>
            )}
          </StyledFormGroup>
        )}
      />
    </>
  );
};

export default InputRHF;

const StyledFormGroup = styled(Form.Group)`
  position: relative;
`;

const StyledFormLabel = styled(Form.Label)<{ nomargintop?: string }>`
  margin-top: ${({ nomargintop }) => (nomargintop === "true" ? "0" : "17px")};
`;

const LabelWithButtonWrapper = styled.div<{ nomargintop?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ nomargintop }) => (nomargintop ? "0" : "17px")};
`;

const ExtraButton = styled.button<{ color?: string }>`
  all: unset;
  display: inline-block;
  margin-bottom: 8px;
  color: ${(p) => (p.color ? p.color : p.theme.colors.darkOrLight)};
  font-size: 14px;
  transition: 0.5s;

  &:hover {
    opacity: 0.6;
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.colors.inputFocusBlue};
  }
`;

const Sublabel = styled.div`
  margin-top: -8px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.grayOrLightGray};
  font-style: italic;
`;

const InputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.inputWrapperGray};
  border-radius: 6px;
  display: flex;
  height: 38px;
  font-size: 0;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.inputFocusBlue};
  }
`;

const StyledFormControl = styled(Form.Control)`
  border: none;
  border-radius: 5px 0 0 5px;

  &:focus-within {
    box-shadow: none;
  }

  &::placeholder {
    font-size: 13px;
  }
`;

const EyeIconButton = styled.button`
  all: unset;
  cursor: pointer;
  background-color: ${lightTheme.colors.whiteOrDark};
  border-radius: 0 5px 5px 0;
  font-size: 0;

  svg {
    height: 30px;
    width: 30px;
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.colors.inputFocusBlue};
  }
`;

const StyledInputError = styled(Form.Text)`
  position: absolute;
  bottom: -18px;
  left: 8px;
  font-size: 12px;
`;
