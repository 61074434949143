import { useEffect, useState } from "react";
import convert from "htmr";
import draftToHtml from "draftjs-to-html";
import styled from "styled-components";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

interface Props {
  editorRawContent: string;
}

const SubsectionParagraph = ({ editorRawContent }: Props) => {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(convertFromRaw(JSON.parse(editorRawContent)))
  );

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(editorRawContent))
      )
    );
  }, [editorRawContent]);

  return (
    <Paragraph>
      {convert(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
    </Paragraph>
  );
};

export default SubsectionParagraph;

const Paragraph = styled.div`
  margin-top: 15px;
  font-size: 15px;
  word-break: break-word;
`;
