import ReactDOM from "react-dom/client";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {GoogleOAuthProvider} from '@react-oauth/google';
import * as Sentry from "@sentry/react";

import "./index.css";

import {persistor, store} from "store/store";
import App from "./App";
import TagManager from 'react-gtm-module';

import {ColorThemeContextProvider} from "global-contexts/ColorThemeContext";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import React from "react";
import {AppMode} from "./types/AppMode";

const gtmId = process.env.REACT_APP_GTM_ID;

const googleAppId = process.env.REACT_APP_GOOGLE_APP_ID;

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

const stringToAppMode = (mode: string | undefined): AppMode | null   => {
    switch (mode) {
        case "standalone":
            return "standalone"
        case "onprem":
            return "onprem"
        default:
            return null
    }
}

const appmode: AppMode = stringToAppMode(process.env.REACT_APP_APP_MODE) || 'onprem';

if (gtmId) {
  console.debug("Starting gtm");
  const tagManagerArgs = {
    gtmId: gtmId
  }
  TagManager.initialize(tagManagerArgs)
} else {
  console.log("Gtm not set")
}

const wrapInGoogleAuthIfGoogleAppIdIsSet = (children: React.ReactNode) => {
  if (googleAppId) {
    return (
      <GoogleOAuthProvider clientId={googleAppId}>
        {children}
      </GoogleOAuthProvider>
    )
  } else {
    return children
  }
}

if(sentryDsn){
  Sentry.init({
    dsn: "https://d6297b24f0ab48ed9e5e0285842182e4@o4503992573362176.ingest.sentry.io/4503992609734657",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/memero\.pl\/api/, /^https:\/\/staging\.memero\.pl\/api/],
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  wrapInGoogleAuthIfGoogleAppIdIsSet(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ColorThemeContextProvider>
          <App appMode={appmode}/>
        </ColorThemeContextProvider>
      </PersistGate>
    </Provider>
  )
);
