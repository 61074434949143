import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
} from "global-styles/sharedStyles";

import SingleStep from "components/profile/SingleStep";

import { StepsRange } from "types/Profile";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  activeStepIndex: StepsRange;
  firstStepContent: string[];
  secondStepContent: string[];
}

const Stepper = ({
  activeStepIndex,
  firstStepContent,
  secondStepContent,
}: Props) => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  return (
    <StepsWrapper isDarkTheme={isDarkTheme}>
      <SingleStep
        thisStepIndex={1}
        activeStepIndex={activeStepIndex}
        label={t("addProfile.addDeceasedPersonBasicData")}
        content={firstStepContent}
      />
      <SingleStep
        thisStepIndex={2}
        activeStepIndex={activeStepIndex}
        label={t("addProfile.addDescriptionAndPhotos")}
        content={secondStepContent}
      />
      <SingleStep
        thisStepIndex={3}
        activeStepIndex={activeStepIndex}
        label={t("addProfile.summary")}
        isLastStep
      />
    </StepsWrapper>
  );
};

export default Stepper;

const StepsWrapper = styled.div<{ isDarkTheme: boolean }>`
  grid-area: steps;
  min-width: 215px;
  padding: 1px;
  @media (max-width: 1275px) {
    display: none;
  }

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
`;
