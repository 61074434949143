import { useId } from "react";
import Form from "react-bootstrap/Form";
import styled, { css } from "styled-components";
import { Control, Controller } from "react-hook-form";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";

interface Props {
  control: Control<any>;
  formFieldName: string;
  label: string;
  error?: string;
  className?: string;
  inputTabIndex?: number;
  biggerFontSize?: boolean;
  noMarginTop?: boolean;
}

const CheckboxRHF = ({
  control,
  formFieldName,
  label,
  error,
  className,
  inputTabIndex,
  biggerFontSize,
  noMarginTop,
}: Props) => {
  const { isDarkTheme } = useColorThemeContext();

  const id = useId();

  return (
    <>
      <Controller
        control={control}
        name={formFieldName}
        render={({ field: { onChange, value } }) => (
          <StyledFormGroup
            controlId={id}
            nomargintop={noMarginTop ? "true" : "false"}
            className={className}
          >
            <StyledFormCheckInput
              type="checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e);
              }}
              defaultChecked={value}
              tabIndex={inputTabIndex}
              isdarktheme={isDarkTheme ? "true" : undefined}
            />
            <StyledFormCheckLabel>
              <Label biggerFontSize={biggerFontSize}>{label}</Label>
            </StyledFormCheckLabel>
            {error && (
              <StyledCheckboxError className="text-danger error-container">
                *{error}
              </StyledCheckboxError>
            )}
          </StyledFormGroup>
        )}
      />
    </>
  );
};

export default CheckboxRHF;

const StyledFormGroup = styled(Form.Group)<{ nomargintop?: boolean }>`
  position: relative;
  display: flex;
  ${(p) => p.noMarginTop === "false" && "margin-top: 20px;"}
`;

const StyledFormCheckLabel = styled(Form.Check.Label)`
  position: relative;
  height: 20px;
  font-size: 12px;
  margin-left: 10px;
  line-height: 1.5;
  word-wrap: normal;
  align-self: flex-end;
  cursor: pointer;
`;

const Label = styled.span<{ biggerFontSize?: boolean }>`
  ${(p) =>
    p.biggerFontSize &&
    css`
      font-size: 19px;
      line-height: 0.9;
    `}
`;

const StyledFormCheckInput = styled(Form.Check.Input)<{ isdarktheme: boolean }>`
  margin-bottom: 3px;
  cursor: pointer;

  &[type="checkbox"]:enabled:checked {
    background-color: ${(p) =>
      p.isdarktheme ? p.theme.colors.darkGray : p.theme.colors.darkOrLight};
    border-color: ${(p) => p.theme.colors.darkOrLight};
  }
`;

const StyledCheckboxError = styled(Form.Text)`
  position: absolute;
  top: 17px;
  left: 8px;
  font-size: 12px;
`;
