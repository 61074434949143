import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import AddSectionDataFormFields from "components/profile/forms/AddSectionDataFormFields";

import {
  AddSectionDataFormValues,
  useAddSectionDataFormValidator,
} from "components/profile/forms/AddSectionDataFormController";
import { PersonDescriptionSubsection } from "types/Profile";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";
import { useQuestionModalContext } from "global-contexts/QuestionModalContext";

import { ReactComponent as MinusIcon } from "assets/minus-icon.svg";
import { ReactComponent as PlusIcon } from "assets/plus-icon.svg";
import { ReactComponent as XIcon } from "assets/x-icon.svg";

interface Props {
  sectionName: string;
  defaultFormValues: AddSectionDataFormValues;
  sectionIndex: number;
  subsectionFormToBeUpdated: boolean;
  setSubsectionFormToBeUpdated: Dispatch<SetStateAction<boolean>>;
}

const AddSectionDataForm = ({
  sectionName,
  defaultFormValues,
  sectionIndex,
  subsectionFormToBeUpdated,
  setSubsectionFormToBeUpdated,
}: Props) => {
  // Due to the way the DraftJS text editor is constructed, the fact that it's controlled
  // by RHF and the necessity to display its content simultaneously in the
  // live preview, it needs to have default values stored in a separate state.
  const [
    subsectionsInitialValuesForTextEditor,
    setSubsectionsInitialValuesForTextEditor,
  ] = useState<PersonDescriptionSubsection[]>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const {
    personDescription,
    setPersonDescription,
    allFormsIsDirtyStatuses,
    setAllFormsIsDirtyStatuses,
  } = useProfileDataContext();

  const { openQuestionModal } = useQuestionModalContext();

  const { t } = useTranslation();

  const addSectionDataFormMethods = useForm<AddSectionDataFormValues>({
    resolver: yupResolver(useAddSectionDataFormValidator()),
    defaultValues: defaultFormValues,
    mode: "onSubmit",
  });

  const formWatcher = useWatch({
    control: addSectionDataFormMethods.control,
    name: "subsections",
  });

  const removeSection = () => {
    if (personDescription) {
      setPersonDescription(
        personDescription.filter((_, index) => index !== sectionIndex)
      );

      setSubsectionFormToBeUpdated(true);

      setAllFormsIsDirtyStatuses({
        ...allFormsIsDirtyStatuses,
        sections: true,
      });
    }
  };

  const updateDefaultSubsectionsValues = useCallback(() => {
    const defaultSubsectionsValuesArray = personDescription[
      sectionIndex
    ].subsections.map((subsection) => subsection);
    setSubsectionsInitialValuesForTextEditor(defaultSubsectionsValuesArray);
  }, [personDescription, sectionIndex]);

  useEffect(
    function setDefaultValuesForTextEditor() {
      if (personDescription) {
        updateDefaultSubsectionsValues();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionIndex]
  );

  useEffect(
    function updateProfilePreview() {
      if (personDescription) {
        setPersonDescription(
          personDescription.map((section, index) => {
            if (index !== sectionIndex) return section;
            else {
              return {
                header: section.header,
                subsections: formWatcher,
                photos: section.photos,
                id: section.id,
              };
            }
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formWatcher]
  );

  useEffect(
    function updateFormValuesAfterSectionsReorder() {
      if (subsectionFormToBeUpdated) {
        addSectionDataFormMethods.reset({
          subsections: personDescription[sectionIndex].subsections,
        });
        updateDefaultSubsectionsValues();
        setSubsectionFormToBeUpdated(false);
      }
    },
    [
      subsectionFormToBeUpdated,
      setSubsectionFormToBeUpdated,
      personDescription,
      addSectionDataFormMethods,
      sectionIndex,
      updateDefaultSubsectionsValues,
    ]
  );

  useEffect(
    function setProfileChangesNotSaved() {
      setAllFormsIsDirtyStatuses({
        ...allFormsIsDirtyStatuses,
        subsections: addSectionDataFormMethods.formState.isDirty,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addSectionDataFormMethods.formState.isDirty]
  );

  return (
    <>
      <Section reducedLastChildBottomMargin={!isExpanded}>
        <SectionHeader isExpanded={isExpanded}>
          <SectionButton
            onClick={() => setIsExpanded((prevState) => !prevState)}
          >
            {isExpanded ? <StyledMinusIcon /> : <StyledPlusIcon />}
            {sectionName}
          </SectionButton>
          <SectionDeleteButton
            onClick={() =>
              openQuestionModal({
                text: t("addProfile.wantToDeleteSectionQuestion"),
                rightButtonAction: removeSection,
              })
            }
          >
            {t("addProfile.deleteSection")}
            <StyledXIcon />
          </SectionDeleteButton>
        </SectionHeader>
        {isExpanded && (
          <FormProvider {...addSectionDataFormMethods}>
            <AddSectionDataFormFields
              defaultFormValues={defaultFormValues}
              sectionIndex={sectionIndex}
              subsectionsInitialValuesForTextEditor={
                subsectionsInitialValuesForTextEditor
              }
              setSubsectionsInitialValuesForTextEditor={
                setSubsectionsInitialValuesForTextEditor
              }
            />
          </FormProvider>
        )}
      </Section>
    </>
  );
};

export default AddSectionDataForm;

const Section = styled.div<{ reducedLastChildBottomMargin: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;

  &:last-child {
    ${(p) => p.reducedLastChildBottomMargin && "margin-bottom: -10px;"}
  }
`;

const SectionHeader = styled.div<{ isExpanded: boolean }>`
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 10px;
  padding-top: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 600;
`;

const SectionButton = styled.button`
  all: unset;
  transition: 0.5s;

  &:hover {
    opacity: 0.6;
  }
`;

const SectionDeleteButton = styled(SectionButton)`
  font-weight: 400;
`;

const StyledPlusIcon = styled(PlusIcon)`
  vertical-align: -6px;
  margin-right: 3px;
`;

const StyledMinusIcon = styled(MinusIcon)`
  vertical-align: -6px;
  margin-right: 3px;
`;

const StyledXIcon = styled(XIcon)`
  vertical-align: -5px;
  margin-left: 2px;
`;
