import { useTranslation } from "react-i18next";

import {
  Header,
  Section,
  SectionContent,
  SectionMainLine,
  SummaryDescription,
  SummaryPrice,
} from "global-styles/commonOrderStyles";

import { DELIVERY_METHODS_API_NAMES } from "utils/consts";
import { PlacedOrderFullData } from "types/Order";

interface Props {
  orderFullData: PlacedOrderFullData | undefined;
}

const PlacedOrderShipment = ({ orderFullData }: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Header>
        <SummaryDescription>{t("orderPlate.delivery")}</SummaryDescription>
        <SummaryPrice>{`${orderFullData?.details.delivery.price} ${orderFullData?.details.delivery.currency}`}</SummaryPrice>
      </Header>
      <SectionContent>
        {orderFullData?.details.delivery.name ===
          DELIVERY_METHODS_API_NAMES.POST && (
          <>
            <SectionMainLine>{t("orderPlate.post")}</SectionMainLine>
          </>
        )}
        {orderFullData?.details.delivery.name ===
          DELIVERY_METHODS_API_NAMES.IN_POST && (
          <>
            <SectionMainLine>{t("orderPlate.inPost")}</SectionMainLine>
            <SectionMainLine>{`${t("orderPlate.pickupPoint")} ${
              orderFullData.shipment.inPostAddress
            }`}</SectionMainLine>
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default PlacedOrderShipment;
