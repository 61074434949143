import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { FormProvider } from "react-hook-form";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import AddBasicDataFieldForm from "components/profile/forms/AddBasicDataFieldForm";
import AddBasicDataFormFields from "components/profile/forms/AddBasicDataFormFields";
import ReorderModal from "components/profile/reorder-drag-and-drop/ReorderModal";

import {
  AddBasicDataFieldFormValues,
  useAddBasicDataFieldFormController,
} from "components/profile/forms/AddBasicDataFieldFormController";
import { PersonBasicData } from "types/Profile";
import { PROFILE_BASIC_DATA_FIELD_CONTENT_TYPES } from "utils/consts";
import { renderInformationToast } from "utils/toasts";
import {
  darkThemeScrollbarStyle,
  lightThemeScrollbarStyle,
  smallButtonStyle,
} from "global-styles/sharedStyles";
import { scrollToBottomOfDivElement } from "utils/utilityFunctions";
import { useAddBasicDataFormController } from "components/profile/forms/AddBasicDataFormController";
import { useBasicDataUtils } from "components/profile/utils/useBasicDataUtils";
import { useColorThemeContext } from "global-contexts/ColorThemeContext";
import { useProfileDataContext } from "global-contexts/ProfileDataContext";

interface Props {
  basicDataFormIsDirtyStatusToBeCleared: boolean;
  setBasicDataFormIsDirtyStatusToBeCleared: Dispatch<SetStateAction<boolean>>;
}

const AddBasicDataForm = ({
  basicDataFormIsDirtyStatusToBeCleared,
  setBasicDataFormIsDirtyStatusToBeCleared,
}: Props) => {
  const [isReorderModalOpen, setIsReorderModalOpen] = useState<boolean>(false);

  const {
    profileGeneralData,
    setProfileGeneralData,
    allFormsIsDirtyStatuses,
    setAllFormsIsDirtyStatuses,
  } = useProfileDataContext();

  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  const inputsWrapperRef = useRef<HTMLDivElement>(null);

  const addFieldFormMethods = useAddBasicDataFieldFormController();
  const addBasicDataFormMethods = useAddBasicDataFormController();
  const { fields, append, remove } = useFieldArray({
    control: addBasicDataFormMethods.control,
    name: "personFields",
  });

  const handleAddNewField = (values: AddBasicDataFieldFormValues) => {
    const fieldsNames = profileGeneralData?.fields.map((field) =>
      field.fieldName.toLowerCase()
    );

    if (
      profileGeneralData &&
      !fieldsNames?.includes(values.newFieldName.toLowerCase())
    ) {
      addFieldFormMethods.reset();
      append({ content: "" });

      setProfileGeneralData({
        ...profileGeneralData,
        fields: [
          ...profileGeneralData.fields,
          {
            fieldName: values.newFieldName,
            content: "",
            fieldType: PROFILE_BASIC_DATA_FIELD_CONTENT_TYPES.STRING,
            required: false,
            deletable: true
          },
        ],
      });

      addBasicDataFormMethods.trigger("personFields");

      setTimeout(() => {
        scrollToBottomOfDivElement(inputsWrapperRef);
      }, 100);
    } else {
      renderInformationToast(t("addProfile.cannotAddFieldThatExists"));
    }
  };

  const handleRemoveField = (fieldIndex: number) => {
    remove(fieldIndex);

    if (profileGeneralData) {
      setProfileGeneralData({
        ...profileGeneralData,
        fields: profileGeneralData.fields.filter(
          (_, index) => index !== fieldIndex
        ),
      });
    }
  };

  const personData = useWatch({
    control: addBasicDataFormMethods.control,
    name: "personFields",
  });

  const {
    isReorderButtonDisabled,
    basicDataToReorder,
    basicDataNonReorderableFields,
  } = useBasicDataUtils();

  const saveReorderedArray = (reorderedArray: PersonBasicData[]) => {
    const combinedArrays = [
      ...basicDataNonReorderableFields!,
      ...reorderedArray,
    ];

    if (profileGeneralData) {
      setProfileGeneralData({
        ...profileGeneralData,
        fields: combinedArrays,
      });
    }

    addBasicDataFormMethods.reset(
      {
        personFields: combinedArrays?.map((field) => {
          return {
            content: field.content,
          };
        }),
      },
      {
        keepErrors: true,
        keepDirty: true,
      }
    );

    setAllFormsIsDirtyStatuses({
      ...allFormsIsDirtyStatuses,
      basicData: true,
    });

    setIsReorderModalOpen(false);
  };

  useEffect(
    function updateProfilePreview() {
      if (profileGeneralData) {
        setProfileGeneralData({
          ...profileGeneralData,
          fields: profileGeneralData.fields.map((field, index) => {
            return {
              fieldName: field.fieldName,
              content: personData[index].content,
              fieldType: field.fieldType,
              required: field.required,
              deletable: field.deletable
            };
          }),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personData]
  );

  useEffect(
    function clearIsDirtyFormStatus() {
      if (basicDataFormIsDirtyStatusToBeCleared) {
        addBasicDataFormMethods.reset({
          personFields: profileGeneralData?.fields.map((field) => {
            return {
              content: field.content,
            };
          }),
        });

        setBasicDataFormIsDirtyStatusToBeCleared(true);

        setTimeout(() => {
          setBasicDataFormIsDirtyStatusToBeCleared(false);
        }, 400);
      }
    },
    [
      basicDataFormIsDirtyStatusToBeCleared,
      setBasicDataFormIsDirtyStatusToBeCleared,
      profileGeneralData?.fields,
      addBasicDataFormMethods,
    ]
  );

  useEffect(
    function setProfileChangesNotSaved() {
      setAllFormsIsDirtyStatuses({
        ...allFormsIsDirtyStatuses,
        basicData: addBasicDataFormMethods.formState.isDirty,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addBasicDataFormMethods.formState.isDirty]
  );

  return (
    <FormWrapper>
      <FormLabel>{`${t("addProfile.addDeceasedPersonBasicData")}:`}</FormLabel>
      <FieldsWrapper ref={inputsWrapperRef} isDarkTheme={isDarkTheme}>
        <InputsFrame>
          <FormProvider {...addBasicDataFormMethods}>
            <AddBasicDataFormFields
              fields={fields}
              handleRemoveField={handleRemoveField}
            />
          </FormProvider>
        </InputsFrame>
      </FieldsWrapper>
      <ManageFields isDarkTheme={isDarkTheme}>
        <FormProvider {...addFieldFormMethods}>
          <StyledForm
            onSubmit={addFieldFormMethods.handleSubmit(handleAddNewField)}
            noValidate
          >
            <AddBasicDataFieldForm />
          </StyledForm>
        </FormProvider>
        <ReorderButton
          onClick={() => setIsReorderModalOpen(true)}
          disabled={isReorderButtonDisabled}
        >
          {t("addProfile.changeNewFieldsOrder")}
        </ReorderButton>
      </ManageFields>
      <ReorderModal
        isReorderModalOpen={isReorderModalOpen}
        setIsReorderModalOpen={setIsReorderModalOpen}
        dataToReorder={basicDataToReorder}
        saveReorderedArray={saveReorderedArray}
        headerText={t("addProfile.basicDataReorderText")}
      />
    </FormWrapper>
  );
};

export default AddBasicDataForm;

const FormWrapper = styled.div`
  grid-area: form;
  overflow: hidden;
  display: grid;
  flex-direction: column;
  grid-template-rows: 1fr 16fr 1fr;

`;

const FormLabel = styled.div`
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 150%;
  font-weight: 600;
`;

const FieldsWrapper = styled.div<{ isDarkTheme: boolean }>`
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  border-radius: ${(p) => (p.isDarkTheme ? "6px" : "4px 4px 0 0")};
  padding: 15px;

  ${(p) => (p.isDarkTheme ? darkThemeScrollbarStyle : lightThemeScrollbarStyle)}
  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const InputsFrame = styled.div`
  max-width: 400px;
`;

const ManageFields = styled.div<{ isDarkTheme: boolean }>`
  @media (max-width: 720px) {
    display: grid;
    height: 28vh;
  }
  background-color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
  min-height: 90px;
  border-radius: 0 0 4px 4px;
  border-radius: ${(p) => (p.isDarkTheme ? "0" : "0 0 4px 4px")};
  padding-left: 20px;
  padding-bottom: 4px;
  padding-right: 9px;
  display: flex;
  ${(p) =>
    !p.isDarkTheme && `border-top: 1px solid ${p.theme.colors.lightGray};`}

  ${(p) =>
    p.isDarkTheme && `border-bottom: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const StyledForm = styled(Form)`
  display: flex;
`;

const ReorderButton = styled.button`
  ${smallButtonStyle}
  margin-top: 41px;
  margin-left: 20px;
  height: 24px;
  margin-bottom: 0;

  @media (max-width: 1305px) {
    height: 44px;
    width: 110px;
    margin-top: 24px;
  }
`;
