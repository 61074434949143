import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "store/storeHooks";
import { useTranslation } from "react-i18next";

import httpService from "utils/httpService";

import { renderErrorToast } from "utils/toasts";
import { selectAuthToken } from "store/auth/AuthSlice";

interface ColorThemeProvider {
  children: ReactNode;
}

export const ColorThemeContext = createContext<{
  isThemeLoaded: boolean;
  isDarkTheme: boolean;
  fetchUserTheme: () => void;
  setIsDarkTheme: (isDarkTheme: boolean) => void;
  saveUserTheme: (isDarkTheme: boolean) => void;
}>({
  isThemeLoaded: false,
  isDarkTheme: false,
  fetchUserTheme: () => {},
  setIsDarkTheme: (isDarkTheme: boolean) => {},
  saveUserTheme: (isDarkTheme: boolean) => {},
});

export const ColorThemeContextProvider = ({ children }: ColorThemeProvider) => {
  const [isThemeLoaded, setIsThemeLoaded] = useState<boolean>(false);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);

  const authToken = useAppSelector(selectAuthToken);

  const { t } = useTranslation();

  const fetchUserTheme = useCallback(() => {
    httpService
      .get(`api/user/preferences/v1/get/`)
      .then((response) => {
        setIsDarkTheme(response.data.darkModeEnabled);
        setIsThemeLoaded(true);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  }, [t]);

  const saveUserTheme = (isDarkTheme: boolean) => {
    httpService
      .put(`api/user/preferences/v1/update/`, {
        darkModeEnabled: isDarkTheme,
      })
      .then((response) => {
        setIsDarkTheme(response.data.darkModeEnabled);
      })
      .catch(() => {
        renderErrorToast(t("errorOccurred"));
      });
  };

  useEffect(
    function fetchThemeForLoggedInUser() {
      if (authToken) {
        fetchUserTheme();
      }
    },
    [authToken, fetchUserTheme]
  );

  return (
    <ColorThemeContext.Provider
      value={{
        isThemeLoaded,
        isDarkTheme,
        setIsDarkTheme: (isDarkTheme: boolean) => {
          setIsDarkTheme(isDarkTheme);
        },
        saveUserTheme,
        fetchUserTheme,
      }}
    >
      {children}
    </ColorThemeContext.Provider>
  );
};

export const useColorThemeContext = () => {
  const context = useContext(ColorThemeContext);

  if (context === undefined) {
    throw new Error(
      "useColorThemeContext must be used within the ColorThemeContextProvider"
    );
  }

  return context;
};
