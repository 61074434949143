import styled from "styled-components";
import { useTranslation } from "react-i18next";

import OrdersTable from "components/orders/OrdersTable";

import { useColorThemeContext } from "global-contexts/ColorThemeContext";

const OrdersContent = () => {
  const { isDarkTheme } = useColorThemeContext();

  const { t } = useTranslation();

  return (
    <Container isDarkTheme={isDarkTheme}>
      <Title>{t("orders.title")}</Title>
      <OrdersTable />
    </Container>
  );
};

export default OrdersContent;

const Container = styled.div<{ isDarkTheme: boolean }>`
  flex-basis: 85.5%;
  background: ${({ theme }) => theme.colors.lightOrDark};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  padding: 32px;
  display: flex;
  flex-direction: column;

  ${(p) => p.isDarkTheme && `border: 1px solid ${p.theme.colors.darkOrLight}`}
`;

const Title = styled.div`
  font-family: "Jost";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 24px;
`;
