import styled from "styled-components";

import { PlacedOrderSummary } from "types/Order";

const OrdersTableRow = ({
  orderNumber,
  orderDate,
  shipmentDate,
  items,
  status,
}: PlacedOrderSummary) => {
  return (
    <Row>
      <RowItem>
        {orderNumber}
      </RowItem>
      <RowItem>{orderDate}</RowItem>
      <RowItem>{shipmentDate}</RowItem>
      <RowItem>{status}</RowItem>
    </Row>
  );
};

export default OrdersTableRow;

const Row = styled.div`
  min-height: 50px;
  color: ${({ theme }) => theme.colors.darkOrLight};
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  padding: 20px 0;

  &:hover {
    background: ${({ theme }) => theme.colors.extraLightGray};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const RowItem = styled.div`
  font-size: 19px;
  line-height: 150%;

  &:nth-child(1) {
    flex-basis: 35%;

    & span {
      padding-left: 10px;
    }
  }
  &:nth-child(2) {
    flex-basis: 21%;
  }
  &:nth-child(3) {
    flex-basis: 21%;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
  &:nth-child(4) {
    flex-basis: 23%;
  }
`;
