import {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

import {lightTheme} from "global-styles/colorThemes";

import {useColorThemeContext} from "global-contexts/ColorThemeContext";
import Footer from "../components/footer/Footer";
import {STATIC_PAGE_PATH, USER_PATH} from "../utils/routes";
import {useAppSelector} from "../store/storeHooks";
import {selectAuthToken} from "../store/auth/AuthSlice";

const AuthPage = () => {
  const {setIsDarkTheme} = useColorThemeContext();
  const navigate = useNavigate();
  const authToken = useAppSelector(selectAuthToken);

  useEffect(() => {
    setIsDarkTheme(false);
  }, [setIsDarkTheme]);

  useEffect(() => {
    if (authToken) {
      navigate(USER_PATH);
    }
  })

  return (
    <>
      <Background>
        <ContentWrapper>
          <OutletWrapper>
            <FlexContainer>
              <Outlet/>
            </FlexContainer>
          </OutletWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <Footer basePath={STATIC_PAGE_PATH}/>
        </FooterWrapper>
      </Background>
    </>
  );
};

export default AuthPage;

const Background = styled.div`
  height: 100%;
  min-width: 95vw;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 6fr 1fr;
  grid-template-areas:
      "content"
      "footer";
  align-items: center;
  justify-content: center;
  @media (max-width: 720px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 1fr;
    grid-template-areas:
      "content"
      "footer";
  }
`;

const FooterWrapper = styled.div`
  grid-area: footer;
`

const ContentWrapper = styled.div`
  grid-area: content;
  margin: 0 auto;
  padding: 40px 30px 30px 44px;
  color: ${lightTheme.colors.whiteOrDark};

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 16fr 1fr 16fr;
  column-gap: 5vw;

  @media (max-width: 1220px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "auth";
  }
`;

const OutletWrapper = styled.div`
  grid-area: auth;

  @media (max-width: 1220px) {
    align-self: start;
  }

  @media (max-width: 720px) {
    margin-top: 20px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({theme}) => theme.colors.lightOrDark};
  border-radius: 12px;
  padding-bottom: 30px;
  max-width: 385px;
  margin-right: 12px;
  height: 100%;

  @media (max-width: 1220px) {
    max-width: none;
  }

  @media (max-width: 720px) {
    max-width: 500px;
  }
`;
