import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Section } from "global-styles/commonOrderStyles";

import { OrderCheckoutSummary } from "types/Order";

interface Props {
  orderCheckoutSummary: OrderCheckoutSummary | undefined;
}
const TotalPrice = ({ orderCheckoutSummary }: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <TotalPriceLine>
        <div>{t("orderPlate.toPay")}</div>
        {!orderCheckoutSummary?.discount && (
          <div>{`${orderCheckoutSummary?.total.price} ${orderCheckoutSummary?.total.currency}`}</div>
        )}
        {orderCheckoutSummary?.discount && (
          <div>
            <CalculatedDiscount>
              <OldPrice>{orderCheckoutSummary?.total.oldPrice}</OldPrice>
              <Percentage>{`-${orderCheckoutSummary?.discount.percentage}%`}</Percentage>
            </CalculatedDiscount>

            {`${orderCheckoutSummary?.total.price} ${orderCheckoutSummary?.total.currency}`}
          </div>
        )}
      </TotalPriceLine>
    </Section>
  );
};

export default TotalPrice;

const TotalPriceLine = styled.div`
  font-weight: 700;
  font-size: 19px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOpacityOrWhiteOpacity};
  display: flex;
  justify-content: space-between;
  line-height: 2;
  margin-top: 25px;
`;

const CalculatedDiscount = styled.span`
  margin-right: 20px;
  margin-left: 10px;

  @media (max-width: 1147px) {
    margin-right: 10px;
  }

  @media (max-width: 1093px) {
    margin-right: 5px;
    margin-right: 5px;
  }
`;

const OldPrice = styled.span`
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 1093px) {
    margin-right: 5px;
  }
`;

const Percentage = styled.span`
  color: ${({ theme }) => theme.colors.successGreen};
`;
