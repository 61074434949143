import { css } from "styled-components";

export const lightThemeScrollbarStyle = css`
  overflow-y: auto;

  // Chrome, Safari, Edge, Opera
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }

  // Firefox
  scrollbar-color: #aeacab #f2f0ef;
  scrollbar-width: thin;
`;

export const darkThemeScrollbarStyle = css`
  overflow-y: auto;

  // Chrome, Safari, Edge, Opera
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.6);
  }

  // Firefox
  scrollbar-color: rgba(255, 255, 255, 0.9) #1d2022;
  scrollbar-width: thin;
`;

export const firefoxLightColorBackgroundScrollbar = css`
  overflow-y: auto;
  scrollbar-color: #aeacab #e5e1de;
  scrollbar-width: thin;
`;

export const smallOutlineButtonStyle = css`
  all: unset;
  font-size: 14px;
  margin-right: 10px;
  border: 1px solid;
  border-radius: 6px;
  padding: 3px 8px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    opacity: 0.6;
  }
`;

export const smallButtonStyle = css`
  ${smallOutlineButtonStyle}
  margin-top: 7px;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.darkOrLight};
  color: ${({ theme }) => theme.colors.whiteOrDark};
  border: none;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.colors.moderateGray};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.whiteOpacityOrDarkOpacity};
    cursor: unset;
  }

  &:hover:disabled {
    opacity: 1;
  }
`;
